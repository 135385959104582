.container-message-rigth-send-component {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: end;

    .user-name-chat-conversation {
        font-size: .6vw;
        font-family: "Poppins";
        margin-right: 0.5vw;
        color: #000;
        font-weight: 500;
    }
}

.subcontainer-message-rigth-send-component {
    width: 65%;
    background: #E6E7E8;
    margin: 0 0 1vw 0.5vw;
    border-top-left-radius: 15px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;

    .contents-msg-title-send {
        font-size: .8vw;
        color: #000;
        font-family: "Poppins";
        font-weight: 400;
        padding: .4vw;

    }

    .img-send-chat {
        width: 90%;
        padding: 0 .5vw

    }

    .content-hour-message {
        font-size: .5vw;
        color: #000;
        font-family: "Poppins";
        font-weight: 500;
        padding: .2vw;
        display: flex;
        justify-content: end;
    }

}