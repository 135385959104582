.box-form-signup {
    .two_columns_input {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .white-half-line {
            width: 45%!important;
        }
        .row-container {
            width: 45%!important;
        }
    }
    .icon_input {
        font-size: 25px;
        color: #f1f1f1e1;
    }
    .smaller_icon {
        font-size: 22px;
    }
    .row-container {
        width: 100%!important;
    }
    .row-containter-checkbox {
        display: flex;
        justify-content: flex-start !important;
        align-items: center;
        label {
            font-family: 'Poppins', sans-serif;
            font-size: 2.5vh;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #ffffff;
            margin-left: 5px;
        }
    }
    .login-button {
        margin-top: 1rem;
    }
}