.quote-c-Lumbares
    grid-template-areas: 'quote-c-lumbares-c-title' 'quote-c-lumbares-c-items'
    grid-area: quote-c-lumbares
    .quote-c-lumbares-c-title
        background: rgba(#313131, .4)
        grid-area: quote-c-lumbares-c-title
        .quote-c-lumbares-title
        .quote-c-lumbares-img
.quote-c-lumbares-c-items
    grid-area: quote-c-lumbares-c-items
    .quote-c-item
        .quote-c-item-img
        .quote-c-item-button
            .quote-item-button-ico