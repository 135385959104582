.precontainer-almacen-notify{
    width: 100%;
    height: 85vh ;
    padding-top: 15vh ;
    position: relative;
    background: rgb(9,64,112);
    background: linear-gradient(152deg, rgba(9,64,112,1) 0%, rgba(56,168,185,1) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow-y: auto;
    font-family: "Poppins";

    .container_general_almace-notify{
        width: 100%;
        height: 78vh;
        overflow: auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        
        .container-almacen-notify{
            display: flex;
            position: relative;
            justify-content: center;
           .img-encuesta-almacen-notify{
            width: 18vw;
    
           }
        }

        .title_encuesta_almacen-notify{
            font-size: 2.5vw;
            justify-content: center;
            margin-top: 3vh;
            color: #ffffff;
            margin-bottom: 1.5vw;
               
        }
        .subtitle-encuesta-almacen-notify{
            font-size: 1.5vw;
            justify-content: center;
            color: #ffffff;
            
        }

        .subtitle-encuesta-almacen-notify2{
            font-size: 1.5vw;
            justify-content: center;
            color: #ffffff;
            margin-bottom: 3vh;

        }
    
        .numberCx_encuestas_almacen-notify{
            font-size: 3vw;
            color: #ffffff;
            margin-bottom: 2vw;
    
        }
    
        .Cont_button_encuestas_almacen-notify{
            display: flex;
                justify-content: center;
    
            .button-almacen-notify{
                font-size: 1.5vw;
                    color: #094070;
                    background-color: #ffffff;
                    border-radius: 20px;
                    cursor: pointer;
                    display: flex;
                    text-transform: uppercase;
                    padding: 6px 1rem;
                    min-width: 14vw;
                    border: none;
                    justify-content: center;
                    text-transform: uppercase;
                    box-shadow: 3px 4px #414040;
                    opacity: .7;
    
            }
    
        }


    } 
}