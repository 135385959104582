
.precontainer{
    display: flex;
    width: 100%;
    justify-content: center;

    @media (max-width: 480px){

        .container-general-medic{
            width: 95% !important;
            margin-top: 3em !important;
            margin-bottom: 3rem!important;
        }

    }
    @media (min-width: 1024px){

        .container-general-medic{
            width: 60% !important;
            margin-top: 5rem !important;
            margin-bottom: 3rem!important;
        }

    }
    @media (min-width: 480px) and (max-width: 1023px){

        .container-general-medic{
            width: 85% !important;
            margin-top: 3rem !important;
            margin-bottom: 3rem!important;
        }

    }
        .container-general-medic{
            width: 95%;
            box-shadow: 0px 1px 6px -1px rgb(0 0 0 / 35%);
            padding-bottom: 2vh;
            display: flex;
            flex-direction: column;
            margin-top: 1em;
            .header-title-medic{
                display: flex;
                justify-content: center;
                border-bottom: 1px solid #c2c2c2;
                .text-title-medic{
                    padding: 1.2vh 1vw;
                    color: #094070;
                    font-family: "Poppins";
                    font-weight: 300;
                    font-size: 1.2rem;
                }
            }
            .body-surtido-medic{
                border-bottom: 1px solid #c2c2c2;
                padding-bottom: 1rem;

                .subtext-extra-title-medic{
                    padding: 1.2vh 1vw;
                    font-family: "Poppins";
                    font-weight: 500;
                    font-size: 1rem;
                    color: #094070;
                }
                .subtext-title-medic{
                    padding: 1.2vh 1vw;
                    font-family: "Poppins";
                    font-weight: 300;
                    font-size: 0.9rem;
                }
                .container-radio{
                    margin-left: 1rem;
                    display: flex;
                    align-items: center;
                    .item-name1{
                        font-family: "Poppins";
                        font-size: 0.9rem;
                        font-weight: 300;
                        margin-right: 0.3rem;
                    }
                    .input-radio1{
                        margin-right: 1rem !important;
                    }
                    @media (max-width: 480px){
                        .input-radio1{
                            margin-right: 1rem !important;
                            width: 1.5rem !important;
                            height: 1.5rem !important;
                        }                
                    }
                }
                .textarea-medic{
                    width: 94%;
                    display: flex;
                    margin-left: 2%;
                    font-family: "Poppins";
                    font-weight: 300;
                    font-size: 0.9rem;
                    color: #707070;
                    height: 76px;
                    margin-top: 1vh;
                    padding: 5px;
                    overflow: auto;
                }
            }
            .container-button{
                display: flex;
                /* align-items: center; */
                /* align-content: center; */
                align-self: center;
                .btn-enviar{
                    background: linear-gradient(90deg, #37a7b9 10%, #094070 100%);
                    color: #fff;
                    padding: 1rem 2rem;
                    font-family: "Poppins";
                    font-size: 0.9rem;
                    border-radius: 15px;
                    width: 10em;
                    height: 2.2rem;
                    margin-top:    1rem;
                    margin-bottom: 1rem;
                    padding: 5px;
                }
            }

        }
    }