$gradient: linear-gradient(270deg, rgba(9,64,112,1) 0%, rgba(55,167,185,1) 91%)
%flexbox_surgeryTabs
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

.container-factoryConfigurationSecundaryTabs
    width: 100%
    margin-top: 40px
    display: grid
    height: 50vh
    justify-items: center
    grid-auto-columns: .5fr 1fr 
    grid-template-areas: 'factoryConfigurationSecundaryTabs-container-tabs factoryConfigurationSecundaryTabs-tab-system'
    .factoryConfigurationSecundaryTabs-container-tabs
        width: 100%
        margin-bottom: 20px
        @extend %flexbox_surgeryTabs
        .factoryConfigurationSecundaryTabs-sistem
            border-radius: 10px
        .factoryConfigurationSecundaryTabs-config
            border-radius: 10px
        .factoryConfigurationSecundaryTabs-tab
            background: linear-gradient(145deg, #d0d0d0, #afafaf)
            box-shadow: 0px 2px 3px #636363, 0px 0px 0px #ffffff
            width: 80%
            margin: 20px 0
            padding: 10px
            font-family: 'Poppins'
            font-size: 1.2vw
            border: none
            outline: none
        .secundary-tab-active
            background: $gradient
            box-shadow: inset 5px 5px 8px #16434a, inset -5px -5px 8px $gradient
            color: #fff
    .factoryConfigurationSecundaryTabs-tab-system
        width: 100%
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        