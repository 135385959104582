%stylesToButton
    cursor: pointer
    outline: none
    border: none
    
.section-1-atq
    grid-template-columns: 1fr 1fr
    grid-template-areas: 'atq-historial  atq-disponibilidad' 'atq-historial atq-rol-guardias' 'atq-asignar atq-rol-guardias'
    .disabled
        opacity: .4
    .atq-item-simpleform
        display: flex
        justify-content: center
        flex-direction: column
        align-items: center
        background: linear-gradient(190deg, rgba(9,9,121,1) 0%, rgba(55,167,185,1) 100%, rgba(9,9,121,1) 094070%)
        img
            width: 9vw
    .atq-item-horizontalform
        display: flex
        flex-direction: row
        justify-content: space-around
        align-items: center
        background: linear-gradient(0deg, rgba(9,9,121,1) 0%, rgba(32,90,154,1) 29%, rgba(55,167,185,1) 100%)
        img
            width: 7vw
    .atq-item
        border-radius: 10px
        color: #fff
        cursor: pointer
        text-align: center
        align-self: stretch
        justify-self: stretch
        text-decoration: none
        @extend %stylesToButton
        .atq-item-title
            font-size: 1.5vw
            font-family: 'Poppins'
            font-weight: 300
    .atq-historial
        display: flex
        grid-area: atq-historial
        flex-direction: column
       
    .atq-asignar
        grid-area: atq-asignar
        padding-left: 15px
    .atq-disponibilidad
        grid-area: atq-disponibilidad
    .atq-rol-guardias
        grid-area: atq-rol-guardias