.detalleproducto-btn-c
    width: 100%
    display: flex
    flex-direction: row
    justify-content: center
    position: absolute
    bottom: 28px
    .detalleproducto-btn
        background: linear-gradient(93deg, rgba(26,188,156,1) 10%, rgba(9,64,112,1) 100%)
        padding: 10px 0
        width: 200px
        color: #fff
        border: none
        border-radius: 30px
        cursor: pointer
        outline: none
        position: fixed
        bottom: 4vw
