.StoreHouseAssignSystemsTableComponent-container-table {
  width: 100%;
  display: flex;
  justify-content: center;
  border-spacing: 0;
  padding-top: 1vw;
  box-shadow: 1px 1px 7px -2px #777777;
  .StoreHouseAssignSystemsTableComponent-precontainer-table {
    width: 95%;
    .StoreHouseAssignSystemsTableComponent-table {
      width: 100%;
      border-collapse: collapse;
      display: inline-block;
      box-sizing: border-box;
    }
    .StoreHouseAssignSystemsTableComponent-table-tr {
      display: flex;
      width: 100%;
      margin-bottom: 1vh;
    }
    .plush{
      border-top: solid 1px #E6E7E8;
    }
    .plushBotton{
      border-bottom: solid 1px #E6E7E8;
      padding-bottom: 1vh;
    }
    .StoreHouseAssignSystemsTableComponent-table-head {
      width: 100%;
      display: flex;
      .StoreHouseAssignSystemsTableComponent-table-title {
        font-weight: 400;
        border-bottom: 0.07vw solid #a7a7a7;
        padding-bottom: 1vw;
        width: calc(100% / 3);
      }
    }
    .StoreHouseAssignSystemsTableComponent-table-body {
      height: 40vh;
      width: 100%;
      overflow: auto;
      display: block;

      .StoreHouseAssignSystemsTableComponent-table-body-tr {
        margin-top: 5px;
        display: flex;
        width: 100%;
      }

      .StoreHouseAssignSystemsTableComponent-table-row-body {
        color: #303030;
        font-weight: 200;
        text-align: start;
        width: 70%;
        padding: 10px 0 1px 1vw;
        font-family: "Poppins";
        font-size: 0.9vw;
        display: flex;
        justify-content: center;
      }
      .StoreHouseAssignSystemsTableComponent-table-row-body-clickeable{
        color: #303030;
        font-weight: 200;
        text-align: start;
        width: 70%;
        padding: 10px 0 1px 1vw;
        font-family: "Poppins";
        font-size: 0.9vw;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }

      .StoreHouseAssignSystemsTableComponent-body-data {
        text-align: center;
        padding: 1vw 0 0.5vw 0;
        color: #777777;
        display: inline-block;
        width: 33%;
        box-sizing: border-box;
      }
      .StoreHouseAssignSystemsTableComponent-body-select {
        cursor: pointer;
      }
    }
  }
}

.StoreHouseAssignSystemsTableComponent-container-middle-buttons {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .StoreHouseAssignSystemsTableComponent-middle-buttons-pxq {
    width: 25%;
    padding: 0.5vw;
    border-radius: 1.5vw;
    color: #fff;
    background: linear-gradient(
      275deg,
      rgba(55, 167, 185, 1) 0%,
      rgba(41, 176, 171, 1) 72%,
      rgba(26, 188, 156, 1) 100%
    );
    border: none;
    outline: none;
    cursor: pointer;
    box-shadow: 1px 1px 5px 0px #777;
  }

  .StoreHouseAssignSystemsTableComponent-switch {
    position: relative;
    display: inline-block;
    width: 25%;
    height: 2.1vw;
  }

  /* Hide default HTML checkbox */
  .StoreHouseAssignSystemsTableComponent-switch .StoreHouseAssignSystemsTableComponent-switch-input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .StoreHouseAssignSystemsTableComponent-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .StoreHouseAssignSystemsTableComponent-slider-color-red:before {
    background-color: #e30613;
  }
  .StoreHouseAssignSystemsTableComponent-slider-color-green:before {
    background-color: #1abc9c;
  }

  .StoreHouseAssignSystemsTableComponent-slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    border: 0.05vw solid #fff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .StoreHouseAssignSystemsTableComponent-switch-input:checked + .StoreHouseAssignSystemsTableComponent-slider {
    background-color: #777777;
  }

  .StoreHouseAssignSystemsTableComponent-switch-input:focus + .StoreHouseAssignSystemsTableComponent-slider {
    box-shadow: 0 0 1px #2196f3;
  }

  .StoreHouseAssignSystemsTableComponent-switch-input:checked + .StoreHouseAssignSystemsTableComponent-slider:before {
    -webkit-transform: translateX(14.4vw);
    -ms-transform: translateX(14.4vw);
    transform: translateX(14.4vw);
  }

  /* Rounded sliders */
  .StoreHouseAssignSystemsTableComponent-slider.StoreHouseAssignSystemsTableComponent-round {
    border-radius: 34px;
  }

  .StoreHouseAssignSystemsTableComponent-slider.StoreHouseAssignSystemsTableComponent-round:before {
    border-radius: 50%;
  }
}
