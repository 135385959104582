.container_experience {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;

    .precontainer_experience {
        width: 80%;
        margin-top: 10vh;
        display: grid;
        grid-template-rows: 1.5fr 1.5fr;

        .first-section {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 8vw;
            margin-top: 10vh;
        }

        .second-complete {
            width: 85%;
            display: grid;
            grid-template-rows: 0.1fr 0.4fr 0.1fr;
            grid-gap: 1vw;
            justify-self: center;
            margin-top: 6vh;
            .text-report {
                font-family: "Poppins";
                font-size: 16px;
            }

            .editor {
                width: 100%;
                height: 28vh;
                /* background: red; */
                border: solid 1px #707070;
                font-family: "Poppins";
                font-size: 1vw;
            }

            .btn-continuar {
                font-family: "Poppins";
                background: linear-gradient(93deg, #1abc9c 10%, #094070 100%);
                color: #fff;
                width: 16%;
                padding: 0.6vw;
                cursor: pointer;
                border: none;
                border-radius: 2vw;
                justify-self: end;
                margin-bottom: 5vh;
            }
        }
        .second-section {
            width: 85%;
            display: flex;
            flex-direction: column;
            /* grid-template-rows: 0.1fr 1fr 0.fr; */
            grid-gap: 1vw;
            justify-self: center;
            margin-top: 2vh;
            height: 47vh;
            .text-report {
                font-family: "Poppins";
                font-size: 16px;
            }

            .editor {
                width: 100%;
                height: 28vh;
                /* background: red; */
                border: solid 1px #707070;
                font-family: "Poppins";
                font-size: 1vw;
            }

            .section-editText-report {
                //background: red;
                //height: 350px;
                //background: red;

                .editText-report {
                    height: 350px;
                    background: #094070;
                    //width: 350px;
                   // max-height: 200;
                   // overflow: scroll;
                }
            }

            .section-btn-report {
                //background: #094070;
                display: flex;
                justify-content: flex-end;
                margin-top: 4vh;

                .btn-continuar {
                    font-family: "Poppins";
                    background: linear-gradient(93deg, #1abc9c 10%, #094070 100%);
                    color: #fff;
                    width: 20%;
                    padding: 0.4vw;
                    cursor: pointer;
                    border: none;
                    border-radius: 2vw;
                    justify-self: end;
                    margin-bottom: 5vh;
                    box-shadow: 4px 3.9px 3px #c4c1c1;
                }
            }
        }
    }
}
