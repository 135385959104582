%stylesToButton
    cursor: pointer
    outline: none
    border: none

.c-home-sec2
    grid-template-columns: .7fr .7fr .7fr
    grid-template-areas: 'c-home-c-pedidos c-home-c-informacion c-home-c-informacion' 'c-home-c-encuestas c-home-c-encuestas c-home-c-estatus'
    .c-home-item-simpleform-disabled
        background: linear-gradient(190deg, rgba(9,9,121,.7) 0%, rgba(55,167,185,.7) 100%, rgba(9,9,121,.7) 094070%) !important
    .c-home-item-simpleform
        display: flex
        justify-content: center
        flex-direction: column
        align-items: center
        img
            width: 7vw
    .c-home-item-horizontalform
        display: flex
        flex-direction: row
        justify-content: space-around
        align-items: center
        img
            width: 9vw
    .c-home-item
        border-radius: 10px
        color: #fff
        cursor: pointer
        text-align: center
        align-self: stretch
        justify-self: stretch
        @extend %stylesToButton
        .home-item-title
            font-size: 1.5vw
            font-family: 'Poppins'
            font-weight: 300
    .c-home-c-pedidos
        grid-area: c-home-c-pedidos
        background: linear-gradient(270deg, rgba(26,188,156,1) 0%, rgba(9,64,112,1) 91%)
        opacity: 0.5
    .c-home-c-encuestas
        flex-direction: row-reverse
        grid-area: c-home-c-encuestas
        background: linear-gradient(270deg, rgba(55,167,185,1) 0%, rgba(26,188,156,1) 100%)
    .c-home-c-informacion
        grid-area: c-home-c-informacion
        background: linear-gradient(93deg, rgba(26,188,156,1) 10%, rgba(9,64,112,1) 100%)
        .home-item-title
            font-size: 1.5vw
        .c-home-c-informacion-title
            width: 40%
            font-size: 1.5vw
            margin-right: 20px
    .c-home-c-estatus
        grid-area: c-home-c-estatus
        background: linear-gradient(270deg, rgba(55,167,185,1) 0%, rgba(26,188,156,1) 100%)
