.container-factoryConfigList
    .factoryListConfig-cards
        height: 57vh
        .factoryConfigList-container-configuration-card
            display: flex
            flex-direction: row
            justify-content: space-around
            align-items: center
            width: 90%
            margin-bottom: 20px
            .factoryListConfig-configuration-info-card-selected
                background: rgba(9,64,112,1) !important
                color: #fff
                border-left: .5vw solid #37a7b9 !important
            .factoryConfigList-container-configuration-info-card
                width: 90%
                font-weight: 300
                display: flex
                flex-direction: row
                align-items: center
                min-height: 4vw
                border: .1vw solid #a7a7a7
                margin-bottom: 1vw
                cursor: pointer
                border-radius: 1vw
                padding: .5vw
                border-left: .5vw solid rgba(9,64,112,1)
                background: #fff
                .factoryConfigList-card-container-configuration-info
                    width: 100%
                    display: flex
                    flex-direction: row
                    justify-content: space-between
                    align-items: center
                    margin-right: .5vw
                    overflow: hidden
                    font-family: 'Poppins'
                    font-size: 1.2vw
            .factoryConfigList-delete-button
                background: #fff
                width: 3vw
                height: 3vw
                border-radius: 50%
                margin-bottom: 1vw
                border: none
                outline: none
                cursor: pointer
                display: flex
                justify-content: center
                align-items: center
                margin-left: 10px
                .factoryConfigList-delete-buttons-img
                    width: 3vw
    .factoryConfigList-container-buttons
        width: 100%
        display: flex
        flex-direction: row
        justify-content: space-around
        align-items: center
        .factoryConfigList-button
            background: #fff
            width: 90%
            border-radius: 1vw
            border: none
            outline: none
            cursor: pointer
            display: flex
            justify-content: center
            align-items: center
            font-size: 1.1vw
            font-family: 'Poppins'
            .factoryConfigList-buttons-img
                width: 2vw
                margin-right: 1vw