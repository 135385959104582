.container-component-comments-SNC {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 7px;
    -webkit-box-shadow: -1px 5px 19px 0px #efecef;
    -moz-box-shadow: -1px 5px 19px 0px #efecef;
    box-shadow: -1px 5px 19px 0px #efecef;
    margin-bottom: 3vh;

    .header-commentsSNC {
        width: 100%;

        .header-titleSNC {
            border-bottom: 1px solid #c2c2c2;
            padding-bottom: 0.5vh;

            .title-componentSNC {
                color: #37a7b9;
                font-family: "Poppins";
                font-size: 1.2vw;
                font-weight: 500;
                display: flex;
                justify-content: center;
                padding-top: 1vh;
            }
        }
    }

    .body-SNC {
        width: auto;
        display: flex;
        flex-direction: column;
        margin: 10px 5px 15px 5px;

        .container-SNC {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;

            .container-SNC-left {
                width: 50%;

                .preco-row-SNC {
                    display: flex;
                    width: 90%;
                    margin: 5px 10px;
                    padding-bottom: 10px;
                    justify-content: space-between;
                    border-bottom:  1px solid #c2c2c2;

                    &:first-child{
                        border-bottom: none;
                    }
                    
                    

                    .text-header-SNC {
                        color: #303030;
                        font-family: "Poppins";
                        font-size: 0.9vw;
                        font-weight: 500;
                    }
                    .text-SNC {
                        color: #303030;
                        font-family: "Poppins";
                        font-size: 0.9vw;
                        font-weight: 300;
                    }

                    .input_check_SNC{
                        cursor: pointer;
                    }
                    
                }
            }

            .container-SNC-rigth {
                width: 50%;
                

                .preco-row-SNC {
                    display: flex;
                    width: 90%;
                    margin: 5px 10px;
                    padding-bottom: 10px;
                    justify-content: space-between;
                    border-bottom:  1px solid #c2c2c2;

                    &:first-child{
                        border-bottom: none;
                    }

                    .text-header-SNC {
                        color: #303030;
                        font-family: "Poppins";
                        font-size: 0.9vw;
                        font-weight: 500;
                    }
                    .text-SNC {
                        color: #303030;
                        font-family: "Poppins";
                        font-size: 0.9vw;
                        font-weight: 300;
                    }

                    .input_check_SNC{
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
