/*::-webkit-scrollbar {
    display: none;
}*/ 
.container-list-add-member-chat-modal {
    width: 100%;
    overflow: scroll;
    max-height: 25vh;

    .card-modal-chat-add-members {
        width: 99%;
        font-weight: 300;
        font-family: "Poppins";
        font-size: 1.2vw;
        border: solid 1px #707070;
        margin-bottom: 1vw;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        cursor: pointer;




        .name-add-memebers-list-modal-chat {
            width: 95%;
            display: flex;
            justify-content: left;
            padding-left: 0.4vw;
            padding-top: 0.4vw;
            align-items: center;


            .title-style-add-members-modal{
                font-size: 1vw;

            }
        }

        .clasification-add-memebers-chat-modal{

            padding-right: 0.4vw;
            display: flex;
            justify-content: flex-end;
            font-size: .9vw;
            align-items: center;

            .title-clasification-add-members-modal{
                display: flex;
                justify-content: flex-end;
                font-size: 0.8vw;
                align-items: center;

            }

        }
    }
}