.c-detalleproducto
    height: 90vh
    position: fixed
    top: 10%
    right: 0
    width: 30%
    padding: 8vh 0px
    overflow-x: hidden
    border-left: 2px solid #969696
    background: #fff
    .c-detalleproducto-c
        height: 70vh
        display: flex
        align-items: center
        justify-content: center
        box-sizing: border-box
        overflow: scroll
        flex-wrap: wrap
        align-content: flex-start
        font-family: 'Poppins'
        .ico-delete
            border: none
            background: #fff
            border-radius: 50%
            color: #ff6666
            font-size: 24px
            box-shadow: #000 3px 3px 6px -3px
            display: flex
            justify-content: center
            width: 30px
            height: 30px
            align-items: center
            position: relative
            outline: none
            cursor: pointer
#goLeft
    position: absolute
    top: 50%
    left: 0
    transform: translateY(-50%)
    width: 10%
    background: none
    border: none
    outline: none
    transition: .4s
    color: #073358
    &:hover
    cursor: pointer
    font-size: 3vw

#goRigth
    position: absolute
    top: 50%
    right: 0
    transform: translateY(-50%)
    width: 10%
    background: none
    border: none
    outline: none
    transition: .4s
    color: #073358
    &:hover
    cursor: pointer
    font-size: 3vw

