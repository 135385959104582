.atqAdministrator_ATQ_TransferirCX_Rechazada_container {
    padding-top: 20vh;
    height: 80vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .atqAdministrator_ATQ_TransferirCX_Rechazada-section-container{
        width: 95%;
        display: grid;
        grid-template-areas:
            "atqAdministrator_ATQ_TransferirCX_Rechazada-section-left atqAdministrator_ATQ_TransferirCX_Rechazada-section-rigth"
            "atqAdministrator_Assign_ATQ-section-bottom-button-container atqAdministrator_Assign_ATQ-section-bottom-button-container";
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: 1fr 0.2fr;

        .atqAdministrator_ATQ_TransferirCX_Rechazada-section-left {
            grid-area: atqAdministrator_ATQ_TransferirCX_Rechazada-section-left;
            width: 80%;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            padding-bottom: 10vh;
            height: 55vh;

        }

        .div_atq_before_red {

            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .img_atq_before_red {
                width: 1.1vw;
                height: 1.1vw;
                margin-left: 10vw;



            }

            .p_atq_before_red {
                font-family: "Poppins";
                color: #E30613;
                font-size: 1vw;
                font-weight: 400;
                text-align: right;
                width: 10vw;

            }

            .ListElementsCXAbstractComponent-left-overview-item-title-atqBefore {
                color: #E30613;
                font-size: 1vw;
                font-weight: 400;
            }

        }

        .div_atq_later_green {

            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;



            .img_atq_later_green {
                width: 1.1vw;
                height: 1.1vw;
                margin-left: 10vw;

            }

            .p_atq_later_green {
                color: #1ABC9C;
                font-family: "Poppins";
               font-size: 1vw;
                font-weight: 400;
                text-align: right;
                width: 10vw;

            }

            .ListElementsCXAbstractComponent-left-overview-item-title-atqLater {
                color: #1ABC9C;
                font-size: 1vw;
                font-weight: 400;
            }
        }


        .atqAdministrator_ATQ_TransferirCX_Rechazada-section-rigth{
            grid-area: atqAdministrator_ATQ_TransferirCX_Rechazada-section-rigth;
            width: 90%;
            justify-self: center;
            display: grid;



            .container_buttons_changes_ATQ_Transferir_Rechazada {
                width: 90%;
                display: flex;
                align-items: center;
                justify-content: end;
            



            .container_buttons_changes_ATQ_Transferir_Rechazada {
                width: 38%;
                display: flex;
                text-align: center;
                justify-content: center;
                align-items: center;
                max-height: 4vh;
                font-size: 1vw;
                color: #ffffff;
                font-family: "Poppins";
                background: linear-gradient(90deg, #094070 0%, #1abc9c 100%);
                border: none;
                border-radius: 20px;
                cursor: pointer;
                outline: none;
                box-shadow: 4px 3.9px 3px #c4c1c1;
            }
        }
    }
}
}