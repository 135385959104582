$gradient: linear-gradient(270deg, rgba(9,64,112,1) 0%, rgba(55,167,185,1) 91%)

%flexbox
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center

.container-factoryConfigurationPrincipalTabs
    width: 100%
    display: grid
    justify-items: center
    grid-auto-columns: 1fr
    grid-template-areas: 'factoryConfigurationPrincipalTabs-container-tabs' 'factoryConfigurationPrincipalTabs-container-surgery'
    .factoryConfigurationPrincipalTabs-container-tabs
        width: 100%
        @extend %flexbox
        .factoryConfigurationPrincipalTabs-cirugia
            border-radius: 0 0 0px 10px
        .factoryConfigurationPrincipalTabs-product
            border-radius: 0 0 10px 0px
        .factoryConfigurationPrincipalTabs-tab
            background: linear-gradient(145deg, #d0d0d0, #afafaf)
            box-shadow:  -2px 2px 5px #636363, 12px 15px 4px #ffffff
            width: 50%
            padding: 5px
            font-family: 'Poppins'
            font-size: 1.1vw
            border: none
            outline: none
        .tab-active
            background: $gradient
            box-shadow: inset 5px 5px 8px #16434a, inset -5px -5px 8px $gradient
            color: #fff
    .factoryConfigurationPrincipalTabs-container-surgery
        width: 100%
        @extend %flexbox
    .factoryConfigurationPrincipalTabs-container-products
        width: 100%
        margin-top: 10px
        display: flex 
        flex-direction: column
        justify-content: center
        align-items: center
        border: none
        border-radius: 10px
        background: linear-gradient(145deg, #ffffff, #e6e6e6)
        box-shadow:  2px 2px 5px #666666, -3px -3px 1px #ffffff
        .factoryConfigurationPrincipalTabs-products
            width: 100%
            
        