.c-users-sec2
    grid-template-columns: .7fr .7fr .7fr
    grid-template-areas: 'c-users-c-pedidos c-users-c-pedidos c-users-c-informacion' 'c-users-c-encuestas c-users-c-estatus c-users-c-estatus'
    .disabled
        opacity: .4
    .c-users-item-simpleform
        position: relative
        display: flex
        justify-content: center
        flex-direction: column
        align-items: center
        img
            position: absolute
            top: 10px
            height: 70%
            width: 90%
            object-fit: scale-down
            border-radius: 10px
        h3
            position: absolute
            bottom: 0px
            margin-top: 0px
            margin-bottom: 2vw
    .c-users-item-horizontalform
        position: relative
        display: flex
        justify-content: center
        flex-direction: column
        align-items: center
        img
            position: absolute
            top: 10px
            height: 70%
            width: 90%
            object-fit: scale-down
            border-radius: 10px
        h3
            position: absolute
            bottom: 0px
            margin-top: 0px
            margin-bottom: 2vw
    .c-users-item
        border-radius: 10px
        color: #fff
        cursor: pointer
        text-align: center
        align-self: stretch
        justify-self: stretch
        .users-item-title
            font-family: 'Poppins'
            font-weight: 400
    .c-users-c-pedidos
        grid-area: c-users-c-pedidos
        background: linear-gradient(270deg, #30b5cb 0%, #1abc9c 100%, #30b5cb 94070%)
    .c-users-c-encuestas
        grid-area: c-users-c-encuestas
        background: linear-gradient(270deg, #1abc9c 0%, #094070 91%)
    .c-users-c-informacion
        grid-area: c-users-c-informacion
        background: linear-gradient(93deg, #30b5cb 0%, #1abc9c 100%, #30b5cb 94070%)
        
    .c-users-c-estatus
        grid-area: c-users-c-estatus
        background: linear-gradient(93deg, #1abc9c 10%, #094070 100%)
