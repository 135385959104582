.sesions-container-ChatGroup {
    width: 100%;
    height: 66vh;
    overflow: scroll;
    border-bottom: 1px solid #303030;
    border-top: 1px solid #303030;


    .sesions-preContainer-active-ChatGroup {
        background: rgba(#1ABC9C, .3);
    }

    .sesions-preContainer-inactive-ChatGroup {
        background: #fff;
    }

    .sesions-preContainer-ChatGroup {
        width: 100%;
        border-top: none;
        border-bottom:1px solid #303030 ;
        &:first-child{
            border-top:none
        }
       

        .img-user-chat-modal-group{
            width: 2.5vw;
        }

       
        .sesions-info-container-ChatGroup {
            width: 90%;
            display: grid;
            grid-template-columns:.1fr 1fr .1fr;
            align-items: center;
            padding: .5vw; 
            grid-column-gap: 1vw;

            .sesions-container-info-ChatGroup {
                justify-self: center;
                cursor: pointer;
                width: 98%;

                .sesions-info-nameChat {
                    font-size: 1vw;
                    color: #303030;
                    display: inline-block;
                    padding-right: 1vw;
                    font-size: .8vw;
                    font-family: 'Poppins';
                }
            }

            .sesions-info-arrow-ChatGroup {
                justify-self: center;
                width: .7vw;
            }
        }
    }

    

    
  .group-isnull{
    font-size: 1vw;
    font-family: "Poppins";
    font-weight: 300;
    display: flex;
    justify-content: center;
    margin-top: 15%;
  }
}
