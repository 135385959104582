.precontainer_page_transfer{
    height: 80 vh;
    padding-top: 20vh;
    font-family: "Poppins";
    display: flex;
    align-items: center;
    flex-direction: column;
      
    .container_page_transfer{
        width: 90%;

        .section_list_transfer{
            display: grid;
            grid-template-columns: 1fr;
            height: 75vh;
            margin-top: 1vw;
        }
        .container_buttons_transfer{
            width: 100%;
            display: flex;
            grid-template-columns: 1;
            align-items: center;
            flex-direction: row;

            .toogle_container_buttons_transfer{
                width: 100%;
                display: flex;
                justify-content: center;

                .normal_button_transfer{
                    padding: 0.5vw;
                    border: none;
                    background: #707070;
                    color: #ffff;
                    cursor: pointer;
                    width: 14vw;
                    outline: none;
                }
                .btn_left_transfer{
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                   
                }
                .btn_center{
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    border-right: .5px solid #303030;
                    border-left: .5px solid #303030;
                }
                .btn_rigth{
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    
                }

                .btn_active_transfer{
                    background: #37a7b9;
                    background: linear-gradient(270deg, #1abc9c 0%, #094070 91%);
                }
            }
        }
    }
}
.section{
    padding-top: 70vh;
}