.section-question-medico {
    width: 95%;
    display: grid;
    background: #ffffff;
    box-shadow: 0px 1px 6px -1px rgb(0 0 0 / 35%);
    overflow: auto;
    margin-bottom: 3vh;
    align-self: center;
    justify-content: center;
    .title-question-medico {
        color: #094070;
        font-family: "Poppins";
        font-weight: 300;
        text-align: center;
        padding: 1vh 0;
        height: auto;
        border-bottom: 1px solid #c2c2c2;
    }
    .title-question-medico-sub {
        color: #094070;
        font-family: "Poppins";
        font-weight: 400;
        text-align: left;
        padding-top: 2vh;
        font-size: 1.8vh;
        height: auto;
        padding-left: 2vh;
    }
    .title-question-intern-medico {
        color: #9c9c9c;
        font-family: "Poppins";
        font-size: 0.9vw;
        font-weight: 300;
        padding: 2vh 0 2vh 1vw;
    }
    .title-question-intern-medico-finaly {
        color: #9c9c9c;
        font-family: "Poppins";
        font-size: 0.9vw;
        font-weight: 500;
        padding: 2vh 0 2vh 1vw;
    }
    .text-answer-medico {
        width: 98%;
        padding-bottom: 10px;
        margin-left: 1vw;
        color: #303030;
        font-family: "Poppins";
        font-size: 0.9vw;
        border-bottom: 1px solid #c2c2c2;
    }

    
    
}

//Estilos de medico
.section-question-medico-gerente {
    width: 100%;
    .container-table {
        width: 100%;
        background: #ffffff;
        border-radius: 7px;
        -webkit-box-shadow: -1px 5px 19px 0px #efecef;
        -moz-box-shadow:-1px 5px 19px 0px #efecef;
        box-shadow: -1px 5px 19px 0px #efecef;
        margin-bottom: 3vh;

        //Titulo

        .fila-title {
            border-bottom: 1px solid #c2c2c2;
            padding-bottom: 0.5vh;

            .title-question-medico {
                color: #1abc9c;
                font-family: "Poppins";
                font-size: 1.2vw;
                font-weight: 500;
                display: flex;
                justify-content: center;
                padding-top: 1vh;
            }
        }

        //Fila left
        .fila-info {
            padding: 1vh 0 1vh 0.5vw;
            width: 65%;
            border-bottom: 1px solid #c2c2c2;
            border-right: 1px solid #c2c2c2;

            .title-medico {
                color: #303030;
                font-family: "Poppins";
                font-size: 0.9vw;
                font-weight: 300;
            }
        }

        //Fila rigth
        .fila-info-sub {
            border-bottom: 1px solid #c2c2c2;
            text-align: center;

            .fila-subtitle-medico {
                color: #303030;
                font-family: "Poppins";
                font-size: 0.9vw;
                font-weight: 300;
                display: flex;
                justify-content: center;
            }
        }

        .fila-info-servicio {
            color: #303030;
            font-family: "Poppins";
            font-size: 0.9vw;
            font-weight: 500;
            padding: 1vh 0 1vh 0.5vw;
            border-right: 1px solid #c2c2c2;
        }

        .fila-finaly {
            padding: 1vh 0 6vh 0.5vw;

            .title-medico-finaly {
                color: #303030;
                font-family: "Poppins";
                font-size: 0.9vw;
                font-weight: 300;
            }
        }
    }
}
