.StoreHomeReceivingMaterialReportScreen-container{
    padding-top: 20vh;
    display: flex;
    justify-content: center;
    height: 70vh;
    font-family: 'Poppins';
    .StoreHomeReceivingMaterialReportScreen-table-container{
        width: 70%;
        display: grid;
        grid-template-rows: .2fr 1fr .2fr;
        .StoreHomeReceivingMaterialReportScreen-title-container{
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .StoreHomeReceivingMaterialReportScreen-title{
                width: 25%;
                padding: .5vw;
                margin: 0;
                color: #FFFFFF;
                font-size: 1.3vw;
                font-family: 'Poppins';
                font-weight: 300;
                border: none;
                background: #777777;
                border-radius: 1vw;
            }
        }


        
        .StoreHomeReceivingMaterialReportScreen-continue-button-container{
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .StoreHomeReceivingMaterialReportScreen-continue-button{
                height: 2vw;
                margin-top: 10px;
                border-radius: 30px;
                width: 16vw;
                background: rgb(9,64,112);
                background: linear-gradient(90deg, rgba(9,64,112,1) 0%, rgba(55,167,185,1) 100%);
                border: none;
                font-size: 1.3vw;
                font-family: 'Poppins';
                color: #FFFFFF;
                cursor: pointer;
                outline: none;
            }
        }
    }
}