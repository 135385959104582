.container-component-comments-AddComent {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 7px;
    -webkit-box-shadow: -1px 5px 19px 0px #efecef;
    -moz-box-shadow: -1px 5px 19px 0px #efecef;
    box-shadow: -1px 5px 19px 0px #efecef;
    margin-bottom: 3vh;

    .header-title-AddComent {
        width: 100%;
        display: flex;
        margin: 1vh;

        .container-body-add {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 1vh 1vw;

            .title-AddComent {
                color: #37a7b9;
                font-family: "Poppins";
                font-size: 1.2vw;
                font-weight: 500;
                display: flex;
                align-items: flex-start;
                //margin-left: -30vw;
            }

            .style-text {
                margin-top: 2vh;
                width: 95%;
                min-height: 12vh;
                font-family: "Poppins";
                font-size: 0.9vw;
                font-weight: 200;
                color: #303030;
                padding: .5vh .5vw;

            }
        }

        .container-body-add-conditional {
            flex-direction: column !important;
            margin-left: -0.2vw;
        }

        .img-add-AddComent {
            width: 2vw;
            cursor: pointer;
        }

        .img-delete-AddComent {
            width: 2vw;
            cursor: pointer;
            margin-right: -37vw;
            margin-top: 1vh;
        }
    }
}
