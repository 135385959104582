.notificaciones_ATQ_TransferirCX_container{
    padding-top: 20vh;
    height: 80vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .notificaciones_ATQ_TransferirCX_subcontainer{
        width: 95%;
        display: grid;
        grid-template-areas: "notificaciones_ATQ_TransferirCX_container_left notificaciones_ATQ_TransferirCX_container_rigth" "atqAdministrator_Assign_ATQ-section-bottom-button-container atqAdministrator_Assign_ATQ-section-bottom-button-container";
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: .8fr 0.2fr;

        .notificaciones_ATQ_TransferirCX_container_left{
            grid-area:notificaciones_ATQ_TransferirCX_container_left;
            width: 90%;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            padding-bottom: 10vh;
            height: 55vh;
        }

        .notificaciones_ATQ_TransferirCX_container_rigth{
            grid-area: notificaciones_ATQ_TransferirCX_container_rigth;
            width: 100%;
            justify-self: center;

            .notificacion_ATQ_TransferirCX_div_container{
              display: grid;
              grid-gap: 3vw;
                grid-template-columns: 1fr 1fr;
            }
           

         

            .container_buttons_ATQ_TransferirCX{
               display: flex;
               flex-direction: row;
               justify-content: flex-end;
               flex-direction: 2vw;
                 

                .btn_rechazar_ATQ_TransferirCX{
                    width: 25%;
                    height: 1.8vw;
                    font-size: 1vw;
                    color: #ffffff;
                    font-family: "Poppins";
                    background: #E30613;
                    border: none;
                    border-radius: 20px;
                    margin-right: 2vw;
                    cursor: pointer;
                    outline: none;
                    box-shadow: 4px 3.9px 3px #c4c1c1;

                }

                .btn_aceptar_ATQ_TransferirCX{
                    width: 25%;
                    
                    font-size: 1vw;
                    color: #ffffff;
                    font-family: "Poppins";
                    background: linear-gradient(90deg, #094070 0%, #1abc9c 100%);
                    border: none;
                    border-radius: 20px;
                    cursor: pointer;
                    outline: none;
                    box-shadow: 4px 3.9px 3px #c4c1c1;

                }
            }

        }
    }

}