.StoreHouseApproveOrderPreViewScreen-container-elements{
    width: 100%;
    display: grid;
    grid-template-rows: .2fr 1fr .2fr;
    .StoreHouseApproveOrderPreViewScreen-container-title{
        width: 100%;
        .StoreHouseApproveOrderPreViewScreen-title{
            width: 20%;
            padding: .5vw;
            text-align: center;
            color: #fff;
            border-radius: 2vw;
            font-weight: 300;
            background: #777777;
        }
    }

    .StoreHouseApproveOrderPreViewScreen-container-table{
        width: 100%;
        display: flex;
        justify-content: center;
        border-spacing: 0;
        padding-top: 1vw;
        box-shadow: 1px 1px 7px -2px #777777;
        .StoreHouseApproveOrderPreViewScreen-precontainer-table{
            width: 95%;
            .StoreHouseApproveOrderPreViewScreen-table{
                width: 100%;
                border-collapse: collapse;
                display: inline-block;
                box-sizing: border-box;
            }
            .StoreHouseApproveOrderPreViewScreen-table-tr{
                display: flex;
                width: 100%;
            }
            .StoreHouseApproveOrderPreViewScreen-table-head{
                width: 100%;
                display: flex;
                .StoreHouseApproveOrderPreViewScreen-table-title{
                    font-weight: 400;
                    border-bottom: .07vw solid #a7a7a7;
                    padding-bottom: 1vw;
                    width: calc( 100% / 3);
                }
            }
            .StoreHouseApproveOrderPreViewScreen-table-body{
                height: 40vh;
                width: 100%;
                overflow: auto;
                display:block;
                .StoreHouseApproveOrderPreViewScreen-body-data{
                    text-align: center;
                    padding: 1vw 0 .5vw 0;
                    color: #777777;
                    display: inline-block;
                    width: 33%;
                    box-sizing: border-box;
                }
            }
        }
        }

    .StoreHouseApproveOrderPreViewScreen-container-middle-buttons{
        width: 95%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .StoreHouseApproveOrderPreViewScreen-middle-buttons-pxq{
            width: 25%;
            padding: .5vw;
            border-radius: 1.5vw;
            color: #fff;
            background: linear-gradient(275deg, rgba(55,167,185,1) 0%, rgba(41,176,171,1) 72%, rgba(26,188,156,1) 100%);
            border: none;
            outline: none;
            cursor: pointer;
            box-shadow: 1px 1px 5px 0px #777;
        }

        .StoreHouseApproveOrderPreViewScreen-switch {
            position: relative;
            display: inline-block;
            width: 25%;
            height: 2.1vw;
          }
          
          /* Hide default HTML checkbox */
          .StoreHouseApproveOrderPreViewScreen-switch .StoreHouseApproveOrderPreViewScreen-switch-input {
            opacity: 0;
            width: 0;
            height: 0;
          }
          
          /* The slider */
          .StoreHouseApproveOrderPreViewScreen-slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
            text-align: center;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          
        .StoreHouseApproveOrderPreViewScreen-slider-color-red:before{
            background-color: #E30613;
        }
        .StoreHouseApproveOrderPreViewScreen-slider-color-green:before{
            background-color: #1ABC9C;
        }

          .StoreHouseApproveOrderPreViewScreen-slider:before {
            position: absolute;
            content: "";
            height: 2vw;
            width: 2vw;
            left: .1vw;
            bottom: 0;
            border: .05vw solid #fff;
            -webkit-transition: .4s;
            transition: .4s;
          }
          
          .StoreHouseApproveOrderPreViewScreen-switch-input:checked + .StoreHouseApproveOrderPreViewScreen-slider {
            background-color: #777777;
            
          }
          
          .StoreHouseApproveOrderPreViewScreen-switch-input:focus + .StoreHouseApproveOrderPreViewScreen-slider {
            box-shadow: 0 0 1px #2196F3;
          }
          
          .StoreHouseApproveOrderPreViewScreen-switch-input:checked + .StoreHouseApproveOrderPreViewScreen-slider:before {
            -webkit-transform: translateX(14.4vw);
            -ms-transform: translateX(14.4vw);
            transform: translateX(14.4vw);
          }
          
          /* Rounded sliders */
          .StoreHouseApproveOrderPreViewScreen-slider.StoreHouseApproveOrderPreViewScreen-round {
            border-radius: 34px;
          }
          
          .StoreHouseApproveOrderPreViewScreen-slider.StoreHouseApproveOrderPreViewScreen-round:before {
            border-radius: 50%;
          }
    }
}
.StoreHouseApproveOrderPreViewScreen-container-button-continue{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .StoreHouseApproveOrderPreViewScreen-button-continue{
        width: 20%;
        padding: .3vw;
        border-radius: 1vw;
        border: none;
        background: linear-gradient(275deg, rgba(9,64,112,1) 0%, rgba(55,167,185,1) 100%);
        color: #fff;
        outline: none;
        cursor: pointer;
        box-shadow: 2px 3px 4px -0.9px #777;
    }
}