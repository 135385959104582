.c-cityAndState
    width: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    .c-cityAndState-item-name
        font-family: 'Poppins'
        margin-bottom: 20px
        font-weight: 300
        font-size: 18px
        color: #6d6d6d
        margin-left: 20px
        border: none
        width: 90%
        outline: none
        margin-bottom: 20px
        padding-bottom: 10px
        border-bottom: 1px solid #a8a8a8
    .c-cityAndState-button
        width: 60%
        margin-top: 20px
        padding: 10px
        border-radius: 20px
        border: none
        background: linear-gradient(270deg, rgba(26,188,156,1) 0%, rgba(55,167,185,1) 91%)
        color: #fff
        outline: none
        cursor: pointer
        font-size: 16px