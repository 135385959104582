.containerGeneral-encuesta-mantenimiento {
    margin-top: 15vh;
    font-family: "Poppins";


    .body-encuestas-mantenimiento {
        margin-top: 20vh;

        .btn-encuestas-mantenimiento {
            display: flex;
            width: 80%;
            margin-left: 10%;
            justify-content: flex-end;

            .btn-mantenimiento {
                padding: 1.2vh 2.5vw;
                font-size: 2vh;
                color: #fff;
                font-family: "Poppins";
                background: #707070;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
               
            }

            .btn-mantenimiento-active {
                background: linear-gradient(152deg, rgba(55, 167, 185, 1) 0%, rgba(9, 64, 112, 1) 100%);
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
            }
        }

        .body-encuestas-list-mantenimiento {
          
            width: 80%;
            display: flex;
            justify-content: center;
            margin-left: 10%;
        }
    }
}
