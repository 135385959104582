/*::-webkit-scrollbar {
    display: none;
}*/
.general-left-containers{
    overflow: none;
    display: flex;
    flex-direction: column;

    .leftTitle {
        font-family: 'Poppins';
        font-size: 25px;
        color: #303030;
        font-weight: 400;
    }

    .search_container {
        margin-bottom: 1vw;
        display: flex;
        gap: 1.5vw;
        width: 90%;
       

        .img_back{
            width: 2vw;
            cursor: pointer;
        }
    }

    .search_container-normal{
        margin-bottom: 1vw;
        
    }


    .profile-list{
        overflow: scroll;
        width: 91.5%;
        height: 62.5vh;

        ::-webkit-scrollbar {
            display: none;
        }
        
        .sesions-container-list-member-chat-modal {
            width: 100%;
            overflow: scroll;
            max-height: 27vh;
           
        
        
        
            .sesions-card-modal-chat {
                width: 99%;
                font-weight: 300;
                font-family: "Poppins";
                font-size: 1.2vw;
                border: solid 1px #707070;
                margin-bottom: 1vw;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
        
        
        
                .sesions-name-memebers-list-modal-chat {
                    width: 95%;
                    display: flex;
                    justify-content: left;
                    padding-left: 0.4vw;
                    padding-top: 0.4vw;
                    align-items: center;
        
                    .sesions-title-style-members-modal {
                        font-size: 1vw;
                    }
                }
        
                .sesions-clasification-memebers-chat-modal {
                    padding-right: 0.4vw;
                    display: flex;
                    justify-content: flex-end;
                    font-size: .9vw;
                    align-items: center;
        
                    .sesions-title-clasification-members-modal {
                        display: flex;
                        justify-content: flex-end;
                        font-size: 0.8vw;
                        align-items: center;
        
                    }
                }
            }
        }
    }


}
