.StoreHomeAbailableSystemsItems-c-cervicales
    grid-template-areas: 'StoreHomeAbailableSystemsItems-c-cervicales-c-title' 'StoreHomeAbailableSystemsItems-c-cervicales-c-items'
    grid-area: StoreHomeAbailableSystemsItems-c-cervicales
    .StoreHomeAbailableSystemsItems-c-cervicales-c-title
        background: rgba(#313131, .4)
.StoreHomeAbailableSystemsItems-c-cervicales-c-items
    grid-area: StoreHomeAbailableSystemsItems-c-cervicales-c-items
    .StoreHomeAbailableSystemsItems-c-item
        .StoreHomeAbailableSystemsItems-c-item-img
        .StoreHomeAbailableSystemsItems-c-item-button
            .StoreHomeAbailableSystemsItems-item-button-ico