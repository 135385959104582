@media screen and ( max-width: 970px )
    .precontainer_atqHome
        .container_mod_rigth
            .section-1
                font-size: 14px
                .atq-item-horizontalform
                    img
                        width: 60px
                .atq-item-simpleform
                    font-size: 16px
                    img
                        width: 90px

            .section-2
                .atq-item-horizontalform
                    img
                        width: 90px
                .atq-item-simpleform
                    img
                        width: 60px
                .atq-c-informacion
                    .atq-item-title
                        font-size: 18px



@media screen and ( max-width: 900px )
    .precontainer_atqHome
        height: auto
        .container_mod_rigth
            grid-template-columns: 1fr
            grid-template-areas: 'seccion-1' 'seccion-2'
            .section-1
                font-size: 18px
                .atq-item-horizontalform
                    img
                        width: 100px
                .atq-item-simpleform
                    font-size: 18px
                    img
                        width: 130px
            .section-2
                grid-template-rows: 160px 120px 160px
                grid-template-columns: 1fr 1fr
                grid-template-areas: 'c-home-c-pedidos c-home-c-informacion''c-home-c-pedidos c-home-c-estatus' 'c-home-c-encuestas  c-home-c-estatus'
                .atq-item-horizontalform
                    img
                        width: 130px
                .atq-item-simpleform
                    img
                        width: 100px
                .atq-c-informacion
                    .atq-item-title
                        font-size: 18px
        