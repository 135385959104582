$gradient: linear-gradient(270deg, rgba(9,64,112,1) 0%, rgba(55,167,185,1) 91%)

.container-FactoryConfigurationSystemTab
    .factory-surgery-active
            background: $gradient
            box-shadow: inset 5px 5px 8px #16434a, inset -5px -5px 8px $gradient
            color: #fff
    .FactoryConfigurationSystemTab-container-surgery
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        .FactoryConfigurationSystemTab-surgery-container-img
            .FactoryConfigurationSystemTab-surgery-img
        .FactoryConfigurationSystemTab-surgery-container-title
            .FactoryConfigurationSystemTab-surgery-title