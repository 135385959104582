.container {
    padding-top: 20vh;
    height: 80vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 95vw;

        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        color: #094070;
        font-weight: 700;
    }

    .title {
        display: block;
        align-items: center;
    }

    hr {
        width: 30%;
        text-align: start;
        height: 4px;
        background-color: #37A7B9;
        margin-left: 0;
        border: none;
    }
    
    .importBtn {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        /*width: '175px';
        height: '46px';*/
        background: #7EC573;
        border-radius: 50px;
        padding: 20px;
        font-family: 'Roboto';
        font-weight: 700;
        font-size: 16px;
        text-transform: uppercase;
        color: #FFFFFF;

        img {
            margin-right: 15px;
        }
    }

    .dateContainer {
        width: 95vw;
        display: flex;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 0px;
        font-weight: 700;
        font-size: 20px;
        font-family: 'Montserrat', sans-serif;
    }

    .bottomBar {
        background: #E5E9F2;
        display: flex;
        width: 100%;
        height: 82px;
        position: fixed;
        bottom: 30px;
        justify-content: end;
        align-items: center;
        padding-right: 50px;
    
        .blueBtn {
            height: 46px;
            display: flex;
            align-items: center;
            background: linear-gradient(90deg, #37A7B9 0%, #094070 100%);
            color: #FFFFFF;
            font-family: 'Inter', sans-serif;
            border-radius: 50px;
            padding: 20px;
            font-size: 16px;

            img {
                margin-right: 15px;
            }
        }
    }
    
}

.tableContainer {
    margin-top: 34px;
    width: 95vw;
}

