@import '../../styles/styles.scss';
.agenda_card {
    width: 40%;
    max-width: 400px;
    padding: 1.5rem 0;
    border: 1px solid #ECECEC;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    // box-shadow: 0px 0px 4px 1px #707070;
}
.card_title {
    width: 100%;
    height: 36px;
    margin: 0 0 1rem 0;
    background-color: $second_blue;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}
.card_body {
    width: 100%;
    padding: 0 1rem;
    max-width: 350px;
}
.two_columns {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .input_and_label {
        width: 50%;
        &:last-child {
            width: 40%;
        }
    }
}
.margin_top_base {
    margin-top: 8px;
}
.input_and_label {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 8px;
    label {
        font-weight: bold;
        margin-bottom: 4px;
    }
    input, select {
        border: 2px solid #C8C9C9;
        padding: 5px;
        border-radius: 8px;
    }
}
.title_text {
    margin-bottom: 1rem;
    div {
        margin-left: 8px;
        p {
            color: #777777;
            margin-bottom: 6px;
        }
    }
}
.total_content {
    margin-top: auto;
    margin-bottom: 0;
    div {
        display: flex;
        justify-content: space-between;
        p {
            color: #777777;
        }
    }
}