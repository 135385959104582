.container {
    padding-top: 20vh;
    height: 80vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    margin-bottom: 100px;

    .tableContainer {
        width: 95vw;
        z-index: 0;
    }

    .filterContainer {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
}