.c-sidebar
    width: 30%
    height: 100vh
    background-color: rgba(#303030, .9 )
    position: fixed
    left: -100%
    transition: 850ms
    z-index: 9999
    overflow-y: scroll
    top: 0
.active
    left: 0%
    transition: 850ms