.container-header-encuesta {
    width: 100%;
    display: flex;
    flex-direction: column;

    .body-page-infoEncuestas {
        width: 90%;
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        border-bottom: 2px solid #c2c2c2;
        .container-component {
            width: 48.7%;
            margin: 5vh 0;

            .comentario{
                width: 44vw;
                display: flex;
                flex-direction: column;
                background: white;
                border-radius: 7px;
                box-shadow: -1px 5px 19px 0px #efecef;
                margin-bottom: 3vh;
            }

            .cont-btn-footer{
                display: flex;
                justify-content: flex-end;

                .btnView-cancelar{
                    background: #707070;
                    color: #fff;
                    padding: 1.2vh 3vw;
                    font-family: "Poppins";
                    font-size: 0.9vw;
                    border-radius: 25px;
                    
                }
                .btnView-guardar{
                    background: linear-gradient(90deg, #37a7b9 10%, #094070 100%);
                    color: #fff;
                    padding: 1.2vh 3vw;
                    font-family: "Poppins";
                    font-size: 0.9vw;
                    border-radius: 25px;
                    margin-left: 1vw;
                }
            }
        }
         .clasificacion-body {
             display: flex;
             justify-content: space-between;
             
         }    
        
    }
}
