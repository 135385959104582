.precontainer_page-asigStatus{
    padding: 28vh 0 0 0;
    font-family: "Poppins";
    display: flex;
    align-items: center;
    flex-direction: column;

    .container_page-asigStatus{
        width: 85%;

        .container_buttons-asigStatus{
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 0.2fr 0.8fr;
            justify-content: flex-end;
            align-items: center;
            flex-direction: row;
            margin-top: -8vh;
          
            
            .toogle_container_buttons-asigStatus{
                width: 100%;
                display: grid;
                grid-column-start: 3;
                grid-template-columns: 1fr 1fr;
                margin-left: 2vw ;                
    
                .normal_button-atq-asigStatus{
                    padding: 0.5vw;
                    border: none;
                    background: #707070;
                    font-family: "Poppins";
                    color: #ffff;
                    cursor: pointer;
                    outline: none;
                }
                .btn_left-atq-asigStatus{
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    font-family: "Poppins";
                  
                }

                .btn_active-atq-asigStatus{
                    font-family: "Poppins";
                    background: linear-gradient(270deg, #1abc9c 0%, #094070 91%);
                }
                .btn_rigth-atq-asigStatus{
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    font-family: "Poppins";
                    
                }
                
            }
        }

        .section_list-asigStatus {
            display: grid;
            grid-template-columns: 1fr;
            height: 75vh;
            margin-top: 1vw;
        }
    }
}