%stylesToButton
    cursor: pointer
    outline: none
    border: none
    
.section-1
    grid-template-columns: 1fr 1fr
    grid-template-areas: 'quality-historial  quality-disponibilidad' 'quality-historial quality-rol-guardias' 'quality-asignar quality-rol-guardias'
    .disabled
        opacity: .4
    .quality-item-simpleform
        display: flex
        justify-content: center
        flex-direction: column
        align-items: center
        background: linear-gradient(190deg, rgba(9,9,121,1) 0%, rgba(55,167,185,1) 100%, rgba(9,9,121,1) 094070%)
        img
            width: 9vw
    .quality-item-horizontalform
        display: flex
        flex-direction: row
        justify-content: space-around
        align-items: center
        background: linear-gradient(0deg, rgba(9,9,121,1) 0%, rgba(32,90,154,1) 29%, rgba(55,167,185,1) 100%)
        img
            width: 7vw
    .quality-item
        border-radius: 10px
        color: #fff
        cursor: pointer
        text-align: center
        align-self: stretch
        justify-self: stretch
        text-decoration: none
        @extend %stylesToButton
        .quality-item-title
            font-size: 1.5vw
            font-family: 'Poppins'
            font-weight: 300
    .quality-historial
        display: flex
        grid-area: quality-historial
        flex-direction: column
    .quality-asignar
        grid-area: quality-asignar
        padding-left: 15px
    .quality-disponibilidad
        grid-area: quality-disponibilidad
    .quality-rol-guardias
        grid-area: quality-rol-guardias