.atqAdministrator_Assign_ATQ_container {
    padding-top: 20vh;
    height: 80vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    .atqAdministrator_Assign_ATQ-section-container {
        width: 95%;
        display: grid;
        grid-template-areas: "atqAdministrator_Assign_ATQ-section-left atqAdministrator_Assign_ATQ-section-rigth" "atqAdministrator_Assign_ATQ-section-bottom-button-container atqAdministrator_Assign_ATQ-section-bottom-button-container";
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: 1fr 0.2fr;

        .atqAdministrator_Assign_ATQ-section-left {
            grid-area: atqAdministrator_Assign_ATQ-section-left;
            width: 80%;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            padding-bottom: 10vh;
            height: 55vh;
            .atqAdministrator_Assign_ATQ-left-item {
                margin-top: 5vh;
            }
        }
        .atqAdministrator_Assign_ATQ-section-rigth {
            grid-area: atqAdministrator_Assign_ATQ-section-rigth;
            width: 90%;
            justify-self: center;
            display: grid;

            .container_buttons {
                display: grid;
                grid-template-columns: 1fr 0.8fr;
                grid-gap: 1vw;

                .btn_apoyo {
                    width: 80%;
                    padding: 0.5vw;
                    max-height: 5vh;
                    font-size: 1vw;
                    color: #ffffff;
                    font-family: "Poppins";
                    background: linear-gradient(90deg, #094070 0%, #1abc9c 100%);
                    border: none;
                    border-radius: 20px;
                    cursor: pointer;
                    outline: none;
                    box-shadow: 4px 3.9px 3px #c4c1c1;
                }
                .btn_cambiar {
                    width: 75%;
                   padding: 0.5vw;
                   max-height: 5vh;
                    font-size: 1vw;
                    color: #ffffff;
                    font-family: "Poppins";
                    background: linear-gradient(90deg, #094070 0%, #1abc9c 100%);
                    border: none;
                    border-radius: 20px;
                    cursor: pointer;
                    outline: none;
                    box-shadow: 4px 3.9px 3px #c4c1c1;
                }
            }

            .btn-Aceptar {
                width: 33% !important;
                max-height: 5vh;
                margin-right: 4.5vw;
                box-shadow: 4px 3.9px 3px #c4c1c1;
            }
            .atqAdministrator_Assign_ATQ-subTitle-container {
                width: 40%;
                margin-left: 5vh;
                justify-self: end;
                justify-content: end;
                font-size: 1vw;
                text-align: center;
                color: #ffffff;
                font-family: "Poppins";
                background: linear-gradient(90deg, rgba(9, 64, 112, 1) 0%, rgba(26, 188, 156, 1) 100%);
                border-radius: 20px;
                border: none;
                cursor: pointer;
                outline: none;
                margin-top: 3vw;
                padding: 0.5vw;
                box-shadow: 4px 3.9px 3px #c4c1c1;
                margin-right: 9vh;
            

                .atqAdministrator_Assign_ATQ-subTitem-title {
                    margin: 0;
                }
            }

            .atqAdministrator_Assign_ATQ-subTitle-container-asignar {
                width: 40%;
                margin-left: 5vh;
                justify-self: end;
                justify-content: end;
                font-size: 1vw;
                text-align: center;
                color: #ffffff;
                font-family: "Poppins";
                background: linear-gradient(90deg, rgba(9, 64, 112, 1) 0%, rgba(26, 188, 156, 1) 100%);
                border-radius: 20px;
                border: none;
                cursor: pointer;
                outline: none;
                margin-top: 3vw;
                padding: 0.5vw;
                box-shadow: 4px 3.9px 3px #c4c1c1;
                margin-right: 9vh;
        
                

                .atqAdministrator_Assign_ATQ-subTitem-title-asignar {
                    margin: 0;
                }
            }

            .atqAdministrator_Assign_ATQ-subTitle-container-transfer {
                width: 40%;
                max-width: 40%;
                height: 2vw;
                max-height: 2vw;
                margin-left: 5vh;
                justify-self: end;
                justify-content: end;
                font-size: 1vw;
                text-align: center;
                color: #ffffff;
                font-family: "Poppins";
                background: linear-gradient(90deg, rgba(9, 64, 112, 1) 0%, rgba(26, 188, 156, 1) 100%);
                border-radius: 20px;
                border: none;
                cursor: pointer;
                outline: none;
                margin-top: 3vw;
                padding: 0.5vw;
                box-shadow: 4px 3.9px 3px #c4c1c1;
                margin-right: 9vh;
            
                

                .atqAdministrator_Assign_ATQ-subTitem-title-asignar-transfer {
                    margin: 0;
                }
            }
        }
    }
}
