.profile-card-container {
    display: grid;
    grid-template-columns: .1fr .1fr 1fr .1fr;
    border-left-color: #2A99AB;
    margin-bottom: 30px;
    border-radius: 5px;
    box-shadow: 3px 1px 10px 0 rgba(166, 171, 189, 0.5);
    align-items: center;
    margin-top: 0.5vw;
    margin-right: 0.5vw;

    .blue-line-content {
        width: .5vw;
        height: 100px;
        background-color: #2A99AB;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        margin-right: .5vw;
    }

    .noImage {
        display: none;
    }

    .profile-card-left-bar {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .image-circle {
            width: 3.5vw;
            height: 6.5vh;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 2px solid #37A7B9;
            overflow: hidden;
            margin-right: .5vw;

            img {
                width: 3.5vw;
            }
        }
    }

    .allwidthpart {
        margin-left: 1rem;
        width: 100% !important;
    }

    .profile-card-right-bar {
        display: flex;
        flex-direction: column;
        color: #094070;
        justify-content: left;
        cursor: pointer;
        width: 17vw;
        padding: 0.5vw;
    

        span {
            padding-right: 1.4rem;
            margin-bottom: 2px;
        }

        .title-leftlist {
            font-size: 1.2vw;
            font-family: "Poppins";
            font-weight: 500;
            text-transform: capitalize;
            width: auto !important;
        }

        .title-left {
            font-size: 1.2vw;
            font-weight: 400;
            text-transform: capitalize;
        }

        .subtitle-leftlist {
            font-size: 1vw;
            font-family: "Poppins";
            font-weight: 500;
        }

        .subtitle2-leftlist {
            font-size: 1vw;
            font-family: "Poppins";
            width: 13vw;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-weight: 300;
        }

       
    }

    .container-img{
        .arrowIcon {
            width: .5vwpx;
            height: 1vw;
        }

    }


}

.profile-blue-card-container {
    background-color: #2A99AB !important;

    .profile-card-left-bar {
        .image-circle {
            background: #fff;
            border-color: solid 1px #CDCCCC;
        }
    }

    .profile-card-right-bar {
        color: #ffffff;
    }
}
