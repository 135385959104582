.container-general-vendedor-survey {
    width: 95%;
    box-shadow: 0px 1px 6px -1px rgb(0 0 0 / 35%);
    padding-bottom: 2vh;
    margin-bottom: 3vh;

    //background: red;

    .header-title-vendedor-survey {
        display: flex;
        // background: gray;
        justify-content: center;
        border-bottom: 1px solid #c2c2c2;

        .text-title-vendedor-survey {
            padding: 1.2vh 1vw;
            color: #094070;
            font-family: "Poppins";
            font-weight: 300;
            font-size: 1.2vw;
        }
    }

    .body-surtido-vendedor-survey {
        .subtext-title-vendedor-survey {
            padding: 1.2vh 1vw;
            font-family: "Poppins";
            font-weight: 300;
            font-size: 0.9vw;
        }

        .textarea-vendedor-survey {
            width: 94%;
            display: flex;
            margin-left: 2%;
            font-family: "Poppins";
            font-weight: 300;
            font-size: 0.9vw;
            color: #707070;
            height: 50px;
            margin-top: 1vh;
            padding: 5px;
            background:white;
        }
    }

    // .body-surtido-vendedor-survey{
    //     background: red;
    // }
}
