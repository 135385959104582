.HospitalHome-principal-container{
    width: 100%;
    height: 80vh;
    padding-top: 9vw;
    display: flex;
    justify-content: center;
    .HospitalHome-main-page-container {
        display: flex;
        width: 95%;
        flex-direction: row;
        margin-bottom: 0!important;
        margin-right: 0!important;
        .HospitalHome-left-bar {
            width: 30%;
            border-right: 2px solid rgba(119,119,119,0.7);
            padding-right: 20px;
            height: calc( 100vh - 160px);
            overflow-y: auto;
            .profile-list {}
        }
        .HospitalHome-right-bar {
            width: 70%;
            padding-left: 40px;
            height: calc( 100vh - 160px);
            overflow-y: auto;
            overflow-x: hidden;
            padding-right: 40px;
        }
    }
    
        .HospitalHome-left-bar::-webkit-scrollbar, .right-bar::-webkit-scrollbar {
            -webkit-appearance: none;
        }
        .HospitalHome-left-bar::-webkit-scrollbar:vertical, .right-bar::-webkit-scrollbar:vertical {
            width:10px;
        }
        .HospitalHome-left-bar::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button, .right-bar::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
            display: none;
        } 
        .left-bar::-webkit-scrollbar:horizontal, .right-bar::-webkit-scrollbar:horizontal {
            height: 10px;
        }
        .HospitalHome-left-bar::-webkit-scrollbar-thumb, .right-bar::-webkit-scrollbar-thumb {
            background-color: #777777;
            border-radius: 20px;
            border: 2px solid #f1f2f3;
        }
        .HospitalHome-left-bar::-webkit-scrollbar-track, .right-bar::-webkit-scrollbar-track {
            border-radius: 10px;  
        }
    
}
