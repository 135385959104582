.principal-container-mantenimiento {
    display: flex;
    width: 100%;
    justify-content: center;

    .post-container-manteniiento {
        display: grid;
        width: 90%;
        height: 82vh;
        margin-top: 18vh;
        display: flex;
        grid-template-columns: 1fr;
        grid-gap: 1.8vw;

        .primer-fila-mantenimiento {

            display: grid;
            width: 100%;
            justify-content: center;
            grid-template-columns: 0.5fr 0.5fr;
            grid-gap: 4vw;
            margin-bottom: 12vh;


            .agenda-manteminiemto {
                border-radius: 10px;
                background: linear-gradient(90deg, #090979 23%, #37a7b9 75%, #090979 94070%);
                display: grid;
                grid-template-columns: 1fr 1fr;
                cursor: pointer;
                outline: none;
                border: none;
                opacity: .6;
                width: 90%;
                justify-self: end;
            }

            .img-agenda-mantenimiento {
                justify-self: center;
                align-self: center;
                width: 12vw;
            }

            .h3-agenda-mantenimiento {
                font-size: 1.8vw;
                font-family: "Poppins";
                font-weight: 300;
                color: #fff;
                align-self: center;

            }


            .encuestas-mantenimiento {
                border-radius: 10px;
                background: linear-gradient(90deg, #37a7b9 23%, #1abc9c 75%, #37a7b9 94070%);
                display: grid;
                grid-template-columns: 1fr 1fr;
                cursor: pointer;
                outline: none;
                border: none;
                width: 90%;

                .img-encuestas-mantenimiento {
                    justify-self: center;
                    align-self: center;
                    width: 12vw;
                }

                .h3-encuestas-mantenimiento {
                    font-size: 1.8vw;
                    font-family: "Poppins";
                    font-weight: 300;
                    color: #fff;
                    align-self: center;

                }
            }

        }


    }
}