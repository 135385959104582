.container-rep-com{
    padding-top: 20vh;
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: "Poppins";

    .precontainer-rep-com{
        display: grid;
        grid-template-columns: 1fr 1.3fr;
        grid-template-rows: 0.7fr 0.3fr;
        width: 95%;
        justify-content: center;
        grid-gap: 0 6vw;

        .section_right_repo{
            display: grid;
            grid-template-rows: 0.65fr 0.1fr;
            grid-gap: 2vw;
            width: 95%;
            height: 87vh;

            .btn-container-repo{
                display: flex;
                flex-direction: row;

                .file{
                    display: none;
                }
                .LeftInfoComponent-button{
                    width: 3vw;
                    height: 3vw;
                    background: linear-gradient(275deg, #094070 0%, #37a7b9 100%);
                    border: none;
                    cursor: pointer;
                    outline: none;
                    border-radius: 0.5vw;
                    margin-right: 2vw;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    img{
                        width: 2vw;
                    }
                }
            }
        }
        .section_left_repo{
            display: grid;
            grid-template-rows: 0.65fr 0.1fr;
            grid-gap: 2vw;
            width: 100%;
            .btnF_container{
                display: grid;
                grid-template-columns: 1fr 1fr;

                .des-button-view{
                    align-items: center;
                    background: linear-gradient( 
                270deg, #1abc9c 0%, #094070 91%);
                    border-radius: 20px;
                    border: none;
                    color: #fff;
                    cursor: pointer;
                    display: flex;
                    font-size: 14px;
                    flex-direction: row;
                    font-family: "Poppins";
                    font-weight: 300;
                    text-transform: uppercase;
                    padding: 8px 1rem;
                    margin-left: 1rem;
                    min-width: 16vw;
                    justify-content: center;
                    font-weight: 500;
                    outline: none;
                    height: 3vh;
                    width: 70%;
                    justify-self: end;
                }
                .des-button-finish{
                    align-items: center;
                    background: linear-gradient( 
                270deg, #1abc9c 0%, #094070 91%);
                    border-radius: 20px;
                    border: none;
                    color: #fff;
                    cursor: pointer;
                    display: flex;
                    font-size: 14px;
                    flex-direction: row;
                    font-family: "Poppins";
                    font-weight: 300;
                    text-transform: uppercase;
                    padding: 8px 1rem;
                    margin-left: 1rem;
                    min-width: 16vw;
                    justify-content: center;
                    font-weight: 500;
                    outline: none;
                    height: 5vh;
                    width: 70%;
                    justify-self: end;
                }
            }
            
            .totalF_contaier{
                display: grid;
                grid-template-columns: 1fr 1fr;
                width: 100%;
                .container-check{
                    display: flex;
                    .style-check{
                        width: 1.3vw;
                        height: 1.3vw;
                        margin-right: 1vw;
                    }
                    .txt-check{
                        font-family: "Poppins";
                        margin-top: 0.4vh;
                    }
                }
                .totalprice{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;

                    .totalpriceelement{
                        display: flex;
                        width: 73%;
                        justify-content: space-between;
                        border-radius: 10px;
                        border: 2px solid #DCDCDC;
                        padding: 2px 6px;
                        margin-bottom: 1rem;

                        .totalpriceelementtitle{
                            font-size: 18px;
                            color: #094070;
                            font-weight: 600;
                        }
                        .totalpriceelementvalue{
                            font-size: 18px;
                            color: #303030;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}