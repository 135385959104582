.c-share{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .c-share-c-search{
        width: 100%;
        margin: 20px 0;
        display: flex;
        flex-direction: row;
        justify-items: flex-start;
        align-items: center;
        font-size: 16px;
        margin-left: 3vw;
        .active{
            background: linear-gradient(270deg, rgba(9,64,112,1) 0%, rgba(55,167,185,1) 91%) !important;
        }
        .c-share-button{
            width: 45%;
            padding: 8px 0;
            color: #fff;
            background: #707070;
            border: none;
            outline: none;
            border-radius: 20px;
        }
        .share-c-input-search{
            width: 100%;
            display: flex;
            align-items: center;
            justify-items: center;
            .c-share-search-icobuscar{
                background: #dddddd;
                padding: 0px 6px 0px 12px;
                border-radius: 20px 0 0 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 27px;
                border: none;
            
                img{
                    width: 18px;
                }
            }
            .share-input-search{
                border: none;
                width: 83%;
                background: #dddddd;
                border-radius: 0 20px 20px 0;
                outline: none;
                padding: 5px;
                height: 17px;
                color: #777777;
            }
        }
    }

    .txt-Enviar{
        display: flex;
        width: 90%;
        margin-bottom: 3vh;
        margin-left: 1.5vw;
        /* align-items: flex-start; */
        /* align-content: flex-start; */
        align-self: flex-start;
        font-family: 'Poppins';
        border-bottom: 1px solid #a8a8a8;
    }
    .c-share-c-list-items{
        width: 90%;
        margin-bottom: 20px;
        height: 38vh;
        .c-share-c-list-items-scroll{
            height: 31vh;
            overflow-y: scroll;
            .c-share-c-item{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 5px;
                padding-top: 5px;
                border-bottom: 1px solid #a8a8a8;

                .img-group-chat{
                    width: 3vw;
                }
                .c-share-item-cod{
                    font-family: "Poppins";
                    font-weight: 450;
                    font-size: 18px;
                    text-align: left;
                    width: 24vw;
                    color: #303030;
                }
                .c-share-item-name{
                    font-family: 'Poppins';
                    font-weight: 300;
                    font-size: 18px;
                    text-align: left;
                    color: #696969;
                    width: 24vw;
                }
                .c-share-item-check{
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    margin-right: .5rem;
                    background-size: cover;
                }
            }
        }
        .c-share-button{
            width: 60%;
            padding: 10px;
            margin-top: 30px;
            border-radius: 20px;
            border: none;
            background: linear-gradient(270deg, rgba(26,188,156,1) 0%, rgba(55,167,185,1) 91%);
            color: #fff;
            outline: none;
            cursor: pointer;
            font-size: 16px;
        }

    }

}