.ATQ_Administrator_GuardRol_View_container {
    width: 100%;
    height: 80vh;
    padding-top: 20vh;
    font-family: "Poppins";
    display: flex;
    justify-content: center;

    .ATQ_Administrator_GuardRol_View_items_container {
        width: 90%;
        display: grid;
        grid-template-rows: .1fr .7fr .1fr;
        grid-template-columns: 1fr;

        .ATQ_Administrator_GuardRol_View_buttons_team_container {
            width: 100%;
            display: flex;
            overflow-x: scroll;
            overflow-y: hidden;
            gap: 1vw;
           // height: 3vw;
            padding: 0 .5vw 0 .5vw;

            .ATQ_Administrator_GuardRol_View_button_team {
                background-color: #707070;
                padding: 0.5vw;
                font-size: 0.9vw;
                font-weight: 500;
                color: #fff;
                border-radius: 2vw;
                border: none;
                cursor: pointer;
                min-width: 9vw;
                max-width: 9vw;
                height: 2vw;
                max-height: 2vw;
            }

            .ATQ_Administrator_GuardRol_View_button_team_active {
                background: linear-gradient(270deg, rgba(26, 188, 156, 1) 0%, rgba(9, 64, 112, 1) 100%);
                padding: .5vw;
                font-size: .9vw;
                font-weight: 500;
                color: #fff;
                border-radius: 2vw;
                border: none;
                cursor: pointer;
            }

        }

        .ATQ_Administrator_GuardRol_View_calendar_container {
            width: 100%;
            overflow-y: scroll;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 2vw;
            padding: 1vw 0;
        }

        .ATQ_Administrator_GuardRol_View_buttons_container {
            display: grid;
            width: 40%;
            grid-gap: 2vw;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            justify-self: end;
            grid-template-rows: 1fr;

            .ATQ_Administrator_GuardRol_View_button {
                padding: .5vw;
                font-size: .9vw;
                font-weight: 500;
                color: #fff;
                border-radius: 2vw;
                border: none;
                cursor: pointer;
                box-shadow: 4px 3.9px 3px #c4c1c1;

            }

            .ATQ_Administrator_GuardRol_View_button:nth-child(1) {
                background: linear-gradient(270deg, rgba(26, 188, 156, 1) 0%, rgba(9, 64, 112, 1) 100%);

            }

            .ATQ_Administrator_GuardRol_View_button:nth-child(2) {
                background: linear-gradient(90deg, rgba(26, 188, 156, 1) 0%, rgba(9, 64, 112, 1) 100%);

            }
        }

    }
}