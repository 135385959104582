.container-general-modal-chat {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .form-chat-modal {
        width: 100%;


        .container-first-modal-chat {
            width: 100%;

            .input-body-modal-chat {
                width: 80%;
                font-family: "Poppins";
                height: 5vh;
                font-weight: 300;
                max-height: 4vh;
                font-size: 1.2vw;
                
            }
            .input-body-modal-chat:focus{
                outline: none;
            }

        }


        .container-second-modal-chat {
            width: 100%;


            .btn-aceptar-modal-chat {
                width: 40%;
                padding: 6px;
                border-radius: 10vw;
                background: linear-gradient(270deg, #1abc9c 0%, #37a7b9 91%);
                color: #fff;
                cursor: pointer;
                font-size: 0.9vw;
                font-family: "Poppins";
                margin-top: 1.5vw;
                font-weight: 500;
            }
        }

    }
}