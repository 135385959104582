.mess_medic
    width: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    .mess_medic-c-search
        width: 90%
        margin: 20px 0
        display: flex
        flex-direction: row
        justify-items: flex-start
        align-items: center
        font-size: 16px
        .active
            background: linear-gradient(270deg, rgba(26,188,156,1) 0%, rgba(55,167,185,1) 91%) !important
    .mess_medic-c-list-items
        width: 90%
        margin-bottom: 20px
        height: 40vh
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        .mess_medic-c-list-items-scroll
            height: 40vh
            overflow-y: scroll
            .mess_medic-modal
                overflow: auto
                height: 30vh
                width: 32vw
                border-radius: 7px
        .mess_medic-button
            width: 60%
            padding: 10px
            margin-top: 30px
            border-radius: 20px
            border: none
            background: linear-gradient(270deg, rgba(26,188,156,1) 0%, rgba(55,167,185,1) 91%)
            color: #fff
            font-family: "Poppins"
            outline: none
            cursor: pointer
            font-size: 16px