@media screen and ( max-width: 970px )
    .c-users-principal
        .c-users
            .c-users-sec1
                font-size: 14px
                .c-users-item-horizontalform
                    img
                        width: 90%
                .c-users-item-simpleform
                    font-size: 16px
                    img
                        width: 90%
            .c-users-sec2
                .c-users-item-horizontalform
                    img
                        width: 90%
                .c-users-item-simpleform
                    img
                        width: 90%
                .c-users-c-informacion
                    .users-item-title
                        font-size: 18px

@media screen and ( max-width: 900px )
    .c-users-principal
        height: auto
        .c-users
            grid-template-columns: 1fr
            grid-template-areas: 'c-users-sec1' 'c-users-sec2'
            .c-users-sec1
                font-size: 18px
                .c-users-item-horizontalform
                    img
                        width: 90%
                .c-users-item-simpleform
                    font-size: 18px
                    img
                        width: 90%
            .c-users-sec2
                grid-template-rows: 160px 120px 160px
                grid-template-columns: 1fr 1fr
                grid-template-areas: 'c-users-c-pedidos c-users-c-informacion''c-users-c-pedidos c-users-c-estatus' 'c-users-c-encuestas  c-users-c-estatus'
                .c-users-item-horizontalform
                    img
                        width: 90%
                .c-users-item-simpleform
                    img
                        width: 90%
                .c-users-c-informacion
                    .users-item-title
                        font-size: 18px
        