%stylesToButton
    cursor: pointer
    outline: none
    border: none

.section-2
    grid-template-columns: .7fr .7fr .7fr
    grid-template-areas: 'quality-encuesta quality-agenda quality-agenda' 'quality-transferir quality-transferir quality-rol-guardias'
    .disabled
        opacity: .4
    .quality-item-simpleform
        display: flex
        justify-content: center
        flex-direction: column
        align-items: center
        img
            width: 7vw
    .quality-item-horizontalform
        display: flex
        flex-direction: row
        justify-content: space-around
        align-items: center
        img
            width: 9vw
    .quality-item
        border-radius: 10px
        color: #fff
        cursor: pointer
        text-align: center
        align-self: stretch
        justify-self: stretch
        @extend %stylesToButton
        .quality-item-title
            font-size: 1.5vw
            font-family: 'Poppins'
            font-weight: 300
    .quality-encuesta
        grid-area: quality-encuesta
        background: linear-gradient(270deg, rgba(26,188,156,1) 0%, rgba(9,64,112,1) 91%)
    .quality-transferir
        flex-direction: row-reverse
        grid-area: quality-transferir
        background: linear-gradient(270deg, rgba(55,167,185,1) 0%, rgba(26,188,156,1) 100%, rgba(55,167,185,1) 094070%)
    .quality-agenda
        grid-area: quality-agenda
        background: linear-gradient(93deg, rgba(26,188,156,1) 10%, rgba(9,64,112,1) 100%)
        // .quality-item-title
        //     font-size: 1.5vw
        // .c-home-c-informacion-title
        //     width: 40%
        //     font-size: 1.5vw
        //     margin-right: 20px
    .quality-rol-guardias
        grid-area: quality-rol-guardias
        background: linear-gradient(270deg, rgba(55,167,185,1) 0%, rgba(26,188,156,1) 100%, rgba(55,167,185,1) 094070%)