.contaniner_trans_not{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    .precontaniner_trans_not{
        display: grid;
        grid-template-rows: 1fr .2fr;
        width: 94%;
        margin-top: 19vh;
        .fisrt_container_transfer{
            display: grid;
            grid-template-columns: .7fr 1fr;
            grid-gap: 5vw;
            .section_transf_left{
                height: 62vh;
            }
            .section_transf_rigth{
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 2vw;
                overflow: auto;
                padding-left: 0.5vw;
            }
        }
        .second_container_transfer{
            display: flex;
            margin-top: 4vw;
            justify-content: end;
                .btn_trans_rechazar{
                    margin-right: 2vw;
                    color: white;
                    background: #E30613;
                    font-family: 'Poppins';
                    /* padding: 0.5vw; */
                    /* border-radius: 12%; */
                    font-size: 14px;
                    font-family: "Poppins";
                    font-weight: 300;
                    text-transform: uppercase;
                    padding: 6px 1rem;
                    border: none;
                    color: #fff;
                    cursor: pointer;
                    width: 10%;
                    border-radius: 20px;
                }
                .btn_trans_aceptar{
                    border: none;
                    color: #fff;
                    cursor: pointer;
                    width: 10%;
                    background: linear-gradient( 
                270deg
                 , #1abc9c 0%, #094070 91%);
                    font-size: 14px;
                    font-family: "Poppins";
                    font-weight: 300;
                    text-transform: uppercase;
                    padding: 6px 1rem;
                    border-radius: 20px;
                }
        }
    }

}