.container-star {
    margin-left: 0.7vw;
    position: relative;
    margin: auto auto;

    .star {
        padding: 7px;
        color: #707070;
        font-size: 25px;
    }

    .fill {
        color: orange;
    }

    .container__star,
    .container__star::before,
    .container__star::after {
        width: 0;
        border-color: transparent transparent #707070 transparent;
        border-width: 0 4rem 2.5rem 4rem;
        border-style: solid;
        position: absolute;
        transform: rotate(180deg);
        margin-top: 2.75rem;
        margin-left: 0.25rem;
    }

    .container__star::before {
        content: "";
        margin-top: -0.15rem;
        margin-left: -4.3rem;
        transform: rotate(70deg);
    }

    .container__star::after {
        content: "";
        margin-top: -0.29rem;
        margin-left: -3.87rem;
        transform: rotate(-70deg);
    }
}