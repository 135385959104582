%flexbox-SystemTab
    display: flex
    justify-content: center
    align-items: center
    
.container-FactoryConfigurationSystemTab
    width: 100%
    display: grid
    grid-template-columns: 1fr 1fr
    grid-template-rows: repeat(2, 20vh)
    grid-gap: 10px
    .FactoryConfigurationSystemTab-container-surgery
        width: 100%
        flex-direction: column
        background: linear-gradient( 145deg, #ffffff, #e6e6e6)
        box-shadow: 0px 0px 2px #666666, 1px -1px 5px #ffffff
        border-radius: 10px
        border: none
        outline: none
        .FactoryConfigurationSystemTab-surgery-container-img
            width: 100%
            @extend %flexbox-SystemTab
            margin-top: 5px
            .FactoryConfigurationSystemTab-surgery-img
                width: 3.5vw
        .FactoryConfigurationSystemTab-surgery-container-title
            .FactoryConfigurationSystemTab-surgery-title
                font-family: 'Poppins'
                font-weight: 400
                font-size: 1vw
