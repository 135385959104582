.doctor-right-bar {
    .doctor-right-bar-buttons-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 1vw;
        margin-right: 3.5vw;
    }

    .institutions-right-bar-buttons-container {
        display: flex;
        justify-content: flex-end;
    }

    .patient-right-bar-buttons-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
    }

    .doctor-right-bar-header {
        align-items: center;
        padding-top: 1vw;
        padding-bottom: 1vw;
        margin-left: 7vw;
        gap: 1.5vw;
        display: grid;
        grid-template-columns: 0.5fr 0.5fr 0.5fr;
        width: 65%;


        .profile-image {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;


            .profile-image-border {
                width: 90%;
                height: 10vw;
                max-width: 130px;
                max-height: 130px;
                border: 3px solid #37A7B9;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;

                img {
                    width: 6vw;
                }
            }
        }


        .profile-data {

            font-family: 'Poppins';

            .container-perfil-user {
                display: flex;
                flex-direction: column;

                .bigBlueText {
                    font-size: 20px;
                    font-weight: 600;
                    color: #00325D;
                    margin-bottom: 4px;

                }

                .subtitleProfile {
                    font-size: 14px;
                    color: #000;
                    font-weight: 500;

                }
            }
        }

        .profile-data-element {
            display: flex;

            .profile-content {
                // width: 30%;
                //padding-left: 2rem;
            }

            .direction_content {
                width: 100%;

                .direccion-p-institutions {
                    font-family: 'Poppins';
                    font-size: .9vw;
                    color: #777777;
                    font-weight: 500;
                }


            }

            .requirements_title {
                font-family: 'Poppins';
                font-size: .9vw;
                color: #303030;
                font-weight: 600;
            }

            .requirements_content {
                width: 65%;
                margin-left: 12.5vw;

                p {
                    font-family: 'Poppins';
                    font-size: 14px;
                    color: #777777;
                    font-weight: 500;
                    margin-bottom: 8px;
                }
            }
        }
    }

    //----medico y atq
    .doctor-right-bar-header-left {
        align-items: center;
        padding-top: 1vw;
        padding-bottom: 1vw;
        gap: 1.5vw;
        display: grid;
        grid-template-columns: 0.5fr 0.5fr 0.5fr;
        width: 65%;


        .profile-image {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;


            .profile-image-border {
                width: 90%;
                height: 10vw;
                max-width: 130px;
                max-height: 130px;
                border: 3px solid #37A7B9;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;

                img {
                    width: 6vw;
                }
            }
        }


        .profile-data {

            font-family: 'Poppins';

            .container-perfil-user {
                display: flex;
                flex-direction: column;

                .bigBlueText {
                    font-size: 20px;
                    font-weight: 600;
                    color: #00325D;
                    margin-bottom: 4px;

                }

                .subtitleProfile {
                    font-size: 14px;
                    color: #000;
                    font-weight: 500;

                }
            }
        }

        .profile-data-element {
            display: flex;

            .profile-content {
                // width: 30%;
                //padding-left: 2rem;
            }

            .direction_content {
                width: 65%;

                .direccion-p-institutions {
                    font-family: 'Poppins';
                    font-size: .9vw;
                    color: #777777;
                    font-weight: 500;
                }


            }

            .requirements_title {
                font-family: 'Poppins';
                font-size: .9vw;
                color: #303030;
                font-weight: 600;
            }

            .requirements_content {
                width: 65%;
                margin-left: 12.5vw;

                p {
                    font-family: 'Poppins';
                    font-size: 14px;
                    color: #777777;
                    font-weight: 500;
                    margin-bottom: 8px;
                }
            }
        }
    }


    .second-part-header {
        padding: 0;
        display: grid;
        grid-template-columns: 0.1fr 1fr;
        width: 41vw;
        margin-left: 22vw;
        gap: 6.7vw;


        .profile-content {

            // width: 30%;
            //padding-left: 2rem;


            .requirements_title {
                font-family: 'Poppins';
                font-size: 1vw;
                color: #303030;
                font-weight: 600;
            }
        }

        .requirements_content {
            width: 100%;
            // background: red;

            .requirements-sesion-institucions {
                font-family: "Poppins";
                font-size: .9vw;
                color: #777777;
                font-weight: 500;
            }
        }
    }




    .text-description-institutions {

        // background: red;
        .text-title-institutions {
            font-size: 1vw;
            font-family: "Poppins";
            font-weight: 450;
            color: #00325D;
            display: flex;
            align-items: center;
        }

    }

    // .info-hospital-institucions{
    //     margin-left: 2vw;
    // }
}


.container-institutions-ubicacion {
    display: flex;
    justify-content: flex-end;
    margin-top: 1vw;
    margin-right: 3.5vw;

    .btn-institution {
        align-items: center;
        border-radius: 20px;
        border: none;
        color: #fff;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        flex-direction: row;
        font-family: "Poppins";
        font-weight: 300;
        text-transform: uppercase;
        padding: 8px 1rem;
        margin-left: 1rem;
        min-width: 150px;
        justify-content: center;
        font-weight: 500;
        height: 30px;
        box-shadow: #707070 3px 3px 6px -3px;
        background: linear-gradient(270deg, #1ABC9C 0%, #31ABB3 91%);
        margin-bottom: 2vw;
        margin-top: 2vw;
    }
}

.line-institutions {
    border-bottom: 1px solid #707070;
    display: flex;
    width: 85%;
    margin-left: 6.5vw;

}


// PATIENT
.separate-line {
    border: 1px solid #CDCCCC;
    margin: 2rem 0;
}

.report-content {
    margin-top: 3rem;
}

.report-tab-elements {
    display: flex;

    .text-element {
        &:last-child {
            margin-right: 0;
        }
    }
}

.patients-right-bar-header {
    padding-top: 60px;
    padding-bottom: 20px !important;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 49.5%;
    margin-left: 6vw;


    .profile-data {
        width: 25%;
        display: flex;
        flex-direction: column;
        font-family: 'Poppins';

        .titleProfile {
            font-size: 1vw;
            color: #2A99AB;
            font-weight: 500;
            text-transform: capitalize;
        }
    }
}

.patien-profile-data {
    display: grid;
    grid-template-columns: 0.2fr .5fr;
    flex-direction: row;
    padding-bottom: 40px;
    gap: 4vw;
    margin-left: 6vw;
    width: 100%;


    .name-patients-rigth {
        font-family: "Poppins";
        color: #00325D;
        font-size: 1.2vw;
        font-weight: 500;
    }

    .profile-data-content {
        width: 100%;
    }

    .profile-data-two-columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        gap: 2vw;

        span {
            width: 100%;
            font-family: 'Poppins';
            font-size: 15px;
            margin-bottom: 8px;

            &:last-child {
                width: 100%;
            }
        }

        .subtitleProfile {
            color: #777777;
            font-weight: 500;
        }

        .subtitleProfileDark {
            color: #303030;
            font-weight: 500;
        }
    }

    .profile-data {

        display: flex;
        flex-direction: column;
        font-family: 'Poppins';

        .bigBlueText {
            font-size: 20px;
            font-weight: 500;
            color: #00325D;
            margin-bottom: 4px;
            margin-left: 2rem;
        }
    }
}

.container-patients-consumo {
    display: flex;
    justify-content: flex-end;
    margin-top: 1vw;
    margin-right: 3.5vw;
    margin-bottom: 1vw;

    .btn-consumo-patients-inactive {
        align-items: center;
        border-radius: 20px;
        border: none;
        color: #303030;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        flex-direction: row;
        font-family: "Poppins";
        font-weight: 300;
        text-transform: uppercase;
        padding: 8px 1rem;
        margin-left: 1rem;
        min-width: 150px;
        justify-content: center;
        font-weight: 500;
        height: 30px;
        border-color: #f2f;
        box-shadow: #707070 3px 3px 6px -3px;
        border: 1px solid #2A99AB;
        background: #FFF;

    }

    .btn-consumo-patients-active {
        align-items: center;
        border-radius: 20px;
        border: none;
        color: #FFF;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        flex-direction: row;
        font-family: "Poppins";
        font-weight: 300;
        text-transform: uppercase;
        padding: 8px 1rem;
        margin-left: 1rem;
        min-width: 150px;
        justify-content: center;
        font-weight: 500;
        height: 30px;
        border-color: #f2f;
        box-shadow: #707070 3px 3px 6px -3px;
        background: #2A99AB;

    }
}

.line-border{
    border-bottom: 2px solid #CDCCCC;
    width: 90%;
    margin-left: 4vw;
}

.container-general-patient{
    height: 39vh;
    overflow: scroll;

    .container-report-patient{
        margin-left: 5vw;
        margin-right: 2vw;
        margin-top: 3vw;   margin-top: 3vw;
        display: flex;
    }
    
    .container-report-patient-diferent{
        display: flex;
        margin-left: 5vw;
        margin-top: 3vw;
        margin-right: 2vw;
        width: 85%;
    }
}
