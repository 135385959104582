.c-StoreHouseStockModalForm{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
    .c-StoreHouseStockModalForm-c-list-items{
        width: 100%;
        border-collapse: collapse;
        .c-StoreHouseStockModalForm-c-list-items-table-container{
            position: relative;
            width: 100%;
            .c-StoreHouseStockModalForm-c-list-items-table-precontainer{
                width: 100%;
                
                .c-StoreHouseStockModalForm-c-list-items-table{
                    width: 100%;
                    border-collapse: collapse;
                    display: inline-block;
                    box-sizing: border-box;
                    .c-StoreHouseStockModalForm-item-container-titles{
                        margin: 1vw 0;
                        width: 100%;
                        display: flex;
                        .c-StoreHouseStockModalForm-item-container-titles-table-head-row{
                            width: 100%;
                            display: flex;
                            .c-StoreHouseStockModalForm-item-title:nth-child(1){
                                width: 10%;
                                
                            }
                            .c-StoreHouseStockModalForm-item-title:nth-child(2){
                                text-align: center;
                                width: 35%;
                                padding-left: 1vw;
                            }
                            .c-StoreHouseStockModalForm-item-title:nth-child(3){
                                width: 25%;
                                text-align: center;
                            }
                            .c-StoreHouseStockModalForm-item-title:nth-child(4){
                                width: 20%;
                            }
                            .c-StoreHouseStockModalForm-item-title:nth-child(5){
                                width: 10%;
                                
                            }
                            .c-StoreHouseStockModalForm-item-title{
                                border-bottom: 1px solid #777777;
                                padding-bottom: .5vw;
                                color: #303030;
                                font-weight: 400;
                                display: inline-block;
                            }
                        }
                    }
                    .c-StoreHouseStockModalForm-item-value-container{
                        height: 25vh;
                        width: 100%;
                        overflow: auto;
                        display:block;
                        margin-bottom: 2vw;
                        .c-StoreHouseStockModalForm-item-container-titles-table-body-row{
                            display: flex;
                            width: 100%;
                        }
                        .c-StoreHouseStockModalForm-item-value:nth-child(1){
                            width: 10%;
                        }
                        
                        .c-StoreHouseStockModalForm-item-value:nth-child(2){
                            text-align: center;
                            width: 35%;
                            padding-left: 1vw;
                        }
                        .c-StoreHouseStockModalForm-item-value:nth-child(3){
                            width: 25%;
                            text-align: center;
                            padding-left: 1vw;
                        }
                        .c-StoreHouseStockModalForm-item-value:nth-child(4){
                            width: 20%;
                            
                        }
                        .c-StoreHouseStockModalForm-item-value:nth-child(5){
                            width: 10%;
                            .c-StoreHouseStockModalForm-item-check{
                                width: 1.3vw;
                                height: 1.3vw;
                            }
                        }
                        .c-StoreHouseStockModalForm-item-value-green{ 
                            color: #1ABC9C !important;
                        }
                        .c-StoreHouseStockModalForm-item-value-grey{ 
                            color: #777777 !important;
                        }
                        .c-StoreHouseStockModalForm-item-value-yellow{ 
                            color: #F1D62D !important;
                        }
                        .c-StoreHouseStockModalForm-item-value-red{ 
                            color: #E30613 !important;
                        }
                        .c-StoreHouseStockModalForm-item-value-black{ 
                            color: #303030 !important;
                        }
                        .c-StoreHouseStockModalForm-item-value{
                            margin-top: 1vw;
                            color: #777777;
                            font-weight: 300;
                            display: inline-block;
                            box-sizing: border-box;
                            width: 33%;
                            
                        }
                    }
                }
            }
        }
        }
    .c-StoreHouseStockModalForm-title-notItem{
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 1.2vw;
        color: #777777;
    }
    .c-StoreHouseStockModalForm-item-note{
        width: 100%;
        margin: 3vw 0 0 0;
        outline: none;
        color: #777777;
    }
    .c-StoreHouseStockModalForm-button{
        width: 60%;
        padding: 10px;
        border-radius: 20px;
        border: none;
        margin-top: 1vw;
        background: linear-gradient(275deg, rgba(55,167,185,1) 0%, rgba(9,64,112,1) 100%);
        color: #fff;
        outline: none;
        cursor: pointer;
        font-size: 16px;
    }
}