%ButtonToAddOrDelete{
    position: absolute;
    border-radius: 50%;
    width: 2.5vw;
    height: 2.5vw;
    border: none;
    box-shadow: 0px 1px 6px -1px rgb(0 0 0 / 35%);
    background: #FFFFFF;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
}

%ButtonsStoreHouse{
    height: 2vw;
    margin-top: 10px;
    border-radius: 30px;
    width: 16vw;
    background: rgb(9,64,112);
    background: linear-gradient(90deg, rgba(9,64,112,1) 0%, rgba(55,167,185,1) 100%);
    border: none;
    font-size: 1.3vw;
    font-family: 'Poppins';
    color: #FFFFFF;
    cursor: pointer;
    outline: none;
}

.storeHouseApproveOrderScreen-container {
    padding-top: 20vh;
    height: 80vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    .storeHouseApproveOrderScreen-section-container{
        width: 95%;
        display: grid;
        grid-template-areas: "storeHouseApproveOrderScreen-section-left storeHouseApproveOrderScreen-section-rigth"  "storeHouseApproveOrderScreen-section-bottom-button-container storeHouseApproveOrderScreen-section-bottom-button-container";
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: 1fr .2fr;


        .storeHouseApproveOrderScreen-section-left{
            grid-area: storeHouseApproveOrderScreen-section-left ;
            width: 80%;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            padding-bottom: 10vh;
            height: 55vh;
            .storeHouseApproveOrderScreen-left-item{
                margin-top: 5vh;
            }
        }

        .storeHouseApproveOrderScreen-section-rigth{
            grid-area: storeHouseApproveOrderScreen-section-rigth;
            width: 90%;
            justify-self: center;

            .storeHouseStockRigthTableItems-section-rigth-card-container{
                position: relative;
                width: 95%;
                padding: 1.8vw 0;
                .storeHouseStockRigthTableItems-section-rigth-card-precontainer{
                    width: 100%;
                    .storeHouseStockRigthTableItems-section-rigth-card-container-delete-container{
                        left: -1vw;
                        top: .5vw;
                        @extend %ButtonToAddOrDelete;
                        .storeHouseStockRigthTableItems-section-rigth-card-container-delete{
                            font-size: 1.7vw;
                            color: rgb(199, 0, 0);
                        }
                    }
                    .storeHouseStockRigthTableItems-section-rigth-card-container-add-container{
                        right: -1vw;
                        bottom: .5vw;
                        @extend %ButtonToAddOrDelete;
                        .storeHouseStockRigthTableItems-section-rigth-card-container-add{
                            font-size: 1.7vw;
                            color: rgb(199, 0, 0);
                            width: 1.7vw;
                        }
                    }
                    .storeHouseStockRigthTableItems-section-rigth-table-container{
                        width: 100%;
                        background: #FFFFFF;
                        box-shadow: 0px 1px 6px -1px rgb(0 0 0 / 35%);
                        border-collapse: collapse;
                        display: inline-block;
                        box-sizing: border-box;
        
                        .storeHouseStockRigthTableItems-section-rigth-table-head{
                            width: 100%;
                            display: flex;
                            .storeHouseStockRigthTableItems-section-rigth-table-head-row{
                                width: 100%;
                                display: flex;
    
                                .storeHouseStockRigthTableItems-section-rigth-table-title:nth-child(1){
                                    text-align: start;
                                    width: 35%;
                                    padding-left: 1vw;
                                }
                                .storeHouseStockRigthTableItems-section-rigth-table-title:nth-child(2){
                                    width: 15%;
                                }
                                .storeHouseStockRigthTableItems-section-rigth-table-title:nth-child(3){
                                    width: 15%;
                                }
                                .storeHouseStockRigthTableItems-section-rigth-table-title:nth-child(4){
                                    width: 35%;
                                }
                                .storeHouseStockRigthTableItems-section-rigth-table-title{
                                    border-bottom: 1px solid #c2c2c2;
                                    font-weight: 300;
                                    font-size: 1vw;
                                    color: #5c5c5c;
                                    font-family: 'Poppins';
                                    font-weight: 300;
                                    padding: 10px 0px;
                                    border-spacing: 0;
                                    text-align: center;
                                }
                            }
                        }
                        .storeHouseStockRigthTableItems-section-rigth-table-body{
                            max-height: 30vh;
                            width: 100%;
                            overflow: auto;
                            display:block;
                            .storeHouseStockRigthTableItems-section-rigth-table-body-row{
                                display: flex;
                                width: 100%;
                                .storeHouseStockRigthTableItems-section-rigth-table-data:nth-child(1){
                                    text-align: start;
                                    width: 35%;
                                    padding-left: 1vw;
                                }
                                .storeHouseStockRigthTableItems-section-rigth-table-data:nth-child(2){
                                    width: 15%;
                                }
                                .storeHouseStockRigthTableItems-section-rigth-table-data:nth-child(3){
                                    width: 15%;
                                }
                                .storeHouseStockRigthTableItems-section-rigth-table-data:nth-child(4){
                                    width: 35%;
                                    .storeHouseStockRigthTableItems-section-rigth-table-data-input{
                                        width: 80%;
                                        border: 1px solid #cfcece;
                                        border-radius: .5vw;
                                    }
                                }
                
                                .storeHouseStockRigthTableItems-section-rigth-table-data{
                                    text-align: center;
                                    padding: 10px 0 4vh 0;
                                    color:rgb(156, 156, 156);
                                    font-family: 'Poppins';
                                    font-size: .9vw;
                                    font-weight: 300;
                                    
                                    .storeHouseStockRigthTableItems-section-rigth-table-data-equipo-img{
                                        outline: none;
                                        cursor: pointer;
                                    }
                                    .storeHouseStockRigthTableItems-section-rigth-table-data-img-check{
                                        width: 1.3vw;
                                    }
                                    .storeHouseStockRigthTableItems-section-rigth-table-data-delete-container{
                                        width: 2.3vw;
                                        height: 2.3vw;
                                        border-radius: 50%;
                                        border: none;
                                        box-shadow: 0px 1px 6px -1px rgb(0 0 0 / 35%);
                                        background: #FFFFFF;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        cursor: pointer;
                                        outline: none;
                                        .storeHouseStockRigthTableItems-section-rigth-table-data-delete{
                                            color: rgb(199, 0, 0);
                                            font-size: 1.4vw;
                                        }
                                    }
                                }
                            }
                        }
                    }   
                }
            }
        }

        .storeHouseApproveOrderScreen-section-bottom-button-container{
            grid-area: storeHouseApproveOrderScreen-section-bottom-button-container;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            justify-self: end;
            .storeHouseApproveOrderScreen-section-bottom-button-container-position{
                display: grid;
                width: 40%;
                grid-template-columns: 1fr 1fr;
                .storeHouseApproveOrderScreen-section-bottom-button-quote{
                    @extend %ButtonsStoreHouse;
                }
        
                .storeHouseApproveOrderScreen-section-bottom-button-continue{
                    @extend %ButtonsStoreHouse;
                    grid-column-start: 2;
                }
            }
    
        }

    }

}