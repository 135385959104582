.DoctorsHome-principal-container{
    width: 100%;
    height: 75vh;
    padding-top: 9vw;
    display: flex;
    justify-content: center;


    .DoctorsHome-main-page-container {
        display: flex;
        width: 95%;
        flex-direction: row;
        margin-bottom: 0!important;
        margin-right: 0!important;
        margin-top: 1vw;
 

        .DoctorsHome-left-bar {
            width: 25%;
            border-right: 2px solid rgba(119,119,119,0.7);
       
        }

        .DoctorsHome-right-bar {
            width: 70%;
            padding-left: 40px;
            height: 73vh;
            padding-right: 40px;
      
        }
    }
    
        .DoctorsHome-left-bar::-webkit-scrollbar, .right-bar::-webkit-scrollbar {
            -webkit-appearance: none;
        }
        .DoctorsHome-left-bar::-webkit-scrollbar:vertical, .right-bar::-webkit-scrollbar:vertical {
            width:10px;
        }
        .DoctorsHome-left-bar::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button, .right-bar::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
            display: none;
        } 
        .left-bar::-webkit-scrollbar:horizontal, .right-bar::-webkit-scrollbar:horizontal {
            height: 10px;
        }
        .DoctorsHome-left-bar::-webkit-scrollbar-thumb, .right-bar::-webkit-scrollbar-thumb {
            background-color: #777777;
            border-radius: 20px;
            border: 2px solid #f1f2f3;
        }
        .DoctorsHome-left-bar::-webkit-scrollbar-track, .right-bar::-webkit-scrollbar-track {
            border-radius: 10px;  
        }
    
}
