.container-component-comments-SeguimientoQuality {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 7px;
    -webkit-box-shadow: -1px 5px 19px 0px #efecef;
    -moz-box-shadow: -1px 5px 19px 0px #efecef;
    box-shadow: -1px 5px 19px 0px #efecef;
    margin-bottom: 3vh;

    .header-commentsSeguimientoQuality {
        width: 100%;

        .header-titleSeguimientoQuality {
            border-bottom: 1px solid #c2c2c2;
            padding-bottom: 0.5vh;
            margin-bottom: 2vh;

            .title-componentSeguimientoQuality {
                color: #37a7b9;
                font-family: "Poppins";
                font-size: 1.2vw;
                font-weight: 500;
                display: flex;
                justify-content: center;
                padding-top: 1vh;
            }
        }
        .header-subtitle-SeguimientoQualityo {
            padding-bottom: 0.5vh;
            .subtitle-componen-SeguimientoQualityo {
                color: #000;
                font-family: "Poppins";
                font-size: 1vw;
                font-weight: 500;
                display: flex;
                justify-content: center;
                padding: 3vh 0 2vh;
            }
        }
    }

    .body-SeguimientoQualityo {
        width: auto;
        display: flex;
        flex-direction: column;
        margin: 0px 10px 15px 10px;

        .container-SeguimientoQualityo {
            width: 100%;
            display: flex;
            justify-content: center;

            .textArea-SeguimientoQualityo{
                width: 100%;
                color: #303030;
                font-family: "Poppins";
                font-size: 1vw;
                font-weight: 200;
                padding: 10px 0 0 10px;
                border-color: #c2c2c2;
                
            }
        }
    }
}
