.c-scheduleSurgeryModal
    width: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    .c-scheduleSurgeryModal-title
        font-family: 'Poppins'
        font-weight: 300
        color: #094070
        font-size: 1.5vw
        text-align: center
        margin-top: 0
    .c-scheduleSurgeryModal-c-list-items
        width: 90%
        margin: 1vw 0
        .c-scheduleSurgeryModal-c-list-items-scroll
            min-height: 5vw
            max-height: 15vw
            overflow-y: scroll
            .scheduleSurgeryModal-title-list
                width: 100%
                text-align: start
                color: #6d6d6d
                font-weight: 500
                font-family: 'Poppins'
            .c-scheduleSurgeryModal-item-title-errror
                font-family: 'Poppins'
                font-weight: 300
                font-size: 1.3vw
                color: #696969
            .c-scheduleSurgeryModal-c-item
                width: 100%
                display: flex
                flex-direction: row
                text-align: start
                justify-content: space-between
                align-items: center
                padding-bottom: 5px
                border-bottom: 1px solid #a8a8a8
                .c-scheduleSurgeryModal-item-name
                    font-family: 'Poppins'
                    font-weight: 300
                    font-size: 18px
                    color: #696969
                .c-scheduleSurgeryModal-item-check
                    display: inline-block
                    width: 18px
                    height: 18px
                    margin-right: .5rem
                    background-size: cover
        .c-scheduleSurgeryModal-button
            width: 60%
            padding: 10px
            margin-top: 30px
            border-radius: 20px
            border: none
            color: #fff
            outline: none
            cursor: pointer
            font-size: 16px
        .c-scheduleSurgeryModal-button-submit
            width: 67%
            font-family: 'Poppins'
            background: linear-gradient(90deg, rgba(55,167,185,1) 0%, rgba(9,64,112,1) 100%)
    .c-scheduleSurgeryModal-button-reject
        width: 60%
        padding: 10px
        border-radius: 20px
        border: none
        color: #fff
        outline: none
        cursor: pointer
        font-size: 16px
        font-family: 'Poppins'
        background: linear-gradient(270deg, rgba(55,167,185,1) 0%, rgba(9,64,112,1) 100%)