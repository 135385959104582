.ATQAdministrator_Notificacion_ATQ_TableUbication-precontainer_table {
    position: relative;
    width: 100%;
    padding: 1.8vw 0;

    .ATQAdministrator_Notificacion_ATQ_TableUbication-container_table {
        width: 100%;

        .ATQAdministrator_Notificacion_ATQ_TableUbication-table-container_table {
            width: 97%;
            background: #FFFFFF;
            box-shadow: 0px 1px 6px -1px rgb(0 0 0 / 35%);
            border-collapse: collapse;
            display: inline-block;
            box-sizing: border-box;

            .ATQAdministrator_Notificacion_ATQ_TableUbication-table-thead_table {
                width: 100%;
                //display: flex;

                .ATQAdministrator_Notificacion_ATQ_TableUbication-table-row_table {
                    width: 100%;
                    //display: flex;



                    .ATQAdministrator_Notificacion_ATQ_TableUbication-table-title_table-1 {
                        text-align: start;
                        width: 23%;
                        border-bottom: 1px solid #c2c2c2;
                        font-weight: 300;
                        font-size: 1.1vw;
                        color: #094070;
                        font-family: "Poppins";
                        font-weight: 300;
                        padding: 10px 0 10px 1vw;
                        border-spacing: 0;

                    }

                    .ATQAdministrator_Notificacion_ATQ_TableUbication-table-title_table-2 {
                        text-align: start;
                        width: 2%;
                        border-bottom: 1px solid #c2c2c2;
                        font-weight: 300;
                        font-size: 1.1vw;
                        text-align: right;
                        color: #094070;
                        font-family: "Poppins";
                        font-weight: 300;
                        padding: 10px 1vw 10px 1vw;
                        border-spacing: 0;

                    }


                    .ATQAdministrator_Notificacion_ATQ_TableUbication-table-title_table-0 {
                        width: 100%;
                       // border-bottom: 1px solid #c2c2c2;
                        font-weight: 300;
                        font-size: 1.1vw;
                        text-align: start;
                        color: #094070;
                        font-family: "Poppins";
                        font-weight: 300;
                        padding: 10px 1vw 10px 1vw;
                        border-spacing: 0;

                    }

                }
            }

            .ATQAdministrator_notificacion_ATQ_TableUbication-table-body_table {
                max-height: 30vh;
                width: 100%;
                overflow: auto;
                display: block;

                .ATQAdministrator_notificacion_ATQ_TableUbication-table-body-row_table {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;

                    .ATQAdministrator_notificacion_ATQ_TableUbication-table-data_table_1 {
                        text-align: start;
                        width: 40%;
                        padding: 10px 0 4vh 1vw;
                        color: #9c9c9c;
                        font-family: "Poppins";
                        font-size: 0.9vw;
                        font-weight: 300;
                        display: inline-block;

                    }

                    .ATQAdministrator_notificacion_ATQ_TableUbication-table-data_table_2 {
                        text-align: start;
                        width: 20%;
                        padding: 10px 0 4vh 1vw;
                        color: #9c9c9c;
                        font-family: "Poppins";
                        font-size: 0.9vw;
                        font-weight: 300;
                        display: flex;
                        justify-content: flex-end;
                        margin-right: 3vw;

                    }


                }

            }
        }

    }
}