.notificaciones_materialCompleto_container{
    padding-top: 20vh;
    height: 80vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .notificaciones_materialCompleto_subcontainer{
        width: 95%;
        display: grid;
        grid-template-areas: "notificaciones_materialCompleto_container_left notificaciones_materialCompleto_container_rigth" "atqAdministrator_Assign_ATQ-section-bottom-button-container atqAdministrator_Assign_ATQ-section-bottom-button-container";
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: .8fr 0.2fr;

        .notificaciones_materialCompleto_container_left{
            grid-area:notificaciones_materialCompleto_container_left;
            width: 90%;
            display: flex;
            flex-direction: column;
            //overflow-y: scroll;
            padding-bottom: 10vh;
            height: 55vh;
        }

        .notificaciones_materialCompleto_container_rigth{
            grid-area: notificaciones_materialCompleto_container_rigth;
            width: 90%;
            justify-self: center;
         

            .notificaciones_button_materialCompleto_continuar_ATQ{
                 

                .notificacion_btn_material{
                    width: 30%;
                    margin-left: 25vw;
                    padding: 0.5vw;
                    font-size: 1vw;
                    color: #ffffff;
                    font-family: "Poppins";
                    background: linear-gradient(90deg, #094070 0%, #1abc9c 100%);
                    border: none;
                    border-radius: 20px;
                    cursor: pointer;
                    outline: none;
                    box-shadow: 4px 3.9px 3px #c4c1c1;

                }
            }

        }
    }

}