.c-patient
    width: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    .c-patient-c-list-items
        width: 90%
        margin-bottom: 20px
        .c-patient-c-item
            width: 90%
            display: flex
            flex-direction: row
            justify-content: space-between
            align-items: center
            margin-bottom: 10px
            padding-bottom: 10px
            border-bottom: 1px solid #a8a8a8
            .c-patient-item-name
                font-family: 'Poppins'
                font-weight: 300
                font-size: 18px
                color: #696969
                margin-left: 20px
                border: none
                width: 90%
                outline: none
    .c-patient-button
        width: 60%
        margin-top: 20px
        padding: 10px
        border-radius: 20px
        border: none
        font-family: 'Poppins'
        background: linear-gradient(270deg, rgba(26,188,156,1) 0%, rgba(55,167,185,1) 91%)
        color: #fff
        outline: none
        cursor: pointer
        font-size: 16px