.padding-left-sb {
    padding-left: 7vw;
    margin-top: 2vw;
    padding-right: 13vw;
    height: 41vh;
    overflow: scroll;
  
}
.doctor-tab-patient-container {
    display: flex;
    border-bottom: 2px solid rgba(143, 237, 252, 0.6);
    padding: 1rem 0;
    align-items: center;
    .doctor-tab-patient-bloque-1 {
        width: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        img {
            width: 100px;
        }
    }
    .doctor-tab-patient-bloque-2 {
        width: 30%;
        display: flex;
        flex-direction: column;
        .pdfIcon {
            width: 15px;
            cursor: pointer;
        }
    }
    .doctor-tab-patient-bloque-3 {
        width: 30%;
    }
    .doctor-tab-patient-bloque-4 {
        width: 20%;
        button {
            min-width: 120px;
        }
    }
}

// TEXT
.blue_dark_text {
    font-family: 'Poppins';
    font-size: 18px;
    color: #00325D;
    font-weight: 500;
    margin-bottom: 4px;
}
.blue_light_text {
    font-family: 'Poppins';
    font-size: 13px;
    color: #2A99AB;
    margin-bottom: 6px;
    font-weight: 500;
}
.gray_dark_text {
    font-family: 'Poppins';
    font-size: 15px;
    color: #303030;
    margin-bottom: 12px;
    font-weight: 600;
}
.gray_light_text {
    font-family: 'Poppins';
    font-size: 15px;
    color: #777777;
    margin-bottom: 12px;
    font-weight: 500;
}
