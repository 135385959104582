//estilos Condicionados del chat ocultar

.container-modal-chat-messages-condition {
    position: fixed;
    bottom: 0;
    right: 5vw;
    background: #FFF;
    height: 7.5vh;
    border-radius: 10px;
    width: 12vw;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.1fr .7fr 0.1fr;
    -webkit-box-shadow: 1px 0px 11px 0px rgba(112,112,112,1);
-moz-box-shadow: 1px 0px 11px 0px rgba(112,112,112,1);
box-shadow: 1px 0px 11px 0px rgba(112,112,112,1);

    .header-modal-chat-msg-condition {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom: solid .5px #707070;
        cursor: pointer;


        .header-modal-chat-left-condition {
            width: 100%;
            display: flex;
            align-items: center;
            margin-left: 0.2vw;
            margin-top: 0.2vw;
            justify-content: space-around;

            .img-modal-chat-header-user-condition {
                width: 1.5vw;

            }

            .title-modal-chat-header-condition {
                font-size: .9vw;
                font-family: "Poppins";
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 7vw;



            }

            .img-modal-chat-header-status-condition {
                width: .5vw;


            }

        }
    }



}

//Estilos normales

.container-modal-chat-messages {
    position: fixed;
    bottom: 0;
    right: 5vw;
    background: #FFF;
    height: 47vh;
    border-radius: 10px;
    width: 16vw;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.1fr .7fr 0.1fr;
    -webkit-box-shadow: 1px 0px 11px 0px rgba(112,112,112,1);
    -moz-box-shadow: 1px 0px 11px 0px rgba(112,112,112,1);
    box-shadow: 1px 0px 11px 0px rgba(112,112,112,1);
    ;




    .header-modal-chat-msg {
        display: flex;
        justify-content: space-between;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom: solid .5px #707070;


        .header-modal-chat-left {
            width: 63%;
            display: flex;
            align-items: center;
            margin-left: 0.2vw;
            margin-top: 0.2vw;
            justify-content: space-around;

            .img-modal-chat-header-user {
                width: 1.5vw;

            }

            .title-modal-chat-header {
                font-size: .9vw;
                font-family: "Poppins";
                margin-left: .5vw;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 7vw;



            }

            .img-modal-chat-header-status {
                width: .5vw;

            }

        }


        .header-modal-chat-rigth {
            width: 35%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            .icon-modal-user {
                width: 1.4vw;
                height: 1.4vw;
                cursor: pointer;

            }


            .icon-modal-minimeze {
                width: 1vw;
                height: 1vw;
                cursor: pointer;

            }

            .icon-modal-close {
                width: 1vw;
                height: 1vw;
                cursor: pointer;

            }

        }






    }

    .body-mensajes-chat-modal {
        background: #FFF;
        border-bottom: solid 1px #707070;
        overflow: scroll;
        margin-top: .5vw;
        height: 31.2vh;
    }


    .footer-send-chat-modal {
        // background: blueviolet;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding-top: .5vw;

        .send-message-chat-modal {
            width: 70%;
            border-radius: 10px;
            background: #70707070;
            border: none;
            padding: .3vw;
            font-size: .7vw;
            font-family: "Poppins";

        }

        .send-message-chat-modal::-webkit-input-placeholder {
            color: #FFF;
        }

        .container_input_send_image {
            width: 1.3vw;
            position: relative;
            cursor: pointer;
            display: flex;
            align-items: center;
            
            .input_file_chat {
                position: absolute;
                top: 0;
                width: 100%;
                opacity: 0;
                cursor: pointer;
                

            }

            .add-image-chat-modal {
                width: 1.2vw;
                cursor: pointer;

            }
        }

    }
}