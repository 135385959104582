.schedule_content {
    width: calc( 100% - 80px);
    display: flex;
    margin: 40px auto;
    padding: 18vh 0;
    max-width: 1500px;
    .schedule_calendar_content {
        width: 55%;
    }
    .schedule_list_content {
        width: 45%;
    }
}