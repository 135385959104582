.container-scheduleSurgery
    margin: 0 auto
    padding-top: 20vh
    padding-bottom: 10vh
    display: flex
    flex-direction: column
    width: 50%
    .container-scheduleSurgery-button
        margin-bottom: 1.5vw
        font-size: 1.3vw
        padding: 8px 40px
        text-align: left
        border-radius: 8px
        border: 1px
        justify-content: space-between
        display: flex
        outline: none
        color: #fff
        font-family: 'Poppins'
        cursor: pointer
        align-items: center
        .container-scheduleSurgery-button-container
            display: flex
            width: 50%
            flex-direction: row
            align-items: center
            .container-scheduleSurgery-button-img
                width: 1.5vw
                margin-right: 20px
        .extra-button-icons
            width: 2.4vw
        .extra-button-information
            font-size: 1.3vw
            font-family: 'Poppins'
            text-align: end
            width: 70%
            overflow: hidden
            text-overflow: ellipsis
            white-space: nowrap

        .extra-button-information-span
            font-size: .9vw
            font-family: 'Poppins'
            text-align: end
            width: 70%
            overflow: hidden
            text-overflow: ellipsis
            white-space: nowrap

    .detalleproducto-btn-c-scheduleSurgery
        font-size: 1.3vw
        font-family: 'Poppins'
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        width: 100%
        .detalleproducto-btn-scheduleSurgery
            background: linear-gradient(90deg, rgba(15,65,111,1) 0%, rgba(56,168,185,1) 100%)
            color: #fff
            width: 30%
            padding: .5vw
            cursor: pointer
            border: none
            border-radius: 2vw
    .error
        background: #e60000 !important
    .container-scheduleSurgery-button-DateModal
        background: linear-gradient(90deg, rgba(54,179,152,1) 0%, rgba(56,168,185,1) 100%) 
    .container-scheduleSurgery-button-AtqModal
        background: linear-gradient(270deg, rgba(54,179,152,1) 0%, rgba(56,168,185,1) 100%) 
    .container-scheduleSurgery-button-PatientModal
        background: linear-gradient(90deg, rgba(15,65,111,1) 0%, rgba(56,168,185,1) 100%) 
    .container-scheduleSurgery-button-InstitutionModal
        background: linear-gradient(270deg, rgba(15,65,111,1) 0%, rgba(56,168,185,1) 100%) 
    .container-scheduleSurgery-button-CityModal
        background: linear-gradient(90deg, rgba(54,179,152,1) 0%, rgba(56,168,185,1) 100%) 
    .container-scheduleSurgery-button-WayToPayModal
        background: linear-gradient(270deg, rgba(54,179,152,1) 0%, rgba(56,168,185,1) 100%) 
    .container-scheduleSurgery-button-PaymentConditionsModal
        background: linear-gradient(90deg, rgba(15,65,111,1) 0%, rgba(56,168,185,1) 100%) 
    .container-scheduleSurgery-button-DeliveryTimeModal
        background: linear-gradient(270deg, rgba(15,65,111,1) 0%, rgba(56,168,185,1) 100%) 
    .container-scheduleSurgery-button-OfferValidityModal
        background: linear-gradient(90deg, rgba(54,179,152,1) 0%, rgba(56,168,185,1) 100%) 
    .container-scheduleSurgery-button-EmailModal
        background: linear-gradient(270deg, rgba(54,179,152,1) 0%, rgba(56,168,185,1) 100%) 
    .container-scheduleSurgery-button-PhoneNumberModal
        background: linear-gradient(90deg, rgba(15,65,111,1) 0%, rgba(56,168,185,1) 100%) 
    .btn-eliminar
        width: 10vw
        padding: 0.3vw
        border-radius: 1vw
        margin-left: 1vw
        font-size: 0.8vw
        background: red
        color: #fff
        border: none
        cursor: pointer