.c-container-modal{
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .c-container-img{
        margin-bottom: 1.5vh;
        .imgIcon{

        }
    }
    .txt1{
        font-family: "Poppins";
        font-weight: 400;
        font-size: 20px;
        text-align: center;
        color: #696969;
        width: 24vw;

    }
    .txt2{
        font-family: "Poppins";
        font-weight: 450;
        font-size: 20px;
        text-align: center;
        width: 24vw;
        color: #303030;
    }
    .c-button{
        width: 60%;
        padding: 10px;
        margin-top: 30px;
        border-radius: 20px;
        border: none;
        background: linear-gradient(270deg, rgba(26,188,156,1) 0%, rgba(55,167,185,1) 91%);
        color: #fff;
        outline: none;
        cursor: pointer;
        font-size: 16px;
    }
}