.precontainer-list{
    width: 100%;
    display: flex;
    flex-direction: column;

    .container-ul{
        text-decoration: none;
        list-style-type: none;
        width: 100%;
        justify-content: space-around;

        p{
            margin-bottom: 2vw;
            font-family: "Poppins";
            font-size: 1rem;
            color: #707070;
        }

        .container-ol{
            text-decoration: none;
            list-style-type: none;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            margin-right: 4vw;

            .active-li{
                    color: #1ABC9C !important;
            }

            .active-li::after{
                background: #1ABC9C !important;
            }
            .container-li{
                font-family: "Poppins";
                font-size: 1.2vw;
                font-weight: 500;
                color: #707070;
                justify-content: space-between;
                display: flex;
                padding: 10px 0;
                position: relative;
                align-items: center;

                }
                .container-last{
                    font-family: "Poppins";
                    font-size: 1.2vw;
                    font-weight: 500;
                    color: #707070;
                    justify-content: space-between;
                    display: flex;
                    padding: 11px 0;
                    position: relative;
                    align-items: center;
                        
                    }
                .container-li:after{
                    content: "";
                    position: absolute;
                    background: #707070;
                    display: block;
                    width: 0.2vw;
                    height: 4vh;
                    top: 4vh;
                    left: 0.8vw;
                    z-index: 0;
                   
                 }
            

                .process{
                    width: 1.5vw;
                    height: 1.5vw;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: .8rem;
                    border-radius: 50%;
                    /* margin-right: 1rem; */
                }

                .active-div{
                    border: 2px solid #1ABC9C !important;
                    background-color: #1ABC9C !important;
                }
                .container-div{
                    border: 2px solid #707070;
                    color: #ffffff;
                    font-weight: 400;
                    z-index: 0;
                    background-color: #707070;

                    span{
                        // width: 17px;
                        // height: 17px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                    }
                }
            
        }
    }
}
