.c-deleteProductModal
    width: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    .deleteProductModal-c-img
        width: 100%
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        .deleteProductModal-img
            width: 150px
    .deleteProductModal-c-data
        width: 80%
        text-align: start
        font-family: 'Poppins'
        border-bottom: 1px solid #a3a3a3
        .deleteProductModal-title
            font-size: 16px
            margin-bottom: 0px
            padding-left: 10px
            font-weight: 400
        .deleteProductModal-info
            font-size: 1.2vw
            color: #5f5f5f
            font-weight: 300
            padding-left: 10px
            padding-bottom: 10px
    .deleteProductModal-c-buttons
        width: 100%
        display: flex
        flex-direction: row
        justify-content: space-around
        align-items: center
        margin-top: 30px
        .deleteProductModal-button
            color: #fff
            width: 33%
            font-weight: 300
            border: none
            border-radius: 30px
            box-shadow: #bdbdbd 4px 4px 3px 0px
            font-size: 16px
            padding: 5px
            outline: none
            cursor: pointer
        .deleteProductModal-button-del
            background: #E30613
        .deleteProductModal-button-continuar
            background: linear-gradient(90deg, rgba(55,167,185,1) 0%, rgba(43,175,173,1) 64%, rgba(26,188,156,1) 100%)