
   


.star-rating{
    margin-left: 1vw;
    button {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;

        @media (max-width: 480px){
          .star{
          font-size: 30px;
          }
        }
        @media (min-width: 1024px){
          .star{
            font-size: 25px;
          }
        }
        @media (min-width: 480px) and (max-width: 1023px){
          .star{
            font-size: 25px;
          }
        }
      }
      .on {
        color: orange;
       // background: none;
      }
      .off {
        color: #707070;
        //background: none;
      }
      

}