.precontainer_page {
    height: 80vh;
    padding: 28vh 0 0 0;
    font-family: "Poppins";
    display: flex;
    align-items: center;
    flex-direction: column;

    .container_page {
        width: 70%;

        .container_buttons {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 0.5fr 0.8fr;
            justify-content: flex-end;
            align-items: center;
            flex-direction: row;

            .calendar_atq {
                width: 15vw;
                height: 3vh;
                margin-top: 2px;
                justify-self: end;
                cursor: pointer;
                border-radius: 4px;
                font-family: "Poppins";
            }

            .container-time {
                display: flex;
                width: 100%;
                height: 100%;
                flex-direction: row;
                align-items: center;

                .time_atq {
                    width: 8vw;
                    margin-left: 0.7vw;
                    height: 3vh;
                    cursor: pointer;
                    border-radius: 4px;
                    font-family: "Poppins";
                }
                .img_search {
                    width: 2.3vw;
                    cursor: pointer;
                    margin-left: 1vw;
                }
            }

            .toogle_container_buttons {
                width: 100%;
                display: grid;
                grid-column-start: 3;
                grid-template-columns: 1fr 1fr;
    
                .normal_button-atq {
                    padding: 0.5vw;
                    border: none;
                    background: #707070;
                    font-family: "Poppins";
                    color: #ffff;
                    cursor: pointer;
                    outline: none;
                }
                .btn_left-atq {
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    font-family: "Poppins";
                }

                .btn_active-atq {
                    background: #37a7b9;
                    background: linear-gradient(270deg, #1abc9c 0%, #094070 91%);
                }
                .btn_rigth-atq {
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    
                }
                
            }
        }

        .section_list {
            display: grid;
            grid-template-columns: 1fr;
            height: 75vh;
            margin-top: 1vw;
        }
    }
}
