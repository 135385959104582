.ATQ_GuardRole_CreateTeam_View-items-menu-container {
    width: 90%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 0.6fr 0.1fr;
    justify-content: center;
    grid-gap: 2vw;

    .ATQ_GuardRole_ViewTeam {
        width: 50%;
        justify-self: center;
        display: grid;
        grid-template-columns: 1fr 1fr;
        overflow-y: scroll;
        grid-gap: 2vw;
    }
    .ATQ_GuardRole_CreateTeam_View_container_item {
        width: 50%;
        justify-self: center;
        display: grid;
        border-radius: 1vw;
        grid-template-columns: 0.2fr 1fr 0.1fr;
        align-items: center;
        color: #fff;
        background: linear-gradient(90deg, rgba(55, 167, 185, 1) 0%, rgba(9, 64, 112, 1) 100%);
        cursor: pointer;

        .ATQ_GuardRole_CreateTeam_View_item_title {
            font-weight: 400;
            font-size: 1.3vw;
            text-align: center;
        }
        .ATQ_GuardRole_CreateTeam_View_item_img {
            width: 5vw;
            justify-self: center;
        }
        .ATQ_GuardRole_CreateTeam_View_item_img-add {
            width: 2vw;
            justify-self: center;
        }
    }
    .ATQ_GuardRole_CreateTeam_View-items-container-buttons {
        width: 40%;
        display: grid;
        grid-row-start: 3;
        justify-content: space-around;
        justify-self: end;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1vw;
        align-content: center;
        .ATQ_GuardRole_CreateTeam_View-items-button {
            padding: 0.5vw;
            font-size: 0.9vw;
            font-weight: 500;
            border-radius: 2vw;
            outline: none;
            cursor: pointer;
            border: none;
            font-family: "Poppins";
            color: #fff;
            box-shadow: 4px 3.9px 3px #c4c1c1; 
        }
        .ATQ_GuardRole_CreateTeam_View-items-button:nth-child(1) {
            background: linear-gradient(273deg, rgba(26, 188, 156, 1) 0%, rgba(9, 64, 112, 1) 100%);
        }
        .ATQ_GuardRole_CreateTeam_View-items-button:nth-child(2) {
            background: linear-gradient(90deg, rgba(26, 188, 156, 1) 0%, rgba(9, 64, 112, 1) 100%);
        }
    }
}
