.preconter_list_rep_exp_directory{
    width: 70%;
    height: 65vh;
    display: flex;
    margin-top: 2vw;
    align-items: center;
    flex-direction: column;
    overflow: scroll;

    .content_info_rep_exp_directory{
        width: 95%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        border: 1px solid #707070;
        border-radius: 0.5vw;
        padding: 0.5vw 0.9vw;
        margin-bottom: 1vw;
        color: #303030;
        cursor: pointer;
        outline: none;
        .text_info_rep_exp_directory{
            width: 95%;
            display: flex;
            flex-direction: column;
            font-size: 1.2vw;
            font-family: "Poppins";
            .info_date_number_directory{

                display: flex;
                align-items: center;
                flex-direction: row;
                font-family: "Poppins";
                color: #303030;
                font-weight: 300;
                font-size: 1vw;
            }
        }
        .storeHouseStock_elements_img_arrow_directory{
            width: .7vw;
            height: .7vw;
        }
    }
}