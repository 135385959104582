.SesionUsers-container-search {
    width: 100%;
    height: 69vh;
    overflow: scroll;
    border-bottom: 1px solid #303030;
    border-top: 1px solid #303030;


    .SesionUsers-preContainer-active {
        background: rgba(#1ABC9C, .3);
    }

    .SesionUsers-preContainer-inactive {
        background: #fff;
    }

    .SesionUsers-preContainer {
        width: 100%;
        border-top: 1px solid #303030;

        &:first-child {
            border-top: none;
           
        }


        .SesionUsers-info-container {
            width: 90%;
            display: grid;
            grid-template-columns: 1fr .1fr;
            align-items: center;
            padding: .5vw;


            .SesionUsers-container-info {
                justify-self: center;
                cursor: pointer;
                width: 98%;

                .SesionUsers-info-Date {
                    font-size: 1vw;
                    color: #303030;
                    display: inline-block;
                    padding-right: 1vw;
                    font-size: .8vw;
                    font-family: 'Poppins';
                }

                .SesionUsers-info-Hour {
                    font-size: 1vw;
                    color: #303030;
                    display: inline-block;
                    padding-right: .3vw;
                    font-size: .8vw;
                    font-family: 'Poppins';

                }

                .SesionUsers-info-Medic {
                    font-size: 1vw;
                    color: #303030;
                    display: inline-block;
                    padding-right: 1vw;
                    font-size: .8vw;
                    font-family: 'Poppins';

                }

                .SesionUsers-info-hospital {
                    font-size: 1vw;
                    color: #303030;
                    display: inline-block;
                    padding-right: 1vw;
                    font-size: .8vw;
                    font-family: 'Poppins';
                }

                .SesionUsers-info-id {
                    font-size: 1vw;
                    color: #303030;
                    display: inline-block;
                    padding-right: 1vw;
                    font-size: .8vw;
                    font-family: 'Poppins';

                }
            }

            .SesionUsers-info-imgMessage {
                justify-self: center;
                width: 1.4vw;
            }
        }
    }
}

//----------Search style ---------------------------
.SesionUsers-container-search {
    width: 100%;
    height: 69vh;
    overflow: scroll;
    border-bottom: 1px solid #303030;
    border-top: 1px solid #303030;


    .SesionUsers-preContainer-active-search {
        background: rgba(#1ABC9C, .3);
    }

    .SesionUsers-preContainer-inactive-search {
        background: #fff;
    }

    .SesionUsers-preContainer-search {
        width: 100%;
        border-bottom: 1px solid #303030;


        .SesionUsers-info-container-search {
            width: 90%;
            display: grid;
            grid-template-columns: 1fr .1fr;
            align-items: center;
            padding: .5vw;


            .SesionUsers-container-info-search {
                justify-self: center;
                cursor: pointer;
                width: 98%;

                .SesionUsers-info-Date {
                    font-size: 1vw;
                    color: #303030;
                    display: inline-block;
                    padding-right: 1vw;
                    font-size: .8vw;
                    font-family: 'Poppins';
                }

                .SesionUsers-info-Hour {
                    font-size: 1vw;
                    color: #303030;
                    display: inline-block;
                    padding-right: .3vw;
                    font-size: .8vw;
                    font-family: 'Poppins';

                }

                .SesionUsers-info-Medic {
                    font-size: 1vw;
                    color: #303030;
                    display: inline-block;
                    padding-right: 1vw;
                    font-size: .8vw;
                    font-family: 'Poppins';

                }

                .SesionUsers-info-hospital {
                    font-size: 1vw;
                    color: #303030;
                    display: inline-block;
                    padding-right: 1vw;
                    font-size: .8vw;
                    font-family: 'Poppins';
                }

                .SesionUsers-info-id {
                    font-size: 1vw;
                    color: #303030;
                    display: inline-block;
                    padding-right: 1vw;
                    font-size: .8vw;
                    font-family: 'Poppins';

                }
            }

            .SesionUsers-info-imgMessage {
                justify-self: center;
                width: 1.4vw;
            }
        }
    }
}
