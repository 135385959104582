@media screen and ( max-width: 970px )
    .c-storeHouse-principal
        .c-storeHouse
            .c-storeHouse-sec1
                font-size: 14px
                .c-storeHouse-item-horizontalform
                    img
                        width: 60px
                .c-storeHouse-item-simpleform
                    font-size: 16px
                    img
                        width: 90px
            .c-storeHouse-sec2
                .c-storeHouse-item-horizontalform
                    img
                        width: 90px
                .c-storeHouse-item-simpleform
                    img
                        width: 60px
                .c-storeHouse-c-informacion
                    .storeHouse-item-title
                        font-size: 18px

@media screen and ( max-width: 900px )
    .c-storeHouse-principal
        height: auto
        .c-storeHouse
            grid-template-columns: 1fr
            grid-template-areas: 'c-storeHouse-sec1' 'c-storeHouse-sec2'
            .c-storeHouse-sec1
                font-size: 18px
                .c-storeHouse-item-horizontalform
                    img
                        width: 100px
                .c-storeHouse-item-simpleform
                    font-size: 18px
                    img
                        width: 130px
            .c-storeHouse-sec2
                grid-template-rows: 160px 120px 160px
                grid-template-columns: 1fr 1fr
                grid-template-areas: 'c-storeHouse-c-pedidos c-storeHouse-c-informacion''c-storeHouse-c-pedidos c-storeHouse-c-estatus' 'c-storeHouse-c-encuestas  c-storeHouse-c-estatus'
                .c-storeHouse-item-horizontalform
                    img
                        width: 130px
                .c-storeHouse-item-simpleform
                    img
                        width: 100px
                .c-storeHouse-c-informacion
                    .storeHouse-item-title
                        font-size: 18px
        