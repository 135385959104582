.c-MedicHospitalModalNewRequeriment{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
    .c-MedicHospitalModalNewRequeriment-c-item{
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #a8a8a8;
        .c-MedicHospitalModalNewRequeriment-item-name{
            height: 20vh;
            background: #f7f7f7;
            font-family: 'Poppins';
            font-weight: 300;
            font-size: 16px;
            color: #696969;
            border: none;
            width: 100%;
            overflow-y: auto;
            overflow: hidden;
            outline: none;
        }
    }
    .c-MedicHospitalModalNewRequeriment-button{
        width: 60%;
        margin-top: 20px;
        padding: 10px;
        border-radius: 20px;
        border: none;
        background: linear-gradient(270deg, rgba(26,188,156,1) 0%, rgba(55,167,185,1) 91%);
        color: #fff;
        outline: none;
        cursor: pointer;
        font-size: 16px;

    }
}