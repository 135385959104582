.ATQ_GuardRole_CreateTeam_View-container{
    width: 100%;
    height: 80vh;
    padding-top: 20vh;
    display: flex;
    justify-content: center;
    font-family: "Poppins";



   
}