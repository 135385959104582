.container-component-comments-1Clasificacion {
    width: 48%;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 7px;
    -webkit-box-shadow: -1px 5px 19px 0px #efecef;
    -moz-box-shadow: -1px 5px 19px 0px #efecef;
    box-shadow: -1px 5px 19px 0px #efecef;
    margin-bottom: 3vh;

    .header-comments1Clasificacion {
        width: 100%;

        .header-title1Clasificacion {
            border-bottom: 1px solid #c2c2c2;
            padding-bottom: 1vh;

            .title-1Clasificacion {
                color: #37a7b9;
                font-family: "Poppins";
                font-size: 1.2vw;
                font-weight: 500;
                display: flex;
                justify-content: center;
                padding-top: 2vh;
            }
        }
    }

    .body_1Clasificacion {
        width: auto;
        display: flex;
        justify-content: center;
        margin: 5vh;

        .select-1clasificacion {
            width: 150px;
            border-radius: 5px;
            padding: 5px;
            font-family: "Poppins";
            font-size: 0.9vw;
            font-weight: 200;
            color: #303030;
            justify-content: center;
            display: flex;
            cursor: pointer;
        }

        .select-1clasificacion-analisis {
            width: 150px;
            border-radius: 5px;
            padding: 5px;
            font-family: "Poppins";
            font-size: 0.9vw;
            font-weight: 500;
            color: #303030;
            background: #aaa6a64d;
            justify-content: center;
            display: flex;
            cursor: pointer;
        }
    }
}
