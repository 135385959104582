.StoreHomeAvailableMaterialToAddToggleButtons_container_toggle{
    .StoreHomeAvailableMaterialToAddToggleButtons_toggle_container_buttons{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        .StoreHomeAvailableMaterialToAddToggleButtons_toggle_button{
            padding: .5vw;
            border: none;
            background: #707070;
            color: #ffff;
            cursor: pointer;
            outline: none;
            width: 100%;
        }
        .StoreHomeAvailableMaterialToAddToggleButtons_toggle_button_active{
            background: rgb(55,167,185);
            background: linear-gradient(152deg, rgba(55,167,185,1) 0%, rgba(9,64,112,1) 100%);
        }
        .StoreHomeAvailableMaterialToAddToggleButtons_toggle_button_left{
            border-radius: .2vw 0 0 .2vw;
            width: 100%;
        }
        .StoreHomeAvailableMaterialToAddToggleButtons_toggle_button_rigth{
            border-radius: 0 .2vw .2vw 0;
            width: 100%;
        }
    }
    
}
