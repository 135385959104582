.precontainer_Atq_Inf {
    display: flex;
    width: 100%;
    justify-content: center;
    align-content: center;
    height: auto;
    flex-direction: column;
    .second_section {
        width: 85%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-top: 10vh;
        margin-left: 8vw;
        grid-gap: 10vw;
        margin-bottom: 1vw;
        .left {
            width: 100%;
        }
    }

    .section-3 {
        width: 100%;
        height: 9vh;
        display: flex;
        justify-content: end;
        margin-bottom: 1vh;

        .btn_asignar {
            margin-right: 8vw;
            font-family: "Poppins";
            background: linear-gradient(93deg, #1abc9c 10%, #094070 100%);
            color: #fff;
            width: 15%;
            padding: 0.4vw 1.5vw;
            font-size: 0.9vw;
            height: 4vh;
            cursor: pointer;
            display: flex;
            justify-content: center;
            border: none;
            border-radius: 2vw;
            box-shadow: 4px 3.9px 3px #c4c1c1;
        }
    }

    .container_img {
        display: flex;
        height: auto;
        padding-top: 24vh;
        width: 32%;
        justify-self: center;
        align-self: center;
        .container_atq {
            width: auto;
            display: grid;
            grid-template-columns: 1fr 1fr;
            // border: 1px solid #707070;
            box-shadow: 1.5px 1.5px 4px #707070;
            border-radius: 0.5vw;
            height: 28vh;

            .colum_right {
                display: grid;
                grid-template-rows: 1fr 0.3fr;

                .letter {
                    display: flex;
                    flex-direction: row;

                    .point {
                        background: #1abc9c;
                        width: 1.2vw;
                        height: 1.2vw;
                        border-radius: 50%;
                        margin-right: 2.5vw;
                        margin-left: 1.2vw;
                        margin-top: 0.2vw;
                    }
                    .pointred {
                        background: #e30613;
                        width: 1.2vw;
                        height: 1.2vw;
                        border-radius: 50%;
                        margin-right: 2.5vw;
                        margin-left: 1.2vw;
                        margin-top: 0.2vw;
                    }
                    .available {
                        font-family: "Poppins";
                        font-weight: 400;
                        font-size: 17px;
                        color: #303030;
                        padding-top: 0.2vh;
                    }
                }

                .img_atq {
                    width: 9vw;
                    height: 9vw;
                    align-self: center;
                    margin-left: 1vw;
                    /*   justify-self: center; */
                    border-radius: 50%;
                    // padding: 1vw 0 0 1vw;
                    border: 0.1vw solid #37a7b9;
                    max-width: 130 px;
                    max-height: 130 px;

                    img {
                        width: 100%;
                    }
                }
            }
            .colum_left {
                display: flex;
                flex-direction: column;
                justify-self: end;
                align-self: center;
                margin-right: 1.5vw;

                .name {
                    font-family: "Poppins";
                    font-weight: 500;
                    font-size: 20px;
                    color: #303030;
                }
                .phone {
                    font-family: "Poppins";
                    font-weight: 400;
                    font-size: 12px;
                    color: #777777;
                }

                span {
                    display: block;
                    text-align: end;
                }
            }
        }
    }
}
