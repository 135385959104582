.quote_completed_general_c {
    margin: 40px auto 40px auto;
    width: 90%;
    position: relative;
    padding-top: 7vw;

    * {
        font-family: 'Poppins';
    }

    .button_elements_content {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;

        .radioButtonOptions {
            color: #777778;

            .MuiRadio-colorSecondary.Mui-checked {
                color: #38A8B9;
            }

            .MuiTypography-body1 {
                font-family: 'Poppins';
            }
        }
    }

    .buttons_options {
        display: contents;
    }
}

.btn {
    background: linear-gradient(90deg, rgba(15, 65, 111, 1) 0%, rgba(56, 168, 185, 1) 100%);
    color: #fff;
    width: 10%;
    padding: .5vw;
    cursor: pointer;
    border: none;
    border-radius: 2vw
}

.c-quote_completed_allelements {
    overflow-y: scroll;
    height: 75vh;

    ::-webkit-scrollbar {
        display: none;
        scrollbar-width: none;
    }

    .quote_completed_allelements {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .cardB {
            width: 100%;
        }
    }
}

.c-quote-navbarBottom {
    background: linear-gradient(90deg, rgba(232, 232, 232, 1) 0%, rgba(232, 232, 232, 1) 94%);
    border-radius: 10px;
    height: 6vh;
    z-index: 1;
    display: flex;
    overflow: hidden;
    bottom: 0;
    width: 100%;
}

.btnExcel {
    background: linear-gradient(90deg, rgba(29, 111, 66, 1) 0%, rgba(29, 111, 66, 1) 94%);
    color: #fff;
    width: 10%;
    height: 65%;
    padding: .5vw;
    cursor: pointer;
    border: none;
    border-radius: 2vw;
    display: flex;
    justify-content: center;
}

.btnBottomPackages {
    background: linear-gradient(90deg, rgba(15, 65, 111, 1) 0%, rgba(56, 168, 185, 1) 100%);
    color: #fff;
    width: 20%;
    height: 65%;
    padding: .5vw;
    cursor: pointer;
    border: none;
    border-radius: 2vw;
    display: flex;
    justify-content: center;
}

.btnDeletePackages {
    background: linear-gradient(90deg, rgba(208, 0, 0, 1) 0%, rgba(208, 0, 0, 1) 94%);
    color: #fff;
    width: 30%;
    height: 70%;
    padding: .5vw;
    cursor: pointer;
    border: none;
    border-radius: 2vw;
    display: flex;
    justify-content: center;
}

.title {
    color: #000;
}

.btnDisc {
    background: linear-gradient(90deg, rgba(208, 171, 0, 1) 0%, rgba(208, 171, 0, 1) 94%);
    color: #fff;
    width: 15%;
    height: 65%;
    padding: .5vw;
    cursor: pointer;
    border: none;
    border-radius: 2vw;
    display: flex;
    justify-content: center;
}

.c-home-c-programar-img {
    margin-right: 10;
}