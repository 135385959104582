.c-offerValidityModal
    width: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    .c-offerValidityModal-c-title
        width: 90%
        text-align: start
        .c-offerValidityModal-title
            margin-top: 0
            font-family: 'Poppins'
            font-weight: 300
            font-size: 22px
    .c-offerValidityModal-c-info
        width: 90%
        display: flex
        flex-direction: row
        justify-content: space-around
        border-bottom: 1px solid #a3a3a3
        padding-bottom: 10px
        .c-offerValidityModal-info
            width: 30%
            color: #a3a3a3
            font-size: 20px
            font-weight: 300
            text-align: center
            border: none
            outline: none
    .c-offerValidityModal-button
        width: 60%
        padding: 10px
        margin-top: 30px
        border-radius: 20px
        border: none
        background: linear-gradient(270deg, rgba(26,188,156,1) 0%, rgba(55,167,185,1) 91%)
        color: #fff
        outline: none
        cursor: pointer
        font-size: 16px