.c-home-principal
    display: flex
    justify-content: center
    align-items: center
    height: 100vh
    .c-home
        display: grid
        grid-template-columns: 1fr 1fr
        grid-template-areas: 'c-home-sec1 c-home-sec2'
        width: 90%
        height: 80vh
        margin: auto
        grid-auto-flow: row dense
        margin-top: 40px
        margin-bottom: 40px
        grid-gap: 20px
        padding-top: 60px
        .c-home-sec
            display: grid
            grid-gap: 20px
            justify-items: center
            align-items: center