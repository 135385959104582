.container_Mat{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: start;
    height: 88vh;
    padding-top: 14vh;
    padding-bottom: 2vh;

    .container-colums_Mat{
        width: 83%;
        display: grid;
        grid-template-columns: .8fr 1fr;
        justify-self: center;
        margin-top: 8vh;

        .container-button_Mat{
            display: flex;
            justify-content: end;
            margin-top: 1vw;

            .btn_aceptar{
                    border: none;
                    color: #fff;
                    cursor: pointer;
                    width: 23%;
                    background: linear-gradient(  270deg, #1abc9c 0%, #094070 91%);
                    font-size: 0.9vw;
                    font-family: "Poppins";
                    text-transform: uppercase;
                    padding: 4px 1rem;
                    border-radius: 20px;
                    box-shadow: 4px 3.9px 3px #c4c1c1;
            }
        }
    }
    // section{
    //     margin-top: 10vh;
    // }
}