.c-medic-c-search{
    width: 47vw;
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: center;
    .tools-c-input-search{
        width: 100%;
        display: flex;
        align-items: center;
        justify-items: center;
        font-size: 18px;
    
        .c-tools-search-icobuscar{
            background: #dddddd;
            padding: 0px 6px 0px 12px;
            border-radius: 20px 0 0 20px;
            justify-content: center;
            align-items: center;
            height: 32px;
            border: none;
            display: flex;
            img{
                width: 18px;
            }
        }
        .tools-input-search{
            border: none;
            width: 50%;
            background: #dddddd;
            border-radius: 0 20px 20px 0;
            outline: none;
            padding: 5px;
            height: 22px;
            font-family: "Poppins";
            font-size: 1.2vw;

        }
    }
}
.leftTitle {
font-family: 'Poppins';
font-size: 25px;
color: #303030;
font-weight: 400;
}