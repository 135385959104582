.SearchChatComponent-tools-c-input-search{
    width: 92%;
    display: flex;
    align-items: center;
    justify-items: center;
    .c-SearchChatComponent-tools-search-icobuscar{
        background: #dddddd;
        padding: 0px 6px 0px 12px;
        border-radius: 20px 0 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 27px;
        border: none;
        img{
            width: 18px;
        }
    }
    .SearchChatComponent-tools-input-search{
        border: none;
        width: 100%;
        background: #dddddd;
        border-radius: 0 20px 20px 0;
        outline: none;
        height: 24px;
        font-size: .9vw;
        font-family: "Poppins";
        font-weight: 350;
        color: #000;

        
    }

    ::placeholder{
        font-family: "Poppins";
        font-weight: 300;
        color: #000;
        font-size: 0.9vw;
    }
}