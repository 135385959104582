%cardToFactoryContainer{
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 4vw;
    margin-bottom: 1vw;
    cursor: pointer;
    border-radius: 1vw;
    padding: .5vw;
    .FactoryMaterial_list_component_card_container_info{
        width: 100%;
        .FactoryMaterial_list_component_card_container_info_name{
            width: 100%;
            margin: 1vw 0;
            text-align: start;
        }
    }
}

%FactoryMaterial_component_container_search{
    display: grid;
    justify-items: center;
    align-items: center;
    grid-gap: .4vw;
    font-family: 'Poppins';
    font-size: .8vw;
    height: 0vh;
    .FactoryMaterial_list_component_search{
        width: 90%;
        padding: .5vw;
        @extend %formSearchPermissionsUser;

    }
}


%formSearchPermissionsUser{
    border-radius: .7vw;
    border: none;
    outline: none;
    color: #666666;
    background: #e4e4e4;
}

    .FactoryMaterial_list_component_card_container_info_requeriments{
        grid-template-columns: 1fr .2fr !important;
        width: 100%;
        .FactoryMaterial_list_component_card_info_text{

        }
        .FactoryMaterial_list_component_card_container_info_img{
            width: 65%;
            display: flex;
            justify-content: center;
            align-items: center;
            justify-self: center;
            border: 1px solid #a80000;
            border-radius: 50%;
            cursor: pointer;
            .FactoryMaterial_list_component_card_info_img{
                width: 2vw;
            }
        }

    }
    .FactoryMaterial_list_component_card_container_info{

        display: grid;
        justify-content: space-between;
        grid-template-columns: 1fr;
        align-items: center;
        width: 95%;
        margin-right: 5px;
        font-weight: 300;
        overflow: hidden;
        font-family: 'Poppins';
        font-size: 1.2vw;
        .FactoryMaterial_list_component_card_container_info_name{
            margin-bottom: .5vw;
        }

    }

    .FactoryMaterial_list_component_card_container_arrow{

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 10%;
        margin-right: 5px;
        .arrowRight{

        }
    }

.container_FactoryMaterial_list_component_materials{
    grid-template-rows: .05fr .05fr .1fr 1fr;
}

.container_FactoryMaterial_list_component_sistems{
    
    grid-template-rows: .1fr .1fr 1fr;
}

.UserComponentsSellers_list_component{
    
    grid-template-rows: .1fr .1fr .1fr 1fr;
}

.container_FactoryMaterial_list_component_materials_to_sistem{
    
    grid-template-rows: .05fr .1fr 1fr;
}


.container_FactoryMaterial_list_component{

    width: 100%;
    height: 73vh;
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    justify-items: center;
    border-radius: 18px;
    box-shadow:  0px 2px 5px #666666, 1px -5px 0px #ffffff;

    .container_FactoryMaterial_container_tab_buttons{
        margin-top: .5vw;
        width: 80%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1vw;
        .container_FactoryMaterial_tab_buttons_active{
            background: #37a7b9;
            color: #fff;
            border-radius: .5vw;
            cursor: pointer;
            border: none;
            padding: .2vw;
        }
        .container_FactoryMaterial_tab_buttons{
            background: #f5f5f5;
            color: #00325D;
            border-radius: .5vw;
            cursor: pointer;
            border: #37a7b9 solid .1vw;
            padding: .2vw;
        }
    }

    .FactoryMaterial_list_component_container_search_with_combo_without{
        grid-template-columns: 1fr !important;
    }

    .FactoryMaterial_list_component_container_search_with_combo{
        @extend %FactoryMaterial_component_container_search;
        width: 90%;
        grid-template-columns: 1fr .5fr;
        .FactoryMaterial_list_search_input_combobox{
            @extend %formSearchPermissionsUser;
            padding: .3vw;
        }
    }

    .FactoryMaterial_list_component_container_search{
        @extend %FactoryMaterial_component_container_search;
        width: 80%;
    }

    .FactoryMaterial_list_component_container_title{
        margin: .1vw 0;
        display: flex;
        width: 90%;
        justify-content: space-around;
        align-items: center;
        .FactoryMaterial_list_component_title{
            
            text-align: center;
            font-family: 'Poppins';
            font-weight: 400;
            color: #00325D;
            font-size: 1.5vw;
        }
        .FactoryMaterial_list_component_title_button_active{
            text-align: center;
            width: 50%;
            margin: 0 .5vw;
            font-family: 'Poppins';
            font-weight: 400;
            color: #fff;
            font-size: 1.3vw;
            background: #37a7b9;
            border: none;
            padding: .5vw;
            border-radius: 1vw;
        }

        .FactoryMaterial_list_component_title_button{
            text-align: center;
            width: 50%;
            margin: 0 .5vw;
            font-family: 'Poppins';
            font-weight: 400;
            color: #00325D;
            font-size: 1.3vw;
            background: #fff;
            border: 1px solid #37a7b9;
            padding: .5vw;
            border-radius: 1vw;
        }
    }
    
    .FactoryMaterial_list_component_container_cards{
        width: 95%;
        overflow: auto;
        margin-bottom: 2vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        
        .FactoryMaterial_list_component_container_card_selected{

            @extend %cardToFactoryContainer;
            background: #37a7b9;
            color: #fff;
            border-left: .5vw solid #37a7b9;
            
        }
        .FactoryMaterial_list_component_container_card_requeriment{
            cursor: default !important;
        }
        .FactoryMaterial_list_component_container_card{

            @extend %cardToFactoryContainer;
            background: #fff;
            border-left: .5vw solid #37a7b9;
            border-top: 1px solid #37a7b9;
            border-bottom: 1px solid #37a7b9;
            border-right: 1px solid #37a7b9;
            color: #666666;
        }
        
    }

}







