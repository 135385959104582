.StoreHouseApproveStatusEditFieldComponent-component{
    
    .StoreHouseApproveStatusEditFieldComponent-noEdit-component{
        display: flex;
        justify-content: center;
        align-items: center;
        .StoreHouseApproveStatusEditFieldComponent-title{
            margin-right: 1vw;
            color: #777;
        }
        .StoreHouseApproveStatusEditFieldComponent-img{
            width: 1.5vw;
            cursor: pointer;
            outline: none;
        }
    }

    .StoreHouseApproveStatusEditFieldComponent-form{
        display: flex;
        justify-content: center;
        align-items: center;
        .StoreHouseApproveStatusEditFieldComponent-input{
            border: 1px solid rgb(172, 172, 172);
            padding: .3vw;
            color: #777;
            outline: none;
            border-radius: .5vw;
            margin-right: 1vw;
        }
        .StoreHouseApproveStatusEditFieldComponent-button{
            width: 1.5vw;
            height: 1.5vw;
            background: linear-gradient(275deg, #094070 0%, #37a7b9 100%);
            border: none;
            outline: none;
            border-radius: .3vw;
            cursor: pointer;
        }
    }
}