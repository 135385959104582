$gradient: linear-gradient(270deg, rgba(9,64,112,1) 0%, rgba(55,167,185,1) 91%);
.containerBillData {
    padding-top: 20vh;
    //height: 80vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .headerBill {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 95vw;

        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        color: #094070;
        font-weight: 700;
    }

    .titleBill {
        display: block;
        align-items: center;
    }

    hr {
        width: 30%;
        text-align: start;
        height: 4px;
        background-color: #37A7B9;
        margin-left: 0;
        border: none;
    }

    .searchContainer {
        width: 95vw;
    }
    
    .responseContainer {
        width: 95vw;
        display: grid;
        align-items: center;
        margin-bottom: 200px;
        margin-top: 20px;

        .tableContainerBill {
            width: 100%;
        }

        .message {
            text-align: center;
            font-family: 'Montserrat', sans-serif;
            font-size: 24px;
            color: #5a5959;
            font-weight: 700;
        }
    }
}

.buttons {
    color: #fff !important;
    background: $gradient;
    border: none;
    border-radius: 20px;
    padding: 5px 30px 5px 30px;
    font-family: 'Inter', sans-serif;
}

.disableButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #c3c3c3;
    border-radius: 50px;
    padding: 10px 20px;
    font-family: 'Roboto';
    font-size: 16px;
    text-transform: uppercase;
    color: #FFFFFF !important;
    margin-right: 10px;
    
    img {
        margin-right: 15px;
    }
}

.importBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $gradient;
    border-radius: 50px;
    padding: 10px 20px;
    font-family: 'Roboto';
    font-size: 16px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-right: 10px;
    
    img {
        margin-right: 15px;
    }
}

.containerTitle {
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 20px;
    color: black;
    margin-left: 20px;
}

.containerTitle2 {
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 20px;
    color: black;
    margin-left: 20px;
}

.containerText {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: black;
    margin-top: 16px;
    margin-left: 20px;
}

.dialogTitleBg {
    background: $gradient;
    color: #fff;
}

.successMainContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.successSecContainer {
    position: relative;
    height: 250px; 
    width: 600px;
    
}

.successIcon {
    position: absolute;
    left: 50%;
    top: 20%;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -20%);
    animation: bounce alternate infinite 0.4s linear;
    -moz-animation: bounce alternate infinite 0.4s linear;
    -webkit-animation: bounce alternate infinite 0.4s linear;
    -o-animation: bounce alternate infinite 0.4s linear;
}

.sombra {
    width: 100px;
    height: 10px;
    position: absolute;
    border-radius: 50%;
    top: 52%;
    left: 42%;
    background-color: rgba(0,0,0, 0.3);
    animation: shadow alternate infinite 0.4s linear;
    -moz-animation: shadow alternate infinite 0.4s linear;
    -webkit-animation: shadow alternate infinite 0.4s linear;
    -o-animation: shadow alternate infinite 0.4s linear;

}

.successSecContainer p {
    position: relative;
    
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 25px;
    top: 70%;
    text-align: center;
}

@keyframes bounce {
    0% {
        top: 15%;
    }
}

@-webkit-keyframes bounce {
    0% {
        top: 15%;
    }
}

@-moz-keyframes bounce {
    0% {
        top: 15%;
    }
}

@-o-keyframes bounce {
    0% {
        top: 15%;
    }
}

@-ms-keyframes bounce {
    0% {
        top: 15%;
    }
}

@keyframes shadow {
    0% {
        transform: scale(1.1);
    }
}

@-webkit-keyframes shadow {
    0% {
        transform: scale(1.1);
    }
}

@-moz-keyframes shadow {
    0% {
        transform: scale(1.1);
    }
}

@-o-keyframes shadow {
    0% {
        transform: scale(1.1);
    }
}

@-ms-keyframes shadow {
    0% {
        transform: scale(1.1);
    }
}
