.schedule-list-content-base {
    width: 100%;
    display: flex;
    flex-direction: column;
    .schedule-serch-title {
        font-size: 22px;
        font-weight: 500;
        font-family: 'Poppins';
        color: #094070;
        margin: 2rem 1rem 0 1rem;
        padding-left: 40px;
    }
    .schedule-serch-sub-title {
        font-size: 18px;
        font-weight: 500;
        font-family: 'Poppins';
        color: #707070;
        margin: 1.5rem 1rem -1rem 1rem;
        padding-left: 40px;
    }
    .schedule-inputs-content {
        display: flex;
        margin: 2rem 1rem 0 1rem;
        padding-left: 40px;
        .numericInputStyle {
            margin-right: 1vw;
        }
    }
    .search-icon {
        width: 30px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 1rem;
        cursor: pointer;
        img {
            width: 30px;
            height: 30px;
        }
    }
    .search-input-content {
        width: 30vw;
        margin: 2rem 1rem 0 1rem;
        padding-left: 40px;
    }
    .schedule-list-title {
        font-size: 28px;
        font-weight: bold;
        font-family: 'Poppins';
        color: #094070;
        text-transform: uppercase;
        margin: 2.5rem 1rem 0 1rem;
        padding-left: 40px;
    }
}
.schedule-list-ul {
    text-decoration: none;
    list-style-type: none;
    width: calc( 100% - 6rem);
    justify-content: space-around;
    margin: 1.5rem 1rem 0 1rem;
    .top_border_li {
        border-top: 1px solid #303030;
    }
    .schedule-list-li {
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #303030;

    }
        .schedule-list-li-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: none;
            background-color: white;
            outline: none;
            padding: 0 1rem;
            font-family: 'Poppins';
            font-size: 17px;
            font-weight: 500;
            color: #303030;
            cursor: pointer;
            .arrowIcon {
                width: 7px;
            }
            .arrowCloseIcon {
                width: 8px;
            }
            .section-right{
                display: flex;
                flex-direction: column;

                .txtHosp{
                    text-align: start;
                    margin-top: 0.6rem;
                }
            }
        }
        .schedule-list-li-body {
            padding-top: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            padding-left: 40px;
            padding-right: 40px;
            .schedule-list-li-title {
                font-family: 'Poppins';
                font-size: 16px;
                color: #707070; 
            }
            .schedule-list-ol {
                text-decoration: none;
                list-style-type: none;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                position: relative;
                li{
                    display: flex;
                    padding: 11px 0;
                    position: relative;
                    align-items: center;
                    &:after {
                        content: '';
                        position: absolute;
                        background: #707070;
                        display: block;
                        width: 2px;
                        height: 23px;
                        top: 34px;
                        left: 11px;
                        z-index: 0;
                    }
                    &:last-child {
                        &:after {
                            width: 0!important;
                            height: 0!important;
                        }
                    }
                }
                
                .textNonActualState {
                    font-family: 'Poppins';
                    font-size: 16px;
                    font-weight: 500;
                    color: #707070;
                    justify-content: space-between;
                    .processNumber {
                        border: 2px solid #707070;
                        color: #ffffff;
                        font-weight: 400;
                        z-index: 0;
                        background-color: #707070;
                    }
                }
                .textActualState {
                    font-family: 'Poppins';
                    font-size: 16px;
                    font-weight: 500;
                    color: #1ABC9C;
                    .processNumber {
                        width: 20px!important;
                        height: 20px!important;
                        border: 2px solid #1ABC9C;
                        color: #ffffff;
                        font-weight: 500;
                        background-color: #1ABC9C;
                    }

                    &:after {
                      
                        background: #1ABC9C !important; 
                       
                    }
                }
                .processNumber {
                    width: 19px;
                    height: 19px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    border-radius: 50%;
                    margin-right: 1rem;
                    span {
                        width: 17px;
                        height: 17px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                    }
                }
            }
            .schedule-list-li-button {
                display: flex;
                justify-content: flex-end;
            }
        }
}
.schedule-selected-data-content {
    margin: 1rem 1rem 0 1rem;
    padding: 0 40px;
    .schedule-selected-item {
        display: flex;
        justify-content: space-between;
        padding: 8px 0;
        border-bottom: 1px solid #CCCCCC;
        font-family: 'Poppins';
        font-size: 16px;
        .schedule-selected-item-title {
            color: #707070;
        }
        .schedule-selected-item-information {
            color: #303030;
            .span-description{
                display: block;
                text-align: end;
            }
        }
    }
}