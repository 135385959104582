.c-footer
    width: 100%
    height: 4vh
    background: #094070
    position: fixed
    bottom: 0
    .c-footer-copyright
        text-align: center
        color: #fff
        font-size: .6vw
        font-family: 'Poppins'
        font-weight: 300