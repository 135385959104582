%ButtonToAddOrDelete{
    position: absolute;
    border-radius: 50%;
    width: 2.5vw;
    height: 2.5vw;
    border: none;
    box-shadow: 0px 1px 6px -1px rgb(0 0 0 / 35%);
    background: #FFFFFF;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
}


.StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-container-table{
    width: 100%;
    display: flex;
    justify-content: center;
    border-spacing: 0;
    padding-top: 1vw;
    height: 45vh;
    box-shadow: 1px 1px 7px -2px #777777;
    position: relative;
    .StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-precontainer-table{
        width: 95%;
        .StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-table{
            width: 100%;
            border-collapse: collapse;
            display: inline-block;
            box-sizing: border-box;
        }
        .StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-table-tr{
            display: flex;
            width: 100%;
        }
        .StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-table-head{
            width: 100%;
            display: flex;
            .StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-table-title:nth-child(1){
                text-align: start;
            }
            .StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-table-title{
                font-weight: 400;
                border-bottom: .07vw solid #a7a7a7;
                padding-bottom: 1vw;
                width: calc( 100% / 3);
                font-size: 1vw;
                color: #094070;
            }
            .shtmriric-button{
                width: 10%;
            }
        }
        .StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-table-body{
            height: 40vh;
            width: 100%;
            overflow: auto;
            display:block;
            .StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-body-data:nth-child(1){
                text-align: start;
            }
            .StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-body-data{
                text-align: center;
                padding: 1vw 0 .5vw 0;
                color: #777777;
                font-size: 1vw;
                display: inline-block;
                width: calc( 100% / 3);
                box-sizing: border-box;
            }
            .StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-button{
                width: 1.8vw;
                height: 1.8vw;
                background: #FFFFFF;
                border-radius: 1vw;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 1px 1px 4px -0.5px #a2a2a3;
                cursor: pointer;
                outline: none;

                .StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-button-img1{
                    width: 1vw;
                    margin-top: 3px;
                    
                }

                .StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-button-img{
                    width: 1.8vw;
                }
            }
            .shtmriric-button{
                width: 10%;
            }
            .StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-body-select{
              cursor: pointer;
            }
        }
        .StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-section-rigth-card-container-add-container{
            right: -1vw;
            bottom: -1vw;
            @extend %ButtonToAddOrDelete;
            .StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-section-rigth-card-container-add{
                font-size: 1.7vw;
                color: rgb(199, 0, 0);
                width: 1.7vw;
            }
        }
    }
}