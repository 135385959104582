.Survey_Ventas_elements_container_info {
    height: 61vh;
    display: flex;
    margin-top: 2vw;
    align-items: center;
    flex-direction: column;
    width: 86%;
    overflow: scroll;

    .Survey_Ventas_elements_info_container {
        width: 95%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        border: 1px solid #707070;
        border-radius: 0.5vw;
        padding: 0.5vw 0.9vw;
        margin-bottom: 1vw;
        color: #303030;
        cursor: pointer;
        outline: none;

        .Survey_Ventas_elements_info_elements_container {
            width: 95%;
            display: flex;
            flex-direction: column;

            .Survey_Ventas_elements_info_date_container {
                display: flex;
                align-items: center;
                flex-direction: row;
                font-family: "Poppins";
                margin-bottom: 0.5vw;

                .Survey_Ventas_elements_info_date {
                    font-weight: 300;
                    font-size: 1vw;
                }
            }

            .Survey_Ventas_elements_info_ubicacion_container {
                display: flex;
                font-size: 1vw;
                align-items: center;
                flex-direction: row;

                .Survey_Ventas_elements_info_ubicacion_element {
                    margin-right: 1vw;
                    font-weight: 300;
                }
            }
            
        }
        .Survey_Ventas_elements_img_arrow {
            width: .7vw;
            height: .7vw;
        }
        
    }
}
