.container-component-comments-surveyCausa {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 7px;
    -webkit-box-shadow: -1px 5px 19px 0px #efecef;
    -moz-box-shadow: -1px 5px 19px 0px #efecef;
    box-shadow: -1px 5px 19px 0px #efecef;
    margin-bottom: 3vh;

    .header-commentsCausa {
        width: 100%;

        .header-titleCausa {
            border-bottom: 1px solid #c2c2c2;
            padding-bottom: 0.5vh;

            .title-componentCausa {
                color: #37a7b9;
                font-family: "Poppins";
                font-size: 1.2vw;
                font-weight: 500;
                display: flex;
                justify-content: center;
                padding-top: 1vh;
            }
        }
    }

    .body-causa {
        width: auto;
        display: flex;
        flex-direction: column;
        border: 1px solid #c2c2c2;
        margin: 10px 5px 15px 5px;

        .container-causa {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;

            .container-causa-left {
                width: 50%;
                // border-right: 1px solid #000;

                .preco-row-causa {
                    display: flex;
                    width: 90%;
                    margin: 5px 10px;
                    padding-bottom: 10px;
                    justify-content: space-between;

                    .text-causa {
                        color: #303030;
                        font-family: "Poppins";
                        font-size: 0.9vw;
                        font-weight: 300;
                    }
                }
            }

            .container-causa-rigth {
                width: 50%;
                // border-right: 1px solid #000;

                .preco-row-causa {
                    display: flex;
                    width: 90%;
                    margin: 5px 10px;
                    padding-bottom: 10px;
                    justify-content: space-between;

                    .text-causa {
                        color: #303030;
                        font-family: "Poppins";
                        font-size: 0.9vw;
                        font-weight: 300;
                    }
                    .input_check_causa {
                        cursor: pointer;
                    }

                    .input_check_causaAgregar {
                        border: none;
                        border-bottom: 1px solid #000;
                        color: #303030;
                        width: 50vw;
                        font-family: "Poppins";
                        font-size: 0.9vw;
                        font-weight: 200;
                        padding-bottom: 4vh;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
