.container_menu_survery{
    
    padding: 20vh 0 0 0;
    font-family: 'Poppins';
    display: flex;
    align-items: center;
    flex-direction: column;

    .menu_survery_container{
        width: 90%;

        .menu_survery_container_toggle{
            width: 100%;
            display: grid;
            grid-template-columns: 1fr .5fr .8fr;
            justify-content: flex-end;
            align-items: center;
            flex-direction: row;
            
            .menu_survery_container_buttons{
                width: 100%;
                display: grid;
                grid-column-start: 3;
                grid-template-columns: 1fr 1fr;

                .normal_button{
                    padding: 0.5vw;
                    border: none;
                    background: #707070;
                    color: #ffff;
                    cursor: pointer;
                    outline: none;
                }
                .btn_left{
                    border-radius: 0.2vw 0 0 0.2vw;
                }
                .btn_rigth{
                    border-radius: 0 0.2vw 0.2vw 0;
                }
                .btn_active{
                    background: #37a7b9;
                    background: linear-gradient(270deg, #1abc9c 0%, #094070 91%);
                }

            }
        }

        .menu_survery_container_elements{
            display: grid;
            grid-template-columns: 1fr;
            height: 75vh;
            width: 100%;
        }
    }
}