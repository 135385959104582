.principal-container{
    display: flex;
    width: 100%;
    justify-content: center;


    .post-container{
        display: grid;
        width: 90%;
        height: 82vh;
        margin-top: 12vh;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 1.8vw;

        .first-row{
            display: grid;
            width: 100%;
            grid-template-columns: 1fr 1fr;
            grid-gap: 2vw;

            .report-consum{
                border-radius: 10px;
                background: linear-gradient(90deg, #090979 -24%, #37a7b9 100%, #090979 94070%);
                display: grid;
                grid-template-columns: .8fr 1fr;
                cursor: pointer;
                outline: none;
                border: none;
                opacity: .4;

                .img-reporte-consumo{
                    justify-self: center;
                    align-self: center;
                    width: 12vw;
                }
                .disabled{
                    opacity: .6;
                }
                .report{
                    font-size: 1.8vw;
                    font-family: "Poppins";
                    font-weight: 300;
                    color:#fff;
                    align-self: center;
                
                }
            }

            .report-experiencie{
                border-radius: 10px;
                background: linear-gradient(90deg, #37a7b9 23%, #1abc9c 75%, #37a7b9 94070%); 
                display: grid;
                grid-template-columns: 1.2fr 0.7fr;
                cursor: pointer;
                outline: none;
                border: none;

                .img-reporte-experience{
                    justify-self: center;
                    align-self: center;
                    width: 12vw;
                }
                .report-experience{
                    font-size: 1.8vw;
                    font-family: "Poppins";
                    font-weight: 300;
                    color:#fff;
                    align-self: center;
                    justify-self: center;
                
                }
            }
        }
        .second-row{
            display: grid;
            width: 100%;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 2vw;

            .agenda{
                border-radius: 10px;
                background: linear-gradient(93deg, #1abc9c 10%, #094070 100%);
                display: grid;
                grid-template-columns: .8fr 1fr;
                cursor: pointer;
                outline: none;
                border: none;

                .img-agenda{
                    justify-self: center;
                    align-self: center;
                    width: 9vw;
                }
                .agenda-text{
                    font-size: 1.8vw;
                    font-family: "Poppins";
                    font-weight: 300;
                    color:#fff;
                    align-self: center;
                
                }
            }
            .encuesta{
                border-radius: 10px;
                background: linear-gradient(90deg, #090979b3 23%, #37a7b9 75%, #090979 94070%);
                display: grid;
                grid-template-columns: .8fr 1fr;
                cursor: pointer;
                outline: none;
                border: none;

                .img-encuesta{
                    justify-self: center;
                    align-self: center;
                    width: 9vw;
                }
                .encuesta-text{
                    font-size: 1.8vw;
                    font-family: "Poppins";
                    font-weight: 300;
                    color:#fff;
                    align-self: center;
                
                }
            }
            .informacion{
                border-radius: 10px;
                background: linear-gradient(93deg, #1abc9c 10%, #094070 100%);
                display: grid;
                grid-template-columns: .8fr 1fr;
                cursor: pointer;
                outline: none;
                border: none;

                .img-informacion{
                    justify-self: center;
                    align-self: center;
                    width: 9vw;
                }
                .informacion-text{
                    font-size: 1.8vw;
                    font-family: "Poppins";
                    font-weight: 300;
                    color:#fff;
                    align-self: center;
                
                }
            }
        }
    }
}