.c-users-principal
    display: flex
    justify-content: center
    align-items: center
    height: 100vh
    
    .c-users
        display: grid
        grid-template-columns: 1fr 1fr
        grid-template-areas: 'c-users-sec1 c-users-sec2'
        width: 90%
        height: 80vh
        margin: auto
        grid-auto-flow: row dense
        margin-top: 40px
        margin-bottom: 40px
        grid-gap: 50px
        padding-top: 60px
        .c-users-sec
            display: grid
            grid-gap: 50px
            justify-items: center
            align-items: center