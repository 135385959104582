.container_team{
    width: 20vw;
    display: grid;
    grid-template-rows: .2fr 1fr .3fr;
    height: 30vh;
    border: solid 1px #707070;
    border-radius: 1vw;
    .name_team{
        justify-self: center;
        align-self: center;
        font-family: "Poppins";
        font-weight: 400;
    }
    .list{
        font-family: 'Poppins';
        font-weight: 200;
        overflow: scroll;
        font-size: 1.2vw;

        li{
            padding-bottom: .5vw;
        }
    }

    .btn_modify_team{
        background: linear-gradient( 180deg, #37a7b9 0%, #094070 100%);
        color: white;
        border-radius: 1vw;
        border: none;
        font-family: "Poppins";
        font-weight: 400;
        display: grid;
         grid-template-columns: 1fr .3fr;
         span{
            justify-self: center;
            align-self: center;
            padding-left: 4vw;
         }
         img{
            justify-self: center;
            align-self: center;
            width: 2vw;
         }
    }
}