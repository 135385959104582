.precontainer_page{
    height: 80vh;
    padding-top: 20vh;
    font-family: "Poppins";
    display: flex;
    align-items: center;
    flex-direction: column;
      
    .container_page{
        width: 90%;

        .section_list{
            display: grid;
            grid-template-columns: 1fr;
            height: 75vh;
            margin-top: 1vw;
        }
        .container_buttons{
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 0.5fr 0.8fr;
            justify-content: flex-end;
            align-items: center;
            flex-direction: row;

            .toogle_container_buttons{
                width: 100%;
                display: grid;
                grid-column-start: 3;
                grid-template-columns: 1fr 1fr;

                .normal_button-asignar{
                    padding: 0.5vw;
                    border: none;
                    background: #707070;
                    color: #ffff;
                    cursor: pointer;
                    outline: none;
                }
                .btn_left-asignar{
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                }
                .btn_rigth-asignar{
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                }
                .btn_active-asignar{
                    background: #37a7b9;
                    background: linear-gradient(270deg, #1abc9c 0%, #094070 91%);
                }
            }
        }
    }
}
.section{
    padding-top: 70vh;
}