%flexbox
    display: flex
    justify-content: center
    align-items: center

.container-factoryConfig
    @extend %flexbox
    height: 88vh
    padding-top: 10vh
    padding-bottom: 2vh
    .container-factoryConfig-structure
        width: 95%
        display: grid
        padding-top: 40px
        grid-template-areas: 'factoryConfig-container-items factoryConfig-container-construct-config'
        grid-template-columns: .8fr 1fr
        grid-gap: 10px
        align-items: center
        .factoryConfig-container-select-items
            width: 100%
            grid-area: 'factoryConfig-container-items'
            display: grid
            grid-template-areas: ' factoryConfig-container-system factoryConfig-container-config '
            grid-template-columns: 1fr 1fr
            grid-gap: 10px
            .factoryConfig-container-system
                width: 100%
                grid-area: ' factoryConfig-container-system '
            .factoryConfig-container-config
                width: 100%
                grid-area: 'factoryConfig-container-config '
        .factoryConfig-container-construct-config
            width: 100%
            margin-left: 10px
            grid-area: 'factoryConfig-container-construct-config'
            grid-template-areas: 'factoryConfig-construct-container-config-card' 'factoryConfig-container-tabs'
            .factoryConfig-construct-container-config-card
                width: 100%
                grid-area: 'factoryConfig-construct-container-config-card'
            .factoryConfig-container-tabs
                width: 100%
                @extend %flexbox
                grid-area: 'factoryConfig-container-tabs'
                