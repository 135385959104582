.MessageTransfered_finish {
    width: 100%;
    height: 85vh ;
    padding-top: 15vh ;
    position: relative;
    background: rgb(9,64,112);
    background: linear-gradient(152deg, rgba(9,64,112,1) 0%, rgba(56,168,185,1) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow-y: auto;
    * {
        font-family: Poppins;
    }
    .MessageTransfered_finish_general_c {
        width: 100%;
        height: 78vh;
        overflow: auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        .MessageTransfered-images {
            display: flex;
            position: relative;
            justify-content: center;
            .MessageTransfered-success-img {
                width: 20vw;
                margin-bottom: 2vw;
            }
            .MessageTransfered-success-icon {
                width: 3vw;
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
            }
        }
        .MessageTransfered_finish_title {
            font-size: 3vw;
            font-weight: 500;
            color: #ffffff;
            margin-bottom: 1.5rem;
        }
        .MessageTransfered_finish-subtitle {
            font-size: 3vw;
            color: #ffffff;
            margin-bottom: 3vw;
        
        }
        .MessageTransfered-number {
            font-size: 3vw;
            font-weight: 500;
            color: #ffffff;
            margin-bottom: 2vw;
        }
        .MessageTransfered_order_buttons {
            display: flex;
            justify-content: center;
            .MessageTransfered-white-button {
                font-size: 1.5vw;
                color: #094070;
                background-color: #ffffff;
                border-radius: 20px;
                cursor: pointer;
                display: flex;
                font-weight: 500;
                text-transform: uppercase;
                padding: 6px 1rem;
                margin-left: 1rem;
                min-width: 14vw;
                border: none;
                justify-content: center;
                text-transform: uppercase;
                opacity: .7;

                .txt-notification-tranfer-succes{
                    color: #094070;
                    font-family: "Poppins";
                    font-size: 1vw;
                    font-weight: 400;

                }
            }
            .MessageTransfered-transparent-button {
                font-size: 1.5vw;
                color: #ffffff;
                background-color: transparent;
                border: 1px solid #ffffff;
                border-radius: 20px;
                cursor: pointer;
                display: flex;
                font-weight: 500;
                text-transform: uppercase;
                padding: 6px 1rem;
                margin-left: 1rem;
                min-width: 14vw;
                justify-content: center;
                text-transform: uppercase;
                    opacity: .7;
            }
        }
    }
}