    .quote_general_c {
        margin: 40px auto 40px auto;
        width: 90%;
        position: relative;
        padding-top: 6vw;
        .button_elements_content {
            display: flex;
            justify-content: space-between;
            margin-top: 1rem;
            .radioButtonOptions {
                .MuiRadio-colorSecondary.Mui-checked {
                    color: #38A8B9;
                }
            }
            .showProductListButton {
                align-items: center;
                background: linear-gradient(270deg, rgba(9,64,112,1) 0%, rgba(26,188,156,1) 91%);
                border-radius: 20px;
                border: none;
                color: #fff;
                cursor: pointer;
                display: flex;
                font-size: 1.2vw;
                flex-direction: row;
                font-family: 'Poppins';
                font-weight: 300;
                text-transform: uppercase;
                padding: 8px 1rem;
                margin-left: 1rem;
                min-width: 150px;
                justify-content: center;
                font-weight: 500;

                &:focus {
                    outline: none;
                }
            }
        }
        .buttons_options {
            display: flex;
            flex-direction: row;
        }
    }
    .quotePrincipalContent {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
        * {
            font-family: Poppins;
        }
        .quoteGeneralInfo {
            width: 35%;
            max-width: 1000px;
            .element {
                display: flex;
                justify-content: space-between;
                padding: 1rem 0 8px 0;
                border-bottom: 1px solid #777777;
                p {
                    font-family: Poppins;
                    font-size: 16px;
                }
                .titleElement {
                    color: #094070;
                }
                .valueElement {
                    color: #777778;
                    text-align: end;
                }
            }
        }
        .quoteDescription1 {
            width: 100%;
            overflow-y: scroll;
            display: grid;
            grid-template-columns: 1fr 1fr;
            height: 70vh;
        }
        .quoteDescription1-c {
            width: 100%;
        }
        .buttonsContent {
            display: flex;
            justify-content: flex-end;
            margin: 4rem 3rem 2rem 0;
        }
        .quote-finished-button {
            align-items: center;
            background: linear-gradient(270deg, rgba(9,64,112,1) 0%, rgba(26,188,156,1) 91%);
            border-radius: 20px;
            border: none;
            color: #fff;
            cursor: pointer;
            display: flex;
            font-size: 14px;
            flex-direction: row;
            font-family: 'Poppins';
            font-weight: 300;
            text-transform: uppercase;
            padding: 8px 1rem;
            margin-left: 1rem;
            min-width: 150px;
            justify-content: center;
            font-weight: 500;
            outline: none;
        }
        .quote-back-invert {
            background: linear-gradient(270deg, rgba(26,188,156,1) 0%, rgba(9,64,112,1) 91%)!important;
        }
    }
    .priceContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .priceElements {
            padding: 4px 8px;
            border: 1px solid #DCDCDC;
            border-radius: 10px;
            margin: 0 3rem 8px 0;
            background: #fff;
            .element {
                display: flex;
                min-width: 300px;
                justify-content: space-between;
                align-items: center;
                p {
                    font-size: 18px;
                }
                .title {
                    color: #094070;
                    font-weight: 500;
                    padding-right: 1rem;
                    padding-top: 0 !important;
                }
                .value {
                    color: #303030;
                }
            }
            &:nth-child(2) {
                padding: 5px 5px 4px 7px;
                min-width: 305px;
                .element {
                    .title {
                        padding-left: 8px;
                    }
                }
            }
        }
    }
