.StoreHomeAvailableMaterialToAddScreen-component{
    padding-top: 20vh;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 70vh;
    font-family: 'Poppins';
    .StoreHomeAvailableMaterialToAddScreen-precomponent{
        width: 70%;
        .StoreHomeAvailableMaterialToAddScreen-component-head-items{
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-content: space-between;
            align-items: center;
            grid-gap: 2vw;
        }
    }
}