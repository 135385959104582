.c-container3 {
    display: grid;
    grid-template-rows: 1fr .4fr .1fr;
    width: 37vw;
    height: 62vh;

    .container-img {
        display: flex;
        width: 100%;
        height: 40vh;
        justify-content: center;

        img {
            display: flex;
            background: blue;
        }
    }

    .container-carrousel {

        display: flex;
        width: 100%;
        height: 18vh;
        flex-direction: row;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        overflow: auto;

        .activo {
            border: black solid 1px;

        }

        .container-img {
            display: grid;
            height: 15vh;
            width: 7vw;
            margin-right: 1vw;

            img {
                background: blue;
                align-self: center;
                justify-self: center;
                height: 14vh;
                width: 14vh;
            }
        }

    }

    .container-btn {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 7vh;
        justify-content: flex-end;
        align-items: center;

        .btn-do {
            width: 3vw;
            height: 6vh;
        }

        .btn-de {
            width: 9vw;
            height: 6vh;
        }

    }
}

.content-modal-check-circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2vw;

    .modal-img-check {
        width: 9vw;
        max-width: 10vw;

    }

    .modal-txt-check-circle {
        font-size: 2vw;
        font-family: "Poppins";
        font-weight: 350;
        color: #000;

    }

    .btn-modal-check {
        background: linear-gradient(270deg, #1abc9c 0%, #094070 100%);
        width: 15vw;
        padding: .5vw;
        border-radius: 3vw;
        color: #FFF;
        font-family: "Poppins";
        box-shadow: 4px 3.9px 3px #c4c1c1;
    }
}