.preconter_listButton::-webkit-scrollbar {
    display: none;
  }
  

.preconter_listButton{
    width: 95%;
    height: 65vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: 6vw;
    overflow-y: scroll;

    .content{
        width: 100%;
        list-style: none;
        display: grid;
        // height: 30vh;
        .list{
            width: 90%;
            margin-bottom: 3vh;

            .text_infoButton{
                width: 95%;
                display: grid;
                grid-template-columns: .1fr 1fr .1fr;
                grid-auto-rows: 0.1fr 1fr;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                border-radius: 0.5vw;
                border: .1vw solid #707070;
                font-family: "Poppins";
                font-size: 1vw;
                color: #303030;
                font-weight: 300;

            .extra_info{
                display: grid;
                padding-top: 1vh;
                padding-bottom: 1vh;
                grid-template-rows: 0.1fr 1fr;
                grid-column-start: 0;
                grid-column-end: 3;

                .content_info{
                    display: grid;
                    grid-template-columns: 1fr 0.5fr;
                    border-bottom: 0.1vh solid #777777;
                    padding-bottom: 0.3vw;
                    padding-top: 3vh;
                }

                .title-rigth{
                    text-align: initial;
                    color: #094070;
                    font-family: "Poppins";
                    font-weight: 300;
                    font-size: 1vw;

                }
                .title-left{
                    width: 100%;
                    text-align: end;
                    color: #777778;
                    font-family: "Poppins";
                    font-weight: 300;
                    font-size: 1vw;
                
                }

            }

            .content_info3{
                display: grid;
                grid-template-columns: 1fr 0.5fr;
                border-bottom: 0.1vh solid #777777;
                padding-bottom: 2vh;
                padding-top: 2vh;
            }

            .title-rigth3{
                text-align: initial;
                color: #094070;
                font-family: "Poppins";
                font-weight: 300;
                font-size: 1vw;

            }
            .title-left3{
                width: 100%;
                text-align: end;
                color: #777778;
                font-family: "Poppins";
                font-weight: 300;
                font-size: 1vw;
            
            }

        }

            .content_info4{
                display: grid;
                grid-template-columns: 1fr 0.5fr;
                padding-bottom: 2vh;
                padding-top: 2vh;
            }

            .title-rigth4{
                text-align: initial;
                color: #094070;
                font-family: "Poppins";
                font-weight: 300;
                font-size: 1vw;

            }
            .title-left4{
                width: 100%;
                text-align: end;
                color: #777778;
                font-family: "Poppins";
                font-weight: 300;
                font-size: 1vw;
            
            }

        }

            .content_info2{
                display: grid;
                grid-template-columns: 1fr 0.5fr;
                border-bottom: 0.1vh solid #777777;
                padding-bottom: 0.3vw;
                padding-top: 1vw;
            }

            .title-rigth2{
                text-align: initial;
                color: #094070;
                font-family: "Poppins";
                font-weight: 300;
                font-size: 1vw;

            }
            .title-left2{
                width: 100%;
                text-align: end;
                color: #777778;
                font-family: "Poppins";
                font-weight: 300;
                font-size: 1vw;
                

                .list_ubication{
                    display: block;
                    padding-bottom: 2vh;
                }
            
            }

        }
            .btn-green{
                justify-self: center;
                align-self: center;
                width: 1.2vw;
                font-size: 1vw;
                height: 1.2vw;
                background: #1ABC9C;
                border-radius: 0.8em;
                justify-content: center;
                color: white;
                margin-right: 1vw;
            }
            .info{
                align-content: flex-start;
                display: flex;
                padding: 0.6vh 0 0.6vh 0;
            }
            .storeHouseStock_elements_img_arrowButton{
                width: 0.7vw;
                height: 0.7vw;
                justify-self: center;
                align-self: center;
            }
        }
   


    /* .content_infoButton{
        width: 95%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        border: 1px solid #707070;
        border-radius: 0.5vw;
        padding: 0.5vw 0.9vw;
        margin-bottom: 1vw;
        color: #303030;
        cursor: pointer;
        outline: none;
 */
   /*      .info{
         width: 95%;
         display: flex;
         flex-direction: column;
        }

        .text_infoButton{
            width: 95%;
            display: flex;
            flex-direction: column;
            

            .info_date_numberPqxButton{
                display: flex;
                align-items: center;
                flex-direction: row;
                font-family: "Poppins";
                //margin-bottom: 0.5vw;
                p{
                    font-weight: 300;
                    font-size: 1vw;
                    margin-right: 0.8vw;
                }
                
            } */
        //}
        
    
   // }
