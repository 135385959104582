.c-navbar-c-icoLogo
    width: 100%
    margin-left: 75px
    grid-area: c-navbar-c-icoLogo
    position: relative
    display: flex
    align-items: center
    justify-content: center
    .c-navbar-icoLogo
        width: 6vw
        padding-left: 12vw
