.swal2-popup
    font-family: 'Poppins'
.c-extra-data-container
    padding-top: 20vh
    padding-bottom: 10vh
    .c-extra-data
        margin: 0 auto
        overflow: scroll
        width: 60%
        height: 70vh
        display: flex
        flex-direction: column
        .c-extra-button
            margin-bottom: 1.5vw
            font-size: 1.3vw
            padding: 8px 40px
            text-align: right
            border-radius: 8px
            border: 1px
            justify-content: space-between
            display: flex
            outline: none
            color: #fff
            font-family: 'Poppins'
            cursor: pointer
            align-items: center
            .extra-button-info-left
                display: flex
                align-items: center
                justify-items: center
                .extra-button-icons-left
                    width: 1.6vw
                    margin-right: 10px
            .extra-button-icons
                width: 2.4vw
            .extra-button-information
                font-size: 1.3vw
                font-family: 'Poppins'
        .error
            background: #e60000 !important
        .c-extra-button:nth-child(2n+1)
            background: linear-gradient(90deg, rgba(54,179,152,1) 0%, rgba(56,168,185,1) 100%)
        .c-extra-button:nth-child(2n)
            background: linear-gradient(270deg, rgba(54,179,152,1) 0%, rgba(56,168,185,1) 100%) 
        .c-extra-button:nth-child(3n+3)
            background: linear-gradient(90deg, rgba(15,65,111,1) 0%, rgba(56,168,185,1) 100%)
        .c-extra-button:nth-child(3n+4)
            background: linear-gradient(270deg, rgba(15,65,111,1) 0%, rgba(56,168,185,1) 100%)