.searchBas {
    width: 45%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 40px;
    margin-bottom: 0vh;
    .search-im {
      padding: 5px 6px 0px 8px;
      height: 22px;
      margin-bottom: 5vh;
        img {
            width: 40px;
            height: 40px;
        }
    }
    /* .search-input {
        border: none;
        height: 15px;
        padding: 6px;
        width: 100%;
        border-radius: 0;
       // background: #E1E2E3;
        outline: none;
        color: #888888;
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 2vh;
    } */
    ::-webkit-input-placeholder { /* Chrome */
        color: #C2C1C1;
      }
      :-ms-input-placeholder { /* IE 10+ */
        color: #C2C1C1;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        color: #C2C1C1;
        opacity: 1;
      }
      :-moz-placeholder { /* Firefox 4 - 18 */
        color: #C2C1C1;
        opacity: 1;
      }
      .SearchInput-search-input-combobo{
        border: none;
        height: 4.75vh;
        width: 90%;
        padding: 0 1vw;
        border-radius: 1vw 1vw 1vw 1vw;
        background: #E1E2E3;
        outline: none;
        color: #888888;
        font-family: "Poppins";
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 2vh;
        margin-right: 2vw;

      }
}
