.calendar_base_content {
    width: 100%;
    font-family: "Poppins";
    .dateSelected_content_standar {
        background-color: #37A7B9;
    }
    .dateSelected_content_ATQ {
        background-color: #1ABC9C;
    }
    .dateSelected_content {
        width: calc( 100% - 2rem);
        padding: 8px 1rem;
        
        span {
            font-family: "Poppins";
            font-size: 16px;
            color: #ffffff;
        }
    }
    .react-calendar {
        width: 100%;
        font-family: 'Poppins';
        border-color: transparent;
        .react-calendar__tile--active {
            abbr {
                color: #ffffff!important;
                border-color: #094070;
                background-color: #094070;
                border-radius: 50%;
                padding: 2px 4px;
            }
        }
        .react-calendar__navigation {
            margin-top: 10px;
            margin-bottom: 12px;
            button {
                font-size: 40px;
            }
        }
        .calendar__navigation__label {
            .calendar__navigation__label__labelText  {
                font-size: 18px;
                color: #00325D;
            }
        }
        button {
            color: #2A99AB;
            outline: none;
        }
        button {
            background-color: transparent;
            border-width: 0;
            min-width: 10px;
            font-size: 22px;
            span {
                font-size: 28px;
                color: #00325D;
                font-weight: 500;
                text-transform: capitalize;
            }
            &:focus, &:hover {
                background-color: transparent;
            }
        }
        .react-calendar__navigation {
            margin-bottom: 0;
        }
        .react-calendar__viewContainer {
            .react-calendar__month-view__weekdays {
                margin: 1rem 0 0 0;
                border-top: 1px solid rgba(112,112,112,0.5);
                border-bottom: 1px solid rgba(112,112,112,0.5);
                .react-calendar__month-view__weekdays__weekday {
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                }
                abbr {
                    color: #707070;
                    font-weight: 500;
                    text-transform: capitalize;
                    text-decoration: none;
                    font-size: 20px;
                    padding: 8px 0;
                }
            }
            .react-calendar__month-view__days {
                background-color: #F2F2F2;
                .react-calendar__month-view__days__day {
                    padding: 12px 0;
                }
                button {
                    abbr {
                        color: #303030;
                        font-weight: 500;
                        min-width: 18px;
                    }
                    &:focus, &:hover {
                        outline: none;
                        abbr {
                            color: #ffffff;
                            border-color: #094070;
                            background-color: #094070;
                            border-radius: 50%;
                            padding: 2px 4px;
                        }
                    }
                }
                .react-calendar__month-view__days__day--neighboringMonth {
                    abbr {
                        color: #CCCCCC;
                        font-weight: 500;
                    }
                }
            }
        }
        abbr {
            font-size: 20px;
        }
    }
}