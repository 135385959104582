.section-general-analisis {
    width: 70%;
    margin-bottom: 12vh;
    font-family: "Poppins";


    .header-title-analisis {
        padding: 2vh 2vw;

        .header-text-title-analisis {
            color: #094070;
            font-weight: 400;
            font-size: 1vw;
            font-family: "Poppins";
        }
    }

    .section-location-analisis {
        padding-left: 2vw;

        .text-item-analisis {
            margin-bottom: 1vh;
            color: #303030;
            font-weight: 200;
            font-size: 1vw;
            font-family: "Poppins";
        }
    }
}
