.container-mantenimiento-detalles {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: start;
    padding-top: 16vh;

    .container-mantenimiento-encuestas-colums-detalles {
        width: 90%;
        display: grid;
        grid-template-columns: 0.8fr 1fr;
        justify-content: start;
        align-items: start;
        grid-gap: 7vw;
        margin-top: 5vh;

        .container-mantenimiento-sec-left {
            .btn-consumo-mantenimiento {
                font-family: "Poppins";
                background: linear-gradient(93deg, #1abc9c 10%, #094070 100%);
                color: #fff;
                width: 25%;
                padding: 0.4vw;
                cursor: pointer;
                border: none;
                border-radius: 2vw;
                margin-right: 7vw;
                box-shadow: 4px 3.9px 3px #c4c1c1;
            }
        }

        .container-mantenimiento-sec-rigth {
            display: flex;
            flex-direction: column;
            width: 95%;

            .container-medico-mantenimiento {
                height: 68vh;
                overflow: overlay;
                padding-left: 4px;

                .text-medico-header-mantenimiento {
                    margin-top: 3vh;
                    color: #094070;
                    font-family: "Poppins";
                    font-weight: 300;
                    text-align: center;
                    margin-bottom: 3vh;
                }
                .btn-detalles-encuesta-mantenimiento {
                    font-family: "Poppins";
                    background: linear-gradient(93deg, #1abc9c 10%, #094070 100%);
                    color: #fff;
                    width: 25%;
                    padding: 0.4vw;
                    cursor: pointer;
                    border: none;
                    border-radius: 2vw;
                    justify-content: end;
                    box-shadow: 4px 3.9px 3px #c4c1c1;
                    margin-top: 32%;
                    margin-left: 70%;
                }
            }
        }

        .container-mantenimiento-sec-rigth {
            display: flex;
            flex-direction: column;
            width: 95%;

            .container-medico-mantenimiento {
                height: 68vh;
                overflow: overlay;
                padding-left: 4px;

                .text-medico-header-mantenimiento {
                    margin-top: 3vh;
                    color: #094070;
                    font-family: "Poppins";
                    font-weight: 300;
                    text-align: center;
                    margin-bottom: 3vh;
                }
                .container-atq-buttons-add{
                    width: 95%;
                    display: flex;
                    justify-content: flex-end;
                    grid-template-rows: 1fr 1fr;
                    .icoAdd{
                        cursor: pointer;
                        width: 2.5vw;
                        align-self: end;
                        justify-self: end;
                        margin-top: -15px;

                    }

                }
               
            }

            .btn-detalles-encuesta-mantenimiento {
                font-family: "Poppins";
                background: linear-gradient(93deg, #1abc9c 10%, #094070 100%);
                color: #fff;
                width: 25%;
                padding: 0.4vw;
                cursor: pointer;
                border: none;
                border-radius: 2vw;
                justify-content: end;
                box-shadow: 4px 3.9px 3px #c4c1c1;
                margin-top: -5%;
                margin-left: 70%;
            }
        }
    }
}
