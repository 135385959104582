%stylesToButton
    cursor: pointer
    outline: none
    border: none

.section-2-atq
    grid-template-columns: .7fr .7fr .7fr
    grid-template-areas: 'atq-encuesta atq-agenda atq-agenda' 'atq-transferir atq-transferir atq-rol-guardias'
    .atq-item-simpleform
        display: flex
        justify-content: center
        flex-direction: column
        align-items: center
        img
            width: 7vw
    .atq-item-horizontalform
        display: flex
        flex-direction: row
        justify-content: space-around
        align-items: center
        img
            width: 9vw
    .atq-item
        border-radius: 10px
        color: #fff
        cursor: pointer
        text-align: center
        align-self: stretch
        justify-self: stretch
        @extend %stylesToButton
        .atq-item-title
            font-size: 1.5vw
            font-family: 'Poppins'
            font-weight: 300
    .atq-encuesta
        grid-area: atq-encuesta
        background: linear-gradient(270deg, rgba(26,188,156,1) 0%, rgba(9,64,112,1) 91%)
       
    .atq-transferir
        flex-direction: row-reverse
        grid-area: atq-transferir
        background: linear-gradient(270deg, rgba(55,167,185,1) 0%, rgba(26,188,156,1) 100%, rgba(55,167,185,1) 094070%)
    .atq-agenda
        grid-area: atq-agenda
        background: linear-gradient(93deg, rgba(26,188,156,1) 10%, rgba(9,64,112,1) 100%)
        // .atq-item-title
        //     font-size: 1.5vw
        // .c-home-c-informacion-title
        //     width: 40%
        //     font-size: 1.5vw
        //     margin-right: 20px
    .atq-rol-guardias
        grid-area: atq-rol-guardias
        background: linear-gradient(270deg, rgba(55,167,185,1) 0%, rgba(26,188,156,1) 100%, rgba(55,167,185,1) 094070%)