.container_storeHouseStock {
    height: 80vh;
    padding-top: 20vh;
    font-family: "Poppins";
    display: flex;
    align-items: center;
    flex-direction: column;
    .storeHouseStock_container {
        width: 90%;
        .storeHouseStock_container_toggle {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 0.5fr 0.8fr;
            justify-content: flex-end;
            align-items: center;
            flex-direction: row;
            // .storeHouseStock_toggle_container-navbar-c-search{
            //     grid-column-start: 2;
            //     .storeHouseStock_toggle_container-navbar-c-search-img{

            //         .storeHouseStock_toggle_container-navbar-search-img{

            //         }
            //     }
            //     .storeHouseStock_toggle_container-navbar-search-input{

            //     }
            // }
            .storeHouseStock_toggle_container_buttons {
                width: 100%;
                display: grid;
                grid-column-start: 3;
                grid-template-columns: 1fr 1fr;
            }
            .storeHouseStock_toggle_button {
                padding: 0.5vw;
                border: none;
                background: #707070;
                color: #ffff;
                cursor: pointer;
                outline: none;
            }
            .storeHouseStock_toggle_button_active {
                background: rgb(55, 167, 185);
                background: linear-gradient(152deg, rgba(55, 167, 185, 1) 0%, rgba(9, 64, 112, 1) 100%);
            }
            .storeHouseStock_toggle_button_left {
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                // box-shadow: -2px ​0px 4px 0px#bebebe;
            }
            .storeHouseStock_toggle_button_rigth {
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
            }
        }
        .storeHouseStock_container_elements {
            display: grid;
            grid-template-columns: 0.5fr 1fr;
            height: 75vh;
            
        }
    }
}
