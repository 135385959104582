.c-sidebar-close-session
    width: 100%
    color: #fff
    background: #000
    height: 80px
    bottom: 0px
    border: none
    cursor: pointer
    outline: none
    position: absolute
    &:hover
        background: #131313
        
    