.approve_unapprove_modal {
    max-width: 530px;
    font-family: 'Poppins';
    margin: 0 auto;
    .a_ua_modalTitle {
        font-size: 25px;
        color: #777777;
        font-weight: 400;
        margin-top: 1.5rem;
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;
    }
    .approve_unapprove_button {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1.5rem 0 1rem 0;
    }
}