
.TableUbication-precontainer{
    position: relative;
    width: 100%;
    padding: 1.8vw 0;
    .TableUbication-container{
        width: 100%;

        .TableUbication-table-container{   
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 1px 6px -1px rgb(0 0 0 / 35%);
        border-collapse: collapse;
        display: inline-block;
        box-sizing: border-box;

            .TableUbication-table-thead{
                width: 100%;
                display: flex;

                 .TableUbication-table-row{
                    width: 100%;
                    display: flex;
                
                     .TableUbication-table-title:nth-child(1){
                        text-align: start;
                        width: 33%;
                        border-bottom: 1px solid #c2c2c2;
                        font-weight: 300;
                        font-size: 1.2vw;
                        color: #094070;
                        font-family: "Poppins";
                        font-weight: 300;
                        padding: 10px 0 10px 1vw;
                        border-spacing: 0;
                        text-align: start;
                    }
                     .TableUbication-table-title:nth-child(2){
                        text-align: start;
                        width: 33%;
                        border-bottom: 1px solid #c2c2c2;
                        font-weight: 300;
                        font-size: 1.2vw;
                        color: #094070;
                        font-family: "Poppins";
                        font-weight: 300;
                        padding: 10px 0 10px 1vw;
                        border-spacing: 0;
                        text-align: center;
                    }
                     .TableUbication-table-title:nth-child(3){
                        text-align: start;
                        width: 33%;
                        border-bottom: 1px solid #c2c2c2;
                        font-weight: 300;
                        font-size: 1.2vw;
                        color: #094070;
                        font-family: "Poppins";
                        font-weight: 300;
                        padding: 10px 0 10px 1vw;
                        border-spacing: 0;
                        text-align: center;
                    }
                }
            } 
            .TableUbication-table-body{
                max-height: 37vh;
                width: 100%;
                overflow: auto;
                display: block;
            
                .TableUbication-table-body-row{
                    display: flex;
                    width: 100%;
                
                 .TableUbication-table-data:nth-child(1){
                    text-align: start;
                    width: 33%;
                    padding: 10px 0 4vh 1vw;
                    color: #9c9c9c;
                    font-family: "Poppins";
                    font-size: 0.9vw;
                    font-weight: 300;
                    display: inline-block;
                }
                 .TableUbication-table-data:nth-child(2){
                    text-align: center;
                    width: 33%;
                    padding: 10px 0 4vh 0;
                    color: #9c9c9c;
                    font-family: "Poppins";
                    font-size: 0.9vw;
                    font-weight: 300;
                    display:inline-block ;
                    }
                 .TableUbication-table-data:nth-child(3){
                    text-align: center;
                    width: 33%;
                    padding: 10px 0 4vh 0;
                    color: #9c9c9c;
                    font-family: "Poppins";
                    font-size: 0.9vw;
                    font-weight: 300;
                    display:inline-block ;
                    }
                }
            }
            
        }

    }
}

table{
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: grey;
}
div{
    display: block;
}


thead{
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}
