.precontainer-almacen{
    width: 100%;
    height: 85vh ;
    padding-top: 15vh ;
    position: relative;
    background: rgb(9,64,112);
    background: linear-gradient(152deg, rgba(9,64,112,1) 0%, rgba(56,168,185,1) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow-y: auto;
    font-family: "Poppins";

    .container_general_almace{
        width: 100%;
        height: 78vh;
        overflow: auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        
        .container-almacen{
            display: flex;
            position: relative;
            justify-content: center;
           .img-encuesta-almacen{
            width: 20vw;
    
           }
        }

        .title_encuesta_almacen{
            font-size: 2.5vw;
            justify-content: center;
            margin-top: 7vh;
            color: #ffffff;
            margin-bottom: 2vw;
               
        }
    
        .numberCx_encuestas_almacen{
            font-size: 3vw;
            color: #ffffff;
            margin-bottom: 2vw;
    
        }
    
        .Cont_button_encuestas_almacen{
            display: flex;
                justify-content: center;
    
            .button-almacen{
                font-size: 1.5vw;
                    color: #094070;
                    background-color: #ffffff;
                    border-radius: 20px;
                    cursor: pointer;
                    display: flex;
                    text-transform: uppercase;
                    padding: 6px 1rem;
                    min-width: 14vw;
                    border: none;
                    justify-content: center;
                    text-transform: uppercase;
                    box-shadow: 3px 4px #414040;
                    opacity: .7;
    
            }
    
        }


    } 
}