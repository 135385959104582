.ATQ_GuardDays_View_container{
    width: 100%;
    height: 80vh;
    padding-top: 20vh;
    font-family: "Poppins";
    display: flex;
    justify-content: center;
    
    .ATQ_GuardDays_View_Items_container{
        display: grid;
        width: 90%;
        height: 80vh;
        grid-template-rows: .8fr .1fr;
        grid-template-columns: 1fr;
        .ATQ_GuardDays_View_Calendar_container{
            width: 60%;
            justify-self: center;
        }
        .ATQ_GuardDays_View_Buttons_container{
            width: 40%;
            justify-self: end;
            display: grid;
            grid-gap: 2vw;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            .ATQ_GuardDays_View_Button{
                padding: 0.5vw;
                font-size: 0.9vw;
                font-weight: 500;
                border-radius: 2vw;
                outline: none;
                cursor: pointer;
                border: none;
                font-family: "Poppins";
                color: #fff;
                box-shadow: 4px 3.9px 3px #c4c1c1; 

            }
            .ATQ_GuardDays_View_Button:nth-child(1){
                background: linear-gradient(270deg, rgba(26,188,156,1) 0%, rgba(9,64,112,1) 100%);
            }
            .ATQ_GuardDays_View_Button:nth-child(2){
                background: linear-gradient(90deg, rgba(26,188,156,1) 0%, rgba(9,64,112,1) 100%);
            }


        }
    }
}