.container-component-comments-FechaCierre {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 7px;
    -webkit-box-shadow: -1px 5px 19px 0px #efecef;
    -moz-box-shadow: -1px 5px 19px 0px #efecef;
    box-shadow: -1px 5px 19px 0px #efecef;
    margin-bottom: 3vh;

    .header-commentsFechaCierre {
        width: 100%;

        .header-titleFechaCierre {
            border-bottom: 1px solid #c2c2c2;
            padding-bottom: 0.5vh;

            .title-componentFechaCierre {
                color: #37a7b9;
                font-family: "Poppins";
                font-size: 1.2vw;
                font-weight: 500;
                display: flex;
                justify-content: center;
                padding-top: 1vh;
            }
        }
    }

    .body-FechaCierre {
        width: auto;
        display: flex;
        flex-direction: column;
        margin: 0px 10px 15px 10px;

        .container-FechaCierre {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .container-text-FechaCierre {
               
                width: 65%;
                display: flex;
                .text_FechaCierre {
                    color: #000;
                    font-family: "Poppins";
                    font-size: 1vw;
                    font-weight: 500;
                    margin-top: 20px;
                    margin-left: 4.2vw;
                }
            }

            .selects-FechaCierre {
                display: flex;
                width: 100%;
                justify-content: center;

                .calendar_calidad{
                    width: 19vw;
                    margin-top: 1vh;
                    margin-bottom: 2vh;
                    /* border-color: #c2c2c2; */
                    border: solid 1px #c2c2c2;
                    color: #303030;
                    font-family: "Poppins";
                    font-size: 0.9vw;
                    padding-bottom: 0.5vh;
                    font-weight: 300;
                    padding-top: 0.5vh;
                    cursor: pointer;
                }

            }
        }
    }
}
