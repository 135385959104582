.container_printListPricesModal{
    width: 90%;
    font-family: 'Poppins';
    border: 1px solid rgb(211, 211, 211);
    padding: 1vw;
    border-radius: .5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .printListPricesModal_container_title{
        
        .printListPricesModal_title{
            margin-top: 0;
            font-weight: 400;
            color: rgb(90, 90, 90);
        }
    }
    .printListPricesModal_container_form{
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        .printListPricesModal_title_element{
            font-weight: 300;
            color: rgb(88, 88, 88);
        }
    }
}