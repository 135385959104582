.c-product
    .c-product-page
        padding-top: 11vw
        width: 90%
        margin: auto
        display: grid
        grid-template-columns: minmax(200px, 260px) 1fr
        grid-template-areas: 'c-product-c-info product-c-items'
        grid-gap: 40px
    .product-c-info
        display: grid
        grid-template-areas: 'product-info-c-name' 'c-product-c-items'
        grid-area: c-product-c-info
        .product-info-c-name
            width: 100%
            min-height: 40px
            height: 10vh
            background: linear-gradient(270deg, rgba(9,64,112,1) 0%, rgba(55,167,185,1) 91%)
            display: flex
            flex-direction: row
            text-align: right
            justify-content: space-around
            align-items: center
            font-family: 'Poppins'
            border-radius: 15px
            .product-info-name
                color: #fff
                width: 85%
                padding-right: 10px
            .product-info-img
                width: 15%
                padding-left: 10px
        .c-product-c-items
            width: 90%
            height: 220px
            justify-self: center
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            border-radius: 32px
            background: #ffffff
            box-shadow:  12px 12px 35px #969696, -12px -12px 35px #ffffff
            .product-item-img
                margin-top: 15px
                width: 7vw
            .product-item-name
                text-align: center
                font-family: 'Poppins'
                font-weight: 400
    .product-c-items
        display: grid
        grid-template-columns: repeat(3, 1fr)
        grid-auto-flow: dense
        grid-template-rows: 200px 200px
        grid-gap: 30px
        height: 70vh
        // overflow: scroll
        .product-c-item
            width: 220px
            height: 200px
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            position: relative
            justify-self: center
            align-self: center
            border-radius: 18px
            background: #ffffff
            border: 3px solid #DCDCDC
            .product-item-c-name
                width: 90%
                text-align: center
                background: none
                border: none
                cursor: pointer
                outline: none
                font-family: 'Poppins' serif
                font-size: 18px
                font-weight: 300
                .product-item-name
                    font-family: 'Poppins'
                    font-weight: 300
            .product-item-c-img
                width: 100%
                border: none
                outline: none
                display: flex
                justify-content: center
                align-items: center
                position: absolute
                bottom: 0
                padding: 5px 0
                border-radius: 0 0 13px 13px
                background: linear-gradient(270deg, rgba(9,64,112,1) 0%, rgba(55,167,185,1) 91%)
                cursor: pointer
                .product-item-img
                    color: #fff
                    font-size: 18px
            .product-item-c-infoitem
                width: 100%
                background: linear-gradient(270deg, rgba(9,64,112,1) 0%, rgba(55,167,185,1) 91%)
                overflow-y: scroll
                border-radius: 13px
                color: #ececec
                padding: 10px 0
                display: flex
                flex-direction: column
                align-items: center
                height: 220px
                .product-item-c-infoitem-item
                    width: 92%
                    padding-bottom: 10px
                    cursor: pointer
                    background: none
                    color: #fff
                    border: none
                    text-align: initial
                    outline: none
                    .product-infoitem-item-name
                        font-size: 12px
                        font-family: 'Poppins'
                        margin-bottom: 3px
                    .product-infoitem-item-desc
                        font-size: 12px
                        font-family: 'Poppins'
            
