.c-StoreHouseAssignSystemTableFormModal{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: 'Poppins';
    .c-StoreHouseAssignSystemTableFormModal-container-title{
        width: 100%;
        border-bottom: .1vw solid #777;
        .c-StoreHouseAssignSystemTableFormModal-title{
            margin-bottom: 1vw;
            margin-left: 1vw;
            text-align: start;
            font-weight: 400;
            font-size: 1.5vw;
            color: rgb(104, 104, 104);
        }
    }
    .c-StoreHouseAssignSystemTableFormModal-c-list-items{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 1.5vw 0;
        .c-typeLocation-c-item{
            margin: .5vw 0;
            width: 95%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .c-typeLocation-item-name{
                font-size: 1.3vw;
                color: rgb(78, 78, 78);
                font-weight: 300;
            }
            .c-typeLocation-item-check{
                width: 1.4vw;
                height: 1.4vw;
            }
        }
        .c-StoreHouseAssignSystemTableFormModal-item-note{
            width: 95%;
            margin-top: 1.5vw;
            border: .15vw solid rgb(133, 133, 133, .6);
            border-radius: .5vw;
            padding: .4vw;
            font-weight: 350;
            font-size: 1vw;
            outline: none;
            color: #777;
        }
        .c-StoreHouseAssignSystemTableFormModal-button{
            margin-top: 1.5vw;
            width: 50%;
            padding: .3vw;
            border-radius: 1vw;
            border: none;
            background: linear-gradient(275deg, rgba(9,64,112,1) 0%, rgba(55,167,185,1) 100%);
            color: #fff;
            outline: none;
            cursor: pointer;
            box-shadow: 2px 3px 4px -0.9px #777;
        }
    }
}