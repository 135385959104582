.container-add-members-modal-chat {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .subcontainer-add-memebers-modal-chat{
        width: 100%;
        display: flex;
        background: #fff;
        position: relative;
        

    }

}