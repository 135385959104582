$gradient: linear-gradient(270deg, rgba(9,64,112,1) 0%, rgba(55,167,185,1) 91%);

.container {
    padding-top: 20vh;
    height: 80vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 95vw;

        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        color: #094070;
        font-weight: 700;
    }

    .title {
        display: block;
        align-items: center;
    }

    hr {
        width: 30%;
        text-align: start;
        height: 4px;
        background-color: #37A7B9;
        margin-left: 0;
        border: none;
    }
    
    .importBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $gradient;
        border-radius: 50px;
        padding: 10px 20px;
        font-family: 'Roboto';
        font-size: 16px;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-right: 10px;
    }

    .clientContainer {
        width: 95vw;
        margin-top: 30px;
        display: flex;
        //align-items: center;
        padding: 20px 15px;
        background-color: #E5E9F2;
        border-radius: 10px;
        position: relative;

        div {
            width: 32%;
            display: block;

            p {
                font-size: 20px;
                font-weight: 700;
                font-family: 'Montserrat', sans-serif;
                text-transform: uppercase;
                color: #484F59;
                margin-bottom: 10px;

                small {
                    text-transform: none;
                }
            }
        }
    }

    .bottomBar {
        background: #E5E9F2;
        display: flex;
        width: 100%;
        height: 82px;
        position: fixed;
        bottom: 30px;
        justify-content: end;
        align-items: center;
        padding-right: 50px;
    
        .blueBtn {
            height: 46px;
            display: flex;
            align-items: center;
            background: linear-gradient(90deg, #37A7B9 0%, #094070 100%);
            color: #FFFFFF;
            font-family: 'Inter', sans-serif;
            border-radius: 50px;
            padding: 20px;
            font-size: 16px;

            img {
                margin-right: 15px;
            }
        }
    }

    .billBtn {
        display: flex;
        align-items: center;
        background: $gradient;
        color: #FFFFFF;
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        border-radius: 50px;
        font-size: 16px;
        padding: 10px 20px;
        img {
            margin-right: 15px;
        }
    }
    
}

.tableContainer {
    margin-top: 37px;
    margin-bottom: 100px;
    width: 95vw;
}

.buttons {
    color: #fff !important;
    background: $gradient;
    border: none;
    border-radius: 20px;
    padding-top: 100px;
    padding-bottom: 100px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
}

.disableButton {
    color: #fff !important;
    background: #c3c3c3;
    border: none;
    border-radius: 20px;
    padding: 5px 30px 5px 30px;
    font-family: 'Inter', sans-serif;
    cursor: default;
}

.disableButtonIcon {
    display: flex;
    align-items: center;
    background: #c3c3c3;
    color: #FFFFFF;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    border-radius: 50px;
    font-size: 16px;
    padding: 10px 20px;
    img {
        margin-right: 15px;
    }
    cursor: default;
    margin-right: 10px;
}

.drawerContainer
{
    display: flex;
    flex-direction: column;
    margin: 20px;

    p {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        font-weight: 700;
        color: #094070;
    }

    .drawerTitle {
        font-size: 20px;
        width: 100%;
        border-bottom: 1px solid #094070;
        line-height: 0.1em;
        margin: 10px 0;
        text-align: start;
    }

    .drawerTitle span {
        background: #fff;
        padding: 0 10px 0 0;
    }

    div {
        margin-top: 20px;
    }

    small {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        color: #484F59;
    }
}
