@media screen and ( max-width: 1480px )
    .c-quote
        .quote-c-section-c-items
            grid-template-columns: repeat( 4, minmax(150px, 1fr))
    .c-quote-whit-products
        .c-items-whit-products
            grid-template-columns: repeat( 4, minmax(150px, 1fr))
@media screen and ( max-width: 950px )
    .c-quote
        .c-items
            grid-template-columns: repeat( 4, minmax(150px, 1fr))
    .c-quote-whit-products
        .c-items-whit-products
            grid-template-columns: repeat( 3, minmax(150px, 1fr))
    
@media screen and ( max-width: 750px )
    .c-quote
        .quote-c-section-c-items
            grid-template-columns: repeat( 3, minmax(150px, 1fr))
    .c-quote-whit-products
        .c-items-whit-products
            grid-template-columns: repeat( 2, minmax(150px, 1fr))