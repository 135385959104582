.c-quote
    display: grid
    grid-template-areas: 'quote-c-cervicales' 'quote-c-lumbares' 'quote-c-toraxicos' 'quote-c-complementos' 'quote-c-products'
    margin: 40px auto 40px auto
    width: 90%
    position: relative
    padding-top: 8vw
.c-quote-whit-products
    display: grid
    grid-template-areas: 'quote-c-cervicales' 'quote-c-lumbares' 'quote-c-toraxicos' 'quote-c-complementos' 'quote-c-products'
    margin: 40px 10px
    width: 60%
    position: relative
    padding-top: 8vw
    
    