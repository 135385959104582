.container-encuestas-screen{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: start;
    height: 86vh;
    padding-top: 16vh;
   

    .container-encuestas-colums{
        width: 90%;
        display: grid;
        grid-template-columns: 0.8fr 1fr;
        justify-content: start;
        align-items: start;
        grid-gap: 7vw;
        margin-top: 5vh;
        
    

        .precontainer-encuestas_survey{
            width: 100%;
            display: grid;
            grid-template-rows: 11fr .1fr;
            height: 71vh;
            
            
            .container-encuestas_survey{
                width: 100%;
                padding-left: 1vw;
                height: 92%;
                overflow: auto;
                margin-bottom: 10px;


                .container-bttns{
                    width: 95%;
                    display: flex;
                    justify-content: flex-end;

                    .btn-encuestas {
                        padding: 8px 30px;
                        color: #ffff;
                        background: #707070;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                    }
                    
                }
                
               
                
            }

            .individual-container-atq_New{
                display: flex;
                justify-content: center;
            }
            .container-atq-buttons-add{
                width: 95%;
                display: flex;
                justify-content: flex-end;
                grid-template-rows: 1fr 1fr;
                .icoAdd{
                    border-style: none;
                    cursor: pointer;
                    width: 2.5vw;
                    align-self: end;
                    justify-self: end;
                    margin-top: -15px;
                    
                  
                
                }
            }

        }
    }
}
.container-btn{
    width: 95%;
    display: flex;
    justify-content: flex-end;
    
    .btn-Aceptar{
        width: 20%;
        padding: 7px;
        font-size: 0.9vw;
        text-align: center;
        color: #FFFFFF;
        font-family: "Poppins";
        background: linear-gradient(90deg, #094070 0%, #1abc9c 100%);
        border-radius: 20px;
        cursor: pointer;
        //margin-top:2px;
            
    }
}