.container-menssage-received-component {
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .user-name-chat-conversation-received{
        font-size: .6vw;
        font-family: "Poppins";
        margin-left: 0.7vw;
        color: #000;
        font-weight: 500;

    }

    .subcontainer-messagge-received-component {
        width: 100%;
        background: #1ABC9C;
        margin: 0 0 1vw 0.5vw;
        border-top-left-radius: 15px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;


        .contents-msg-title {
            font-size: .8vw;
            color: #FFF;
            font-family: "Poppins";
            font-weight: 400;
            padding: .4vw;

        }

        .img-send-chat-received {
            width: 90%;
            padding: 0 .5vw
    
        }
    

        .contents-msg-hour-left {
            font-size: .6vw;
            color: #FFF;
            font-family: "Poppins";
            font-weight: 500;
           padding-left: .4vw;
           padding-bottom: .2vw;
            display: flex;
            justify-content: left;

        }

    }

}