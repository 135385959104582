.c-sidebar-btn-close
    background: none
    border: none
    cursor: pointer
    left: 40px
    outline: none
    position: relative
    top: 20px
    width: 30px
    .c-sidebar-btn-close-img
        width: 20px
.c-sidebar-list
    display: flex
    flex-direction: column
    margin-top: 50px
    width: 80%
    margin-bottom: 50px
    .c-sidebar-item
        align-items: center
        border-bottom: 2px solid #777777
        color: #fff
        cursor: pointer
        display: flex
        font-family: 'Roboto', sans-serif
        font-size: 16px
        font-weight: 300
        justify-content: space-between
        list-style: none
        margin-top: 5px
        padding-bottom: 10px
        transition: .3s
        &:hover
            color: rgba(#9ad0ff, .5 )
            transition: .3s
            border-bottom: 2px solid #a7a7a7
        .c-sidebar-item-icon
            width: 24px
        .c-sidebar-item-c-name
            margin-left: 15px
            .c-sidebar-item-name
                margin-left: 15px
        .c-sidebar-item-c-arrow
            margin-right: 15px