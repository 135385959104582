
    .codes-inputs {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-top: 1rem;
        .modal-text-input, .modal-select {
            width: 50%;
            margin-bottom: 1rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .modal-text-input-title, .modal-text-select-title {
                font-size: 15px;
                color: #00325D;
                font-weight: 500;
            }
            .modal-text-input-input, .form-selects {
                border-color: transparent;
                border-bottom: 1px solid #2A99AB;
                width: 90%;
                height: 25px;
                font-size: 15px;
                color: #777777;
                font-weight: 400;
                &:focus-visible {
                    border-color: transparent;
                    border-bottom: 1px solid #2A99AB;
                }
            }
            .form-selects {
                margin-top: 3px;
            }
        }
        input:focus {
            outline:none !important;
            outline-width: 0 !important;
            box-shadow: none;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
        }
    }