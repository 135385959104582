.StoreHomeAbailableSystemsItems-c-section
    display: grid
    grid-template-rows: 70px 1fr
    margin: 30px 0
    .StoreHomeAbailableSystemsItems-c-section-c-title
        margin-bottom: 20px
        width: 300px
        border-radius: 30px
        display: flex
        height: 30px
        flex-direction: row
        justify-content: space-around
        align-items: center
        .StoreHomeAbailableSystemsItems-c-section-title
            font-family: 'Poppins'
            font-weight: 300
            font-size: 16px
            text-align: center
            color: #fff
        .StoreHomeAbailableSystemsItems-c-section-img
            width: 23px
.c-items
    grid-template-columns: repeat( 6, minmax(150px, 1fr))
.c-items-whit-products
    grid-template-columns: repeat( 5, minmax(150px, 1fr))
.StoreHomeAbailableSystemsItems-c-section-c-items
    display: grid
    grid-gap: 30px
    width: 100%
    
    .StoreHomeAbailableSystemsItems-c-item
        align-items: center
        border-radius: 10px
        box-shadow: 0px -1px 7px -2px
        display: flex
        flex-direction: column
        justify-content: center
        width: 100%
        position: relative

        .second-Component
                background: linear-gradient(270deg, #1abc9c 0%, #094070 91%)
                width: 100%
                height: 100%
                position: absolute
                z-index: 9
                opacity: 0.8
                border-radius: 10px
                display: grid
                grid-template-rows: 1fr .4fr
                .ATQ-Ico
                    color: #fff
                    font-size: 18px
                    justify-self: center
                    align-self: center
                .StoreHomeAbailableSystemsItems-item-button-name
                    margin-left: 10px
                    font-size: 1.1vw
                    text-align: center
                    font-weight: 500
                    color: #fff
                    cursor: pointer
                    align-self: center
                    font-family: "Poppins"
                    font-weight: 300

        .StoreHomeAbailableSystemsItems-c-item-img
            padding: 10px 0 50px 0
            width: 80px
            min-height: 4vw
        .ATQ-button   
            /* align-self: center */
            border-radius: 0px 0px 10px 10px
            background: linear-gradient(270deg, #1abc9c 0%, #094070 91%)
            border: none
            color: #fff
            cursor: pointer
            display: grid
            grid-template-rows: .1fr 1fr
            /* flex-direction: row */
            font-family: "Poppins"
            font-weight: 300
            /* justify-content: space-between */
            padding: 5px 0px
            text-align: left
            outline: none
            bottom: 0
            position: absolute
            width: 100%

            .ATQ-Ico
                font-size: 18px
                font-family: "Poppins"
                font-weight: 300
                justify-self: center
            .StoreHomeAbailableSystemsItems-item-button-name-container
                display: flex
                justify-content: center
                align-items: center
                flex-direction: column 
                width: 100%
                .StoreHomeAbailableSystemsItems-item-button-name
                    margin-left: 10px
                    font-size: 1.1vw
                    text-align: center
                    font-weight: 300
                .StoreHomeAbailableSystemsItems-item-button-secondname
                    margin-left: 10px
                    text-align: start

        .StoreHomeAbailableSystemsItems-c-item-button
            align-items: center
            border-radius: 0px 0px 10px 10px
            background: linear-gradient(275deg, rgba(55,167,185,1) 0%, rgba(9,64,112,1) 100%)
            border: none
            color: #fff
            cursor: pointer
            display: flex
            font-size: 14px
            flex-direction: row
            font-family: 'Poppins'
            font-weight: 300
            justify-content: space-between
            padding: 5px 0px
            text-align: left
            outline: none
            bottom: 0
            position: absolute
            width: 100%
            .StoreHomeAbailableSystemsItems-item-button-ico
                font-size: 18px
                margin-right: 10px
            .StoreHomeAbailableSystemsItems-item-button-name-container
                display: flex
                justify-content: center
                align-items: center
                flex-direction: column 
                width: 100%
                .StoreHomeAbailableSystemsItems-item-button-name
                    margin-left: 10px
                    font-size: 1.1vw
                    text-align: center
                    font-weight: 500
                .StoreHomeAbailableSystemsItems-item-button-secondname
                    margin-left: 10px
                    text-align: start