.container-members-modal-chat {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .subcontainer-memebers-modal-chat{
        width: 100%;
        display: flex;
        background: #fff;
        position: relative;
        

    }

    .container-footer-modal-members{
        width: 97%;
        display: flex;
        justify-content: end;
        padding-right: 1.5vw;


        .add-member-group-chat{
            width: 2vw;

        }

    }
}