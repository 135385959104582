
        .section-question{
            width: 95%;
            display: grid;
            background: #FFFFFF;
            box-shadow: 0px 1px 6px -1px rgb(0 0 0 / 35%);
            overflow: auto;
            margin-bottom: 3vh;
            align-self: center;
            justify-content: center;
            margin-top: 3vh;
            padding-bottom: 3vh;
            .container-radio{
                display: flex;
                align-items: center;
               
                
                .item-name{
                    font-family: "Poppins";
                    font-size: 0.9vw;
                    font-weight: 300;
                    margin-right: 0.3rem;
                }
                .input-radio{
                    margin-right: 1rem;
                }
            }

            .title-question{
                color: #094070;
                font-family: "Poppins";
                font-weight: 300;
                text-align: center;
                padding: 1vh 0;
                height: auto;
                border-bottom: 1px solid #c2c2c2;
                width: 36vw;

            }
                .style-text{
                    overflow: auto;
                    height: 14vh;
                    font-family: "Poppins";
                    font-size: 0.9vw;
                    font-weight: 300;
                    width: 36vw;
                    margin-top: 1.1vh;
                    margin-bottom: 1.1vh;
                    background-color: #FFFFFF;

                }
            .title-question-intern{
                color: #9c9c9c;
                font-family: "Poppins";
                font-size: 0.9vw;
                font-weight: 300;
                padding: 2vh 0 2vh 1vw;
            }
            .text-answer{
                width: 95%;
                margin-left: 1vw;
                color: #303030;
                font-family: "Poppins";
                font-size: 0.9vw;
                text-align: justify;
            }
            span{
                display: block;
            }
        }


        .section-question-almacen{
            width: 95%;
            display: block;
            border-bottom: 1px solid #8a8787;
            margin-bottom: 10px;

            .title-question-almcen{
                width: 99%;
                color:#094070;
                display: flex;
                justify-content: flex-start;
                font-family: "Poppins";
                font-size: 1.1vw;
                margin-bottom: 10px;
                
            }

            .style-text-almacen{
                width: 99%;
                display: inline-block;
                font-family: "Poppins";
                text-align: justify;
                font-size: 0.9vw;
                margin-bottom: 10px;

                
            }
            
        }


