.container-Atq_Availability_Modal_asign-all{
    width: 100%;
    display: grid;
    grid-template-rows: .1fr .9fr .1fr .1fr .1fr;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    font-family: "Poppins";
    .Atq_Availability_Modal_asign-title{
        border-bottom: 1px solid #777777;
        color: #303030;
        text-align: start;
        font-weight: 500;
    }
    .container-Atq_Availability_Modal_asign-list-items{
        width: 86%;
        margin-bottom: 20px;
        font-family: "Poppins";
        justify-self: center;
        .container-Atq_Availability_Modal_asign-items-scroll{
            height: 10vw;
            overflow-y: scroll;
            .container-Atq_Availability_Modal_asign{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
            .Atq_Availability_Modal_asign-c-item{
                width: 100%;
                display: grid;
                grid-template-columns: 1fr .1fr;
                align-items: center;
                padding-bottom: 5px;
                .Atq_Availability_Modal_asign-item-name{
                    font-family: 'Poppins';
                    font-weight: 300;
                    font-size: 18px;
                    color: #696969;
                    text-align: left;
                }
                .Atq_Availability_Modal_asign-item-check{
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    margin-right: .5rem;
                    background-size: cover;
                }
            }
        }
    }
}
.Atq_Availability_Modal_asign-button{
    cursor: pointer;
    outline: none;
    justify-self: center;
    margin-top: 1vw;
    width: 50%;
    padding: .5vw;
    border-radius: 1vw;
    background: linear-gradient(23deg, rgba(55,167,185,1) 0%, rgba(9,64,112,1) 100%);
    color: #fff;
    box-shadow: 6px 5px 4px 0px rgb(0 0 0 / 25%);
    border: none;
}
}