.StoreHomeAvailableMaterialToAddItemsList-container{
    height: 60vh;
    display: flex;
    margin-top: 2vw;
    align-items: center;
    flex-direction: column;
    width: 100%;
    overflow: scroll;
    font-family: 'Poppins';
    .StoreHomeAvailableMaterialToAddItemsList_elements_info_container{
        width: 96%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        border: 1px solid #a8a8a8;
        border-radius: .5vw;
        padding: .5vw .9vw;
        margin-bottom: 1vw;
        color: #303030;
        cursor: pointer;
        outline: none;
        .StoreHomeAvailableMaterialToAddItemsList_elements_info_elements_container{
            width: 95%;
            display: flex;
            flex-direction: column;
            .StoreHomeAvailableMaterialToAddItemsList_elements_info_ubicacion_container{
                display: flex;
                font-size: 1.3vw;
                align-items: center;
                flex-direction: row;
                .StoreHomeAvailableMaterialToAddItemsList_elements_info_ubicacion_element{
                    margin-right: 1vw;
                    font-weight: 300;
                }
            }
        }
        .StoreHomeAvailableMaterialToAddItemsList_elements_img_arrow{
            width: 1vw;
            height: 1vw;
        }
    }
}
