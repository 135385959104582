%ButtonToAddOrDelete{
    position: absolute;
    border-radius: 50%;
    width: 2.5vw;
    height: 2.5vw;
    border: none;
    box-shadow: 0px 1px 6px -1px rgb(0 0 0 / 35%);
    background: #FFFFFF;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
}


.ConRepor-container-table{
    width: 100%;
    display: flex;
    justify-content: center;
    border-spacing: 0;
    padding-top: 1vw;
    height: 40vh;
    box-shadow: 1px 1px 7px -2px #777777;
    position: relative;
    .ConRepor-precontainer-table{
        width: 95%;
        .ConRepor-table{
            width: 100%;
            border-collapse: collapse;
            display: inline-block;
            box-sizing: border-box;
        }
        .ConRepor-table-tr{
            display: flex;
            width: 100%;
            border-bottom: 0.07vw solid #a7a7a7;
        }
        .ConRepor-table-head{
            width: 100%;
            display: flex;
            .ConRepor-table-title:nth-child(1){
                text-align: center;
                width: 14%;
            }
            .ConRepor-table-title:nth-child(2){
                text-align: center;
                width: 19%;
            }
            .ConRepor-table-title:nth-child(3){
                text-align: start;
                width: 34%;
                
            }
            .ConRepor-table-title:nth-child(4){
                text-align: start;
                width: 14%;
            }
            .ConRepor-table-title:nth-child(5){
                text-align: start;
                width: 14%;
            }

            .ConRepor-table-title{
                font-weight: 400;
                border-bottom: .07vw solid #a7a7a7;
                padding-bottom: 1vw;
                /* width: calc( 100% / 3); */
                font-size: 1vw;
                color: #094070;
            }
            .shtmriric-button{
                width: 10%;
            }
        }
        .ConRepor-table-body{
            height: 35vh;
            width: 100%;
            overflow: auto;
            display:block;
            .ConRepor-body-data:nth-child(1){
                text-align: center;
                width: 14%;
            }
            .ConRepor-body-data:nth-child(2){
                text-align: center;
                width: 19%;
            }
            .ConRepor-body-data:nth-child(3){
                text-align: justify;
                width: 34%;
                padding-right: 0.4vw;
            }
            .ConRepor-body-data:nth-child(4){
                text-align: center;
                width: 14%;
            }
            .ConRepor-body-data:nth-child(5){
                text-align: center;
                width: 14%;
            }
            .ConRepor-body-data:nth-child(6){
                text-align: center;
                width: 5%;
            }
            .ConRepor-body-data{
                text-align: center;
                padding: 1vw 0 .5vw 0;
                color: #777777;
                font-size: 1vw;
                display: inline-block;
                box-sizing: border-box;
            }
            .ConRepor-button{
                width: 1.8vw;
                height: 1.8vw;
                border-radius: 1vw;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 1px 1px 4px -0.5px #a2a2a3;
                cursor: pointer;
                outline: none;

                .ConRepor-button-img{
                    width: 3vw;
                }
            }
            .shtmriric-button{
                width: 10%;
            }
            .ConRepor-body-select{
              cursor: pointer;
            }
        }
        .ConRepor-section-rigth-card-container-add-container{
            right: -1vw;
            bottom: -1vw;
            @extend %ButtonToAddOrDelete;
            .ConRepor-section-rigth-card-container-add{
                font-size: 1.7vw;
                color: rgb(199, 0, 0);
                width: 1.7vw;
            }
        }
    }
}