.precontainer_survey {
    position: relative;
    width: 95%;
    padding: 3.3vw 1vw;
    justify-self: start;
    align-self: start;
    margin-left: 3vw;

    .container_survey {
        width: 100%;
        display: grid;
        grid-template-rows: 0.3fr 1fr;
    }
    .survey_title {
        padding-bottom: 5vh;
        color: #094070;
        font-family: "Poppins";
        text-align: center;
        padding-top: 3vh;
        font-size: 1.2vw;
        font-weight: 300;
    }
    .section-question {
        width: 90%;
        margin-top: 1vh;
        display: grid;
        margin-left: 2vw;
        background: #ffffff;
        box-shadow: 0px 1px 6px -1px rgb(0 0 0 / 35%);
        overflow: auto;
        margin-bottom: 3vh;
        align-self: center;
        justify-content: center;
        margin-top: 1vh;
        padding-bottom: 2vh;
        .title-question {
            color: #094070;
            font-family: "Poppins";
            font-weight: 300;
            text-align: center;
            padding: 1vh 0;
            height: auto;
            border-bottom: 1px solid #c2c2c2;
            width: 37vw;
        
        }
        .title-question-intern {
            color: #9c9c9c;
            font-family: "Poppins";
            font-size: 0.9vw;
            font-weight: 300;
            padding: 2vh 0 2vh 1vw;
        }
        .text-answer {
            width: 95%;
            height: 8vh;
            margin-left: 1vw;
            color: #303030;
            font-family: "Poppins";
            font-size: 0.9vw;
            border-bottom: 1px solid #c2c2c2;
        }
    }
    span {
        display: block;
    }

    
}

.section-question-mantenimiento {
    width: 95%;
    margin-top: 1vh;
    display: grid;
    background: #ffffff;
    box-shadow: 0px 1px 6px -1px rgb(0 0 0 / 35%);
    overflow: auto;
    margin-bottom: 3vh;
    align-self: center;
    font-family: "Poppins";
   
    margin-top: 1vh;
    padding-bottom: 2vh;
    .title-question-mantenimiento {
        color: #094070;
        font-family: "Poppins";
        font-weight: 300;
        text-align: center;
        padding: 1vh 0;
        height: auto;
        border-bottom: 1px solid #c2c2c2;
    }
    .title-question-intern-mantenimiento {
        color: #9c9c9c;
        font-family: "Poppins";
        font-size: 0.9vw;
        font-weight: 300;
        padding: 2vh 0 2vh 1vw;
    }
    .text-answer-mantenimiento {
        width: 95%;
        height: 8vh;
        margin-left: 1vw;
        color: #303030;
        font-family: "Poppins";
        font-size: 0.9vw;
        
    }
}
