.container-left-tabChat {
    .title-left-container {
        font-size: 1.2vw;
        font-family: "Poppins";
        font-weight: 300;

    }

    
    .txt-member-sinasignar{
        font-size: 1vw;
        font-family: "Poppins";
        font-weight: 300;
        display: flex;
        justify-content: center;
        margin-top: 10%;
    }

    .search_content_base {
        margin-top: 1vw;
        margin-bottom: 1vw;
    }

    .profile-list {
     width: 31vw;
     height: 24vh;
     overflow: scroll;
     
        ::-webkit-scrollbar {
            display: none;
        }

        .sesions-container-list-member-chat-modal {
            width: 100%;
            overflow: scroll;
            max-height: 27vh;



            .sesions-card-modal-chat {
                width: 90%;
                font-weight: 300;
                font-family: "Poppins";
                font-size: 1.2vw;
                border: solid 1px #707070;
                margin-bottom: 1vw;
                border-radius: 5px;
                display: flex;
                flex-direction: column;



                .sesions-name-memebers-list-modal-chat {
                    width: 95%;
                    display: flex;
                    justify-content: left;
                    padding-left: 0.4vw;
                    padding-top: 0.4vw;
                    align-items: center;

                    .sesions-title-style-members-modal {
                        font-size: 1vw;
                    }
                }

                .sesions-clasification-memebers-chat-modal {
                    padding-right: 0.4vw;
                    display: flex;
                    justify-content: flex-end;
                    font-size: .9vw;
                    align-items: center;

                    .sesions-title-clasification-members-modal {
                        display: flex;
                        justify-content: flex-end;
                        font-size: 0.8vw;
                        align-items: center;

                    }
                }
            }
        }
    }
}
