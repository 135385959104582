@media screen and ( max-width: 970px )
    .c-sidebar
        width: 40%

@media screen and ( max-width: 800px )
    .c-sidebar
        width: 40%

@media screen and ( max-width: 550px )  
    .c-sidebar
        width: 70%