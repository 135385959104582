.c-paymentCondition
    width: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    .c-paymentCondition-c-list-items
        width: 90%
        margin-bottom: 20px
        .c-paymentCondition-c-item
            width: 100%
            display: flex
            flex-direction: row
            justify-content: space-between
            align-items: center
            padding-bottom: 5px
            border-bottom: 1px solid #a8a8a8
            margin-top: 10px
            .c-paymentCondition-item-name
                font-family: 'Poppins'
                font-weight: 300
                font-size: 1.5vw
                color: #696969
            .c-paymentCondition-item-check
                display: inline-block
                width: 18px
                height: 18px
                margin-right: .5rem
                background-size: cover
        .c-paymentCondition-input-container-add
            width: 100%
            .c-paymentCondition-item-add
                width: 100%
                padding: 0 0 5px 0
                font-family: 'Poppins'
                font-weight: 300
                font-size: 1.5vw
                border-top: none
                border-left: none
                border-right: none
                border-bottom: 1px solid #a8a8a8
                margin-bottom: 10px
                outline: none
    .c-paymentCondition-button
        width: 60%
        padding: 10px
        border-radius: 20px
        border: none
        background: linear-gradient(270deg, rgba(26,188,156,1) 0%, rgba(55,167,185,1) 91%)
        color: #fff
        outline: none
        cursor: pointer
        font-size: 16px