.precontainer_estatus{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .container-columns{
        width: 85%;
        margin-top: 24vh;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 3vw;
        flex-direction: row;
        margin-left: 8vw;
        .section-left{
            
        }
        .section-rigth{
            /* margin-top: 8vh; */
        }

    }
    .section-3{
        width:100%;
        display:flex;
        justify-content:end;
        margin-bottom: 13vh;

        .btn_aceptar{
            margin-top: 10vh;
            font-family: 'Poppins';
            background: linear-gradient(93deg, #1abc9c 10%, #094070 100%);
            color: #fff;
            width: 15%;
            padding: .4vw;
            cursor: pointer;
            border: none;
            border-radius: 2vw;
            margin-right: 7vw;
            box-shadow: 4px 3.9px 3px #c4c1c1;
        }

    }
}