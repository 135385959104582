
%quote-navbar-search-i{
    border: none;
    padding: .2vw;
    background: #dddddd;
    outline: none;
    color: #fff;
    font-family: 'Poppins';
    color: #777777;
}

.c-StoreHomeAvailableMaterialToAddSearch-c-input-search{
    width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 1fr .1fr;
    .StoreHomeAvailableMaterialToAddSearch-c-input-search{
        width: 95%;
        display: flex;
        align-items: center;
        justify-items: center;
        flex-direction: row;
        .c-StoreHomeAvailableMaterialToAddSearch-search-icobuscar{
            background: #dddddd;
            padding: 0px 6px 0px 12px;
            border-radius: 20px 0 0 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 2vw;
            border: none;
        }
            img{
                width: 1.3vw;
            }
        .StoreHomeAvailableMaterialToAddSearch-input-search{
            border: none;
            width: 80%;
            background: #dddddd;
            border-radius: 0;
            outline: none;
            padding: .3vw 0;
            height: 1.4vw;
            font-size: 1vw;
            color: #777777;
        }
        .quote-navbar-search-input-combobox{
            @extend %quote-navbar-search-i;
            border-radius: 0px 20px 20px 0px;
            width: 12vw;
            height: 2vw;
            font-size: 1vw;

        }
    }
    .StoreHomeAvailableMaterialToAddSearch-button-lector{
        height: 2vw;
        width: 2vw;
        background: linear-gradient(152deg, rgba(55,167,185,1) 0%, rgba(9,64,112,1) 100%);
        border: none;
        outline: none;
        border-radius: .5vw;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        outline: none;
        .StoreHomeAvailableMaterialToAddSearch-button-lector-img{
            width: 2vw;
        }
    }
}
