.container-mantenimiento{
    display: grid;
    width: 100%;
    justify-content: center;
    align-items: start;
    height: 70vh;
    padding-top: 20vh;
    padding-bottom: 2vh;
    grid-template-columns: 1fr;
    grid-template-rows: 0.1fr 1fr;


    .precontainer-mantenimiento{
        display: grid;
        width: 90%;
        height: 70vh;
        grid-template-columns: 1fr 1fr;
        /* grid-template-rows: 1fr 1fr; */
        grid-gap: 5vw;
        margin-left: 5vw;
        margin-right: 5vw;

        .section-left{
            width: 100%;
            height: 100%;
            display: grid;
            .agenda{
                border-radius: 10px;
                background: linear-gradient(
            93deg, #1abc9c 10%, #094070 100%);
                display: grid;
                grid-template-columns: 0.8fr 1fr;
                cursor: pointer;
                outline: none;
                border: none;
                

                .img-agenda{
                    justify-self: center;
                    align-self: center;
                    width: 15vw;
                }
                .agenda-text{
                    font-size: 2.8vw;
                    font-family: "Poppins";
                    font-weight: 300;
                    color:#fff;
                    align-self: center;
                    text-align: center;
                
                }
            }
        }

        .section-rigth{
            display: grid;
            grid-template-rows: 1fr 1fr;
            grid-gap: 5vh;
            width: 100%;

            .reque_medicos{
                border-radius: 10px;
                background: linear-gradient(90deg, #090979 -24%, #37a7b9 100%, #090979 94070%);
                display: grid;
                grid-template-columns: .8fr 1fr;
                cursor: pointer;
                outline: none;
                border: none;

                .img-medicos{
                    justify-self: center;
                    align-self: center;
                    width: 12vw;
                }
                .text-med{
                    font-size: 1.8vw;
                    font-family: "Poppins";
                    font-weight: 300;
                    color:#fff;
                    align-self: center;
                    text-align: center;
                
                }
            }

            .reque_hospitales{
                border-radius: 10px;
                background: linear-gradient(90deg, #37a7b9 23%, #1abc9c 75%, #37a7b9 94070%); 
                display: grid;
                grid-template-columns: 0.8fr 1fr;
                cursor: pointer;
                outline: none;
                border: none;

                .img-hospitales{
                    justify-self: center;
                    align-self: center;
                    width: 12vw;
                }
                .text-hosp{
                    font-size: 1.8vw;
                    font-family: "Poppins";
                    font-weight: 300;
                    color:#fff;
                    align-self: center;
                    justify-self: center;
                    text-align: center;
                
                }
            }
        }
    }
}