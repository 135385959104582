.container-component-comments-survey {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 7px;
    -webkit-box-shadow: -1px 5px 19px 0px #efecef;
    -moz-box-shadow: -1px 5px 19px 0px #efecef;
    box-shadow: -1px 5px 19px 0px #efecef;
    margin-bottom: 3vh;

    .header-comments {
        width: 100%;

        div {
            width: 100%;
        }
        .header-title {
            border-bottom: 1px solid #c2c2c2;
            padding-bottom: 0.5vh;

            .title-component {
                color: #1abc9c;
                font-family: "Poppins";
                font-size: 1.2vw;
                font-weight: 500;
                display: flex;
                justify-content: center;
                padding-top: 1vh;
            }
        }
        .header-subtitle {
            padding-bottom: 0.5vh;
            .subtitle-component {
                color: #000;
                font-family: "Poppins";
                font-size: 1vw;
                font-weight: 500;
                display: flex;
                justify-content: center;
                padding: 1vh 0 1vh;
            }
        }
    }
    //ATQ
    .body-coments {
        width: 100%;
        display: flex;
        flex-direction: column;

        .container-row {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            border-bottom: 1px solid #c2c2c2;

            &:first-child {
                border-top: 1px solid #c2c2c2;
            }

            &:last-child {
                border-bottom: none;
            }

            .preco-row {
                width: 33%;
                padding: 10px;
                border-right: 1px solid #c2c2c2;

                .text {
                    color: #303030;
                    font-family: "Poppins";
                    font-size: 0.9vw;
                    font-weight: 300;
                    align-items: center;
                }
                &:first-child {
                    width: 40%;
                }
                &:nth-child(2) {
                    width: 20%;
                    display: flex;
                    justify-content: center;
                    
                }
                &:last-child {
                    width: 40%;
                    border-right: none;
                }
            }
        }
    }

    //Almacen
    .preco-row-almacen {
        width: 50%;
        padding: 10px;
        border-right: 1px solid #c2c2c2;

        &:nth-child(2) {
            border-right: none;
        }
    }

    .text {
        color: #303030;
        font-family: "Poppins";
        font-size: 0.9vw;
        font-weight: 300;
        align-items: center;
    }

    //Ventas
    .body-coments-ventas {
        width: 100%;
        display: flex;
        flex-direction: column;

        .container-row-ventas {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            border-bottom: 1px solid #c2c2c2;

            &:first-child {
                border-top: 1px solid #c2c2c2;
            }

            &:last-child {
                border-bottom: none;
            }

            .preco-row-ventas {
                width: 33%;
                padding: 10px;
                border-right: 1px solid #c2c2c2;

                .text-ventas {
                    color: #303030;
                    font-family: "Poppins";
                    font-size: 0.9vw;
                    font-weight: 300;
                    align-items: center;
                }
                &:first-child {
                    width: 40%;
                }
                &:nth-child(2) {
                    width: auto;
                }
                &:last-child {
                    width: 40%;
                    border-right: none;
                }
            }
        }
    }

    //Mantenimiento

    .preco-row-mantenimiento {
        width: auto;
        padding: 20px;
        border-top: 1px solid #c2c2c2;
    }
}
