.container-FactoryConfigurationProductList
    width: 100%
    border-right: 2px solid #c2c2c2
    .FactoryConfigurationProductList-title
        text-align: center
        font-family: 'Poppins'
        font-weight: 400
        color: #00325D
        font-size: 1.3vw
        margin: 10px 0 2px 0
    .FactoryConfigurationProductList-container-search
        width: 98%
        margin: 10px 0
        display: flex
        flex-direction: row
        .FactoryConfigurationProductList-search
            width: 60%
            font-family: 'Poppins'
            font-size: 1.1vw
            padding: 5px
            border: 1px solid #c7c7c7
            border-radius: 5px
            outline: none
        .FactoryConfigurationProductList-search-combobox
            width: 40%
            font-family: 'Poppins'
            font-size: 1vw
            padding: 5px
            border: 1px solid #c7c7c7
            border-radius: 5px
            outline: none
    .FactoryConfigurationProductList-container-cards
        width: 100%
        overflow: scroll
        height: 37vh
        .FactoryConfigurationProductList-cards-container
            width: 95%
            font-weight: 300
            display: flex
            flex-direction: row
            align-items: center
            min-height: 4vw
            border: .1vw solid #a7a7a7
            margin-bottom: 2vw
            cursor: pointer
            outline: none
            border-radius: 1vw
            padding: .5vw
            border-left: .5vw solid rgba(9,64,112,1)
            background: #fff
            .FactoryConfigurationProductList-card-container-info
                display: grid
                flex-direction: row
                justify-content: space-between
                align-items: center
                width: 100%
                .FactoryConfigurationProductList-card-info
                    font-weight: 300
                    font-family: 'Poppins'
                    font-size: 1.1vw
                    text-align: start
