.container_cx{
    display: grid;
    width: 100%;
    /* justify-content: center; */
    /* align-items: start; */
    height: 88vh;
    padding-top: 10vh;
    padding-bottom: 2vh;
    grid-template-columns: 1fr;
    grid-template-rows: 0.1fr 1fr;
}