.StoreHomeAbailableSystemsItems-c-Lumbares
    grid-template-areas: 'StoreHomeAbailableSystemsItems-c-lumbares-c-title' 'StoreHomeAbailableSystemsItems-c-lumbares-c-items'
    grid-area: StoreHomeAbailableSystemsItems-c-lumbares
    .StoreHomeAbailableSystemsItems-c-lumbares-c-title
        background: rgba(#313131, .4)
        grid-area: StoreHomeAbailableSystemsItems-c-lumbares-c-title
        .StoreHomeAbailableSystemsItems-c-lumbares-title
        .StoreHomeAbailableSystemsItems-c-lumbares-img
.StoreHomeAbailableSystemsItems-c-lumbares-c-items
    grid-area: StoreHomeAbailableSystemsItems-c-lumbares-c-items
    .StoreHomeAbailableSystemsItems-c-item
        .StoreHomeAbailableSystemsItems-c-item-img
        .StoreHomeAbailableSystemsItems-c-item-button
            .StoreHomeAbailableSystemsItems-item-button-ico