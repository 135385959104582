.StoreHouseAssignSystemsScreen-container{
    padding-top: 20vh;
    height: 80vh;
    width: 100%;
    display: flex;
    justify-items: center;
    font-family: 'Poppins';
    .StoreHouseAssignSystemsScreen-precontainer{
        width: 100%;
        display: grid;
        height: 70vh;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr .2fr;
        grid-gap: 2vw;
        justify-items: center;
        overflow: scroll;
        .StoreHouseAssignSystemsScreen-container-section{
            width: 90%;
            display: grid;
            height: 60vh ;
            grid-template-columns: .6fr 1fr;
            .StoreHouseAssignSystemsScreen-container-left-section{
                width: 90%;
            }
            .StoreHouseAssignSystemsScreen-container-rigth-section{
                width: 95%;
                padding-top: 1vw;
            }
        }
        .StoreHouseAssignSystemsScreen-container-bottom-button{
            width: 90%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .StoreHouseAssignSystemsScreen-bottom-button{
                width: 20%;
                padding: .3vw;
                border-radius: 1vw;
                margin-right: 2vw;
                border: none;
                background: linear-gradient(275deg, rgba(9,64,112,1) 0%, rgba(55,167,185,1) 100%);
                color: #fff;
                outline: none;
                cursor: pointer;
                box-shadow: 2px 3px 4px -0.9px #777;
            }
        }
    }
}

.StoreHouseAssignSystemsNotes-left-item-items{
    border-top: 1px solid #919191;
    margin-top: 2vh;
    font-family: 'Poppins';
    .StoreHouseAssignSystemsNotes-left-item-item-title{
        font-size: 1.2vw;
        font-weight: 300;

    }
    .StoreHouseAssignSystemsNotes-left-item-list{
        padding: 0px;
        list-style-type: circle;
        .StoreHouseAssignSystemsNotes-left-item-list-item{
            font-size: 1.1vw;
            width: 90%;
            margin: 0 0 1vw 2vw;
            font-weight: 300;
            color: #707070;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .StoreHouseAssignSystemsNotes-left-item-list-item-container-Text{

                .StoreHouseAssignSystemsNotes-left-item-list-item-Text{
                    color: #37A7B9;
                }
            }
        }
        .StoreHouseAssignSystemsNotes-left-item-list-item-img-delete{
            color: #b60000;
            cursor: pointer;
            outline: none;
            font-size: 1.1vw;
        }
    }
}