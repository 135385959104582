.container_der{
    display: flex;
    align-content: center;
    justify-content: center;
    width: 100%;
    .precontainer{
        display: grid;
        grid-template-rows: 1fr .2fr .2fr;
        .list-style{
                /* display: flex; */
                width: 46vw;
                border-radius: 5px;
                box-shadow: 3px 1px 10px 0 rgb(166 171 189 / 50%);
                padding-right: 3vw;
                padding-bottom: 3vh;
                height: 50vh;
                overflow-y: auto;
            .style-item{
                margin-top: 3vh;
                width: 100%;
                font-family: "Poppins";
                color: #303030;
                font-weight: 250;
                font-size: 1vw;
                .container-list{
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                        .inputText{
                            width: 85%;
                            margin-left: 0vw;
                            border: none;
                            border-bottom: 2px solid #CDCCCC;
                            font-family: "Poppins";
                            font-size: 1vw;
                            color:#303030;
                            font-weight: 250;
                            padding-bottom: 15px;
                        }
                    .icoEdit1{
                        width: 2.5vw;
                        height: 2.5vw;
                        margin-right: 10px;
                        cursor: pointer;
                    }
                    .container-del{
                        border-radius: 50%;
                        width: 2vw;
                        height: 2vw;
                        background: red;
                        align-items: center;
                        display: flex;
                        cursor: pointer;
                        justify-content: center;
                       // margin-top: 0.3vh;
                        .icoDelete{
                            width: 1.2vw;
                            height: 1.2vw;
                            
                            
                        }
                }
                }
            }
        }

        .btnAceptar{
            background: linear-gradient(93deg, #1abc9c 10%, #094070 100%);
            color: #fff;
            width: 15vw;
            padding: 0.5vw;
            font-family: "Poppins";
            cursor: pointer;
            border: none;
            border-radius: 2vw;
            height: 2.2vw;
            justify-self: end;
            box-shadow: 4px 3.9px 3px #c4c1c1;
        }

        .sectionIcons{
            display: flex;
            flex-direction: row;
            align-content: center;
            justify-content: end;
            .icoEdit{
                width: 4vw;
                cursor: pointer;
                height: 4vw;
                position: absolute;
                bottom: 23vh;
                z-index: 3;
                right: 16vw;
            }
            .icoAdd{
                width: 4vw;
                height: 4vw;
                cursor: pointer;
                position: absolute;
                bottom: 23vh;
                right: 8vw;
                top: 70vh;
                z-index: 3;
            }
        }
    }

}