.container-mess{
    padding-top: 20vh;
    height: 80vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    .precontainer-mess{
        width: 95%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 5vw;
    
        .section-left{
            width: 80%;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            padding-bottom: 10vh;
            height: 55vh;
        }
        .container-rigth{
            display: grid;
            grid-template-rows: 1fr .1fr;
            margin-bottom: 7vh;

            .btn-Aceptar{
                width: 34%;
                justify-self: end;
                /* padding: 0.1vw; */
                font-size: .9vw;
                text-align: center;
                color: #FFFFFF;
                font-family: "Poppins";
                background: linear-gradient(
            90deg, #094070 0%, #1abc9c 100%);
                border-radius: 20px;
                border: none;
                cursor: pointer;
                outline: none;
                margin-top: 3vw;
                padding: 0.5vw;
                .btn-title{

                }
            }
        }
    }
}