.preconter_list_asignar_cx{
    width: 56%;
    height: 60vh;
    display: flex;
    margin-top: 2vw;
    align-items: center;
    flex-direction: column;
    overflow: scroll;
    justify-self: center;

    .content_info_asignar{
        width: 95%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        border: 1px solid #707070;
        border-radius: 0.5vw;
        padding: 0.5vw 0.9vw;
        margin-bottom: 1vw;
        color: #303030;
        cursor: pointer;
        outline: none;


        .text_info_asignar{
            width: 95%;
            display: flex;
            flex-direction: column;

            .info_date_numberPqx_asignar{
                display: flex;
                align-items: center;
                flex-direction: row;
                font-family: "Poppins";
                margin-bottom: 0.5vw;
                color: #303030;
                font-weight: 300;
                font-size: 1vw;

            }
        }
        .storeHouseStock_elements_img_arrow{
            width: .7vw;
            height: .7vw;
        }
    }
}