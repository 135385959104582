%stylesToButton
    cursor: pointer
    outline: none
    border: none
    
.c-storeHouse-sec1
    grid-template-columns: 1fr 1fr
    grid-template-areas: 'c-storeHouse-c-programar c-storeHouse-c-historial' 'c-storeHouse-c-programar c-storeHouse-c-cotizar' 'c-storeHouse-c-facturas c-storeHouse-c-cotizar'
    .disabled
     opacity: 0.5   
    .c-storeHouse-item-simpleform
        display: flex
        justify-content: center
        flex-direction: column
        align-items: center
        background: linear-gradient(190deg, rgba(9,9,121,1) 0%, rgba(55,167,185,1) 100%, rgba(9,9,121,1) 094070%)
        img
            width: 9vw
    .c-storeHouse-item-horizontalform
        display: flex
        flex-direction: row
        justify-content: space-around
        align-items: center
        background: linear-gradient(0deg, rgba(9,9,121,1) 0%, rgba(32,90,154,1) 29%, rgba(55,167,185,1) 100%)
        img
            width: 7vw
    .c-storeHouse-item
        border-radius: 10px
        color: #fff
        cursor: pointer
        text-align: center
        align-self: stretch
        justify-self: stretch
        text-decoration: none
        @extend %stylesToButton
        .storeHouse-item-title
            font-size: 1.5vw
            font-family: 'Poppins'
            font-weight: 300
    .c-storeHouse-c-programar
        display: flex
        grid-area: c-storeHouse-c-programar
        flex-direction: column
    .c-storeHouse-c-historial
        grid-area: c-storeHouse-c-historial
        padding-left: 15px
    .c-storeHouse-c-facturas
        grid-area: c-storeHouse-c-facturas
    .c-storeHouse-c-cotizar
        grid-area: c-storeHouse-c-cotizar