.StoreHouseApproveOrderPreViewScreen-container{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: 'Poppins' !important;
    .StoreHouseApproveOrderPreViewScreen-primary-container{
        width: 70%;
        padding-top: 20vh;
        height: 70vh;
        display: grid;
        flex-direction: column;
        grid-template-rows: 1fr .1fr;
        grid-gap: 2vw;
    }
}