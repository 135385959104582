%quote-navbar-search-i
    border: none
    padding: 5px
    background: rgba(#000, .3)
    outline: none
    color: #fff
    font-family: 'Poppins'
    font-weight: 100
    
.c-quote-navbar
    background: linear-gradient(270deg, rgba(9,64,112,1) 0%, rgba(55,167,185,1) 91%)
    display: flex
    flex-direction: row
    align-items: center
    position: fixed
    top: 5vw
    width: 100%
    height: 7vh
    z-index: 1
    .quote-navbar-c-history
        display: flex
        justify-content: center
        align-items: center
        margin-left: 40px
        background: none
        border-radius: 50%
        cursor: pointer
        border: none
        outline: none
        width: 3vw
        .quote-navbar-history
            width: 2.2vw
    .quote-navbar-title
        color: #fff
        font-family: 'Poppins'
        font-weight: 300
        font-size: 1.5vw
        position: absolute
        width: 100%
        text-align: center
        z-index: -1
        margin: 0
    .quote-navbar-c-search
        margin: 0 0 0 auto
        display: flex
        flex-direction: row
        margin-right: 40px
        .quote-navbar-c-search-img
            padding: 6px 9px
            border-radius: 20px 0px 0px 20px
            background: rgba(#000, .3)
            .quote-navbar-search-img
                width: 1vw
        .quote-navbar-search-input
            @extend %quote-navbar-search-i
            border-radius: 0 0px 0px 0
            width: 18vw
            font-size: .9vw
        .quote-navbar-search-input-combobox
            @extend %quote-navbar-search-i
            border-radius: 0px 20px 20px 0px
            width: 12vw
            font-size: .8vw
        ::placeholder
            color: #e0e0e0
    .quote-button-container-disabled
        display: none
        transition: 1s
    .quote-button-container
        width: 5%
        .quote-button-save
            width: 100%
            cursor: pointer
            outline: none
        .quote-button-img
            width: 40px
            display: flex
            flex-direction: row
            justify-content: center
            align-items: center
            background: transparent
            border: none
            outline: none
