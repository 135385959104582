.precontainer_qualityHome
    display: flex
    justify-content: center
    align-items: center
    height: 100vh
    .container_mod_rigth
        display: grid
        grid-template-columns: 1fr 1fr
        grid-template-areas: 'section-1 section-2'
        width: 90%
        height: 80vh
        margin: auto
        grid-auto-flow: row dense
        margin-top: 40px
        margin-bottom: 40px
        grid-gap: 1.5vw
        padding-top: 60px
        .container-sec
            display: grid
            grid-gap: 20px
            justify-items: center
            align-items: center
            
        
  
        