.precontainer-analisis{
    width: 100%;
    padding-top: 20vh;
    font-family: "Poppins";
    display: flex;
    align-items: center;
    flex-direction: column;

    .container-analisis{
    width: 60%;

    .analisis-header{
        display: flex;
        margin-top: 5vh;
        justify-content: space-between;

        .cont-left{
            width: 50%;
            display: flex;

            .cont-fecha{
                display: flex;
                flex-direction: column;
                margin-right: 20px;
                position: relative;

                .select-1{
                    min-width: 90px;
                    height: 100%;
                    cursor: pointer;
                    border: 1px solid #707070;
                    border-radius: 5px;
                }

                .span-cx{
                    position: absolute;
                    top: -28px;
                    left: 0;

                }

            }
            .select-2{
                min-width: 90px;
                cursor: pointer;
                height: 100%;
                border: 1px solid #707070;
                border-radius: 5px;
            }

            .img-searchs{
                width: 35px;
                cursor: pointer;
                margin-left: 30px;
            }
        }


        .cont-rigth{
            display: flex;
            align-items: center;

            .cont-close{
                display: flex;
                align-items: center;
                margin: 0 20px;

                .img-close{
                    margin-right: 10px;
                    width: 20px;
                }

            }

            .cont-open{
                display: flex;
                align-items: center;
                margin: 0 20px;


                .img-open{
                    margin-right: 10px;
                    width: 24px;
                }

            }
        }

    }


    .container-analisis-list{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: 58vh;
        overflow-y: scroll;
        margin-top: 30px;

        .analisis-list{
            margin-top: 10px;
            display:flex ;
            cursor: pointer;
            justify-content: space-between;
            width: 95%;
            align-items: center;
            border: 1px solid #707070;
            border-radius: 10px;
            padding: 2px 10px;
            .list{
                display: flex;
                align-items: center;

                .list-p{
                    margin-left: 15px;
                }
            }

            .icon-arrow{
                margin-right: 2vw;
            }

        }
    }


    }
}