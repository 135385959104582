.container_encuesta_modal{
    width: 50%;
    margin: auto auto;

    .container-encuesta-tittle{
        padding: 20px 20px;
        .modal_encuesta_texto{
            font-family: 'Poppins';
            font-weight: 400;
            color: #707070;
            font-size: 17px;
            
        }
    }


    .modal_encuesta_button{
        height: 2.3vw;
        border-radius: 30px;
        width: 12vw;
        background: rgb(9,64,112);
        background: linear-gradient(90deg, rgba(9,64,112,1) 0%, rgba(55,167,185,1) 100%);
        border: none;
        font-size: 1vw;
        font-family: 'Poppins';
        color: #FFFFFF;
        cursor: pointer;
        outline: none;
    }
}