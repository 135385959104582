.container-FactoryConfigurationProductListAdd
    width: 100%
    .FactoryConfigurationProductListAdd-container-title
        text-align: center
        font-family: 'Poppins'
        font-weight: 400
        color: #00325D
        font-size: 1.3vw
        margin: 10px 0 10px 0
    .FactoryConfigurationProductListAdd-container-cards
        width: 100%
        overflow: scroll
        height: 43vh
        .FactoryConfigurationProductListAdd-card-container
            width: 90%
            font-weight: 300
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between
            min-height: 4vw
            border: .1vw solid #a7a7a7
            margin-bottom: 2vw
            border-radius: 1vw
            padding: .5vw
            border-left: .5vw solid rgba(9,64,112,1)
            background: #fff
            outline: none
            .FactoryConfigurationProductListAdd-card-name-container
                font-weight: 300
                font-family: 'Poppins'
                font-size: 1.1vw
                text-align: start
            .FactoryConfigurationProductListAdd-card-delete-container
                border-radius: 50%
                border: .1vw solid #fff
                width: 2.5vw
                height: 2.5vw
                display: flex
                justify-content: center
                align-items: center
                position: relative
                outline: none
                box-shadow: #000 3px 3px 6px -3px
                cursor: pointer
                .FactoryConfigurationProductListAdd-card-delete
                    width: 2.5vw