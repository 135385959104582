.container{
    display: grid;
    width: 100%;
    justify-content: center;
    align-items: start;
    height: 88vh;
    padding-top: 20vh;
    padding-bottom: 2vh;
    grid-template-columns: 1fr;
    grid-template-rows: .1fr 1fr;

    .ListElementsCXAbstractComponent-section-left-title-container{
        width: 17%;
        padding: 8px 0px;
        border-radius: 40px;
        text-align: center;
        margin-left: 7vw;
        .ListElementsCXAbstractComponent-section-left-title{
            padding: 0;
            margin: 0;
            color: #FFFFFF;
            font-size: 1.1vw;
            font-family: 'Poppins';
            font-weight: 300;
        }
    }
 
    
    .ListElementsCXAbstractComponent-section-left-title-container-colorTitlePQX{
        background: #707070;
    }

    .container-colums{
        width: 83%;
        display: grid;
        grid-template-columns: .8fr 1fr;
        justify-content: start;
        align-items: start;

    }
    // section{
    //     margin-top: 10vh;
    // }
}