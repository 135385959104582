.detalleproducto-c-info
    width: 100%
    left: -6%
    display: flex
    flex-direction: column
    font-size: 16px
    text-align: start
    .detalleproducto-info-configuration
        width: 100%
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        margin: 10px 0 0 0
        padding-bottom: 10px
        border-bottom: .5px solid #aaaaaa
        .info-configuration-c
            width: 70%
            .info-configuration-title
                font-weight: 300
                color: #073358
                margin: 10px 0 0 0
            .info-configuration-type
                font-family: 'Poppins'
                font-weight: 600
                color: #073358
        .info-configuration-delete
    .detalleproducto-combobox
        width: 100%
        margin: 10px 0 0 0
        padding-bottom: 10px
        border-bottom: .5px solid #aaaaaa
        .detalleproducto-combobox-select
            background: none 
            width: 100%
            border-radius: 10px
            outline: none
            font-weight: 600
            color: #073358
            padding: 5px
        .detalleproducto-combobox-option
            
    .detalleproducto-info-tipo
        width: 100%
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        padding-bottom: 10px
        border-bottom: .5px solid #aaaaaa
        .info-configuration-c
            width: 70%
            .info-configuration-title
                font-weight: 600
                color: #073358
                margin: 10px 0 0 0
            .detalleproducto-info-tipo-name
                font-family: 'Poppins'
                font-weight: 100
                color: #727272
        .info-configuration-c-buttons
            width: 100px
            display: flex
            flex-direction: row
            justify-content: space-around
            align-items: center
            .info-configuration-add
                border: none
                background: #fff
                border-radius: 50%
                color: #073358
                font-size: 24px
                box-shadow: #000 3px 3px 6px -3px
                display: flex
                justify-content: center
                width: 30px
                height: 30px
                align-items: center
                position: relative
                outline: none
                cursor: pointer
.ico-delete
    border: none
    background: #fff
    border-radius: 50%
    color: #ff6666
    font-size: 24px
    box-shadow: #000 3px 3px 6px -3px
    display: flex
    justify-content: center
    width: 30px
    height: 30px
    align-items: center
    position: relative
    outline: none
    cursor: pointer
    left: 64px