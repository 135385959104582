.atq-prouct
    width: 36vw
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    .atq-prouct-c-list-items
        width: 90%
        margin-bottom: 20px
        height: 56vh
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        .atq-prouct-c-list-items-scroll
            height: 46vh
            overflow-y: scroll
            display: flex
            flex-direction: column
            width: 100%
            .principal-title
                text-align: left
                font-family: 'Poppins'
                font-weight: 500
                padding-bottom: 1vh
            .second-title   
                text-align: left
                font-family: 'Poppins'
                font-weight: 500
                padding-bottom: 2vh
                color: #777777
                font-size: 1.1vw
            .description
                text-align: justify
                font-family: "Poppins"
                font-weight: 300
                font-size: 0.8vw
                color: #777777
            .style-list
                list-style-image: url('../../../assets/img/126PX/Icon-list2.png')
                .item-list
                    text-align: left
                    font-family: "Poppins"
                    font-weight: 300
                    font-size: 0.8vw
                    color: #777777
            .atq-prouct-modal
                overflow: auto
                height: 30vh
                width: 32vw
                border-radius: 7px
        .atq-prouct-button
            width: 60%
            padding: 10px
            margin-top: 30px
            border-radius: 20px
            border: none
            background: linear-gradient(270deg, rgba(26,188,156,1) 0%, rgba(55,167,185,1) 91%)
            color: #fff
            outline: none
            cursor: pointer
            font-size: 16px