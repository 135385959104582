.Schedulecolumn {
    float: left;
    width: 33.33%;
    padding: 10px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: 'center';
    align-content: center;
}

.cardSchedule {
    width: 100%;
    height: 20%;
    border-radius: 10px;
    display: inline-flex;
    box-shadow: rgba(0, 0, 0, 0.295) 0px 0px 10px;
    margin-bottom: 10px;
}

.card-logoSchedule {
    padding: 10px;
}

.card-contentSchedule {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
}