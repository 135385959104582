.container_menu_asignar{
    padding: 20vh 0 0 0;
    font-family: 'Poppins';
    display: flex;
    align-items: center;
    flex-direction: column;

    .menu_asignar_container{
        width: 85%;
       

        .menu_asignar_container_toggle{
            width: 100%;
            display: grid;
            grid-template-columns: 1fr .2fr .8fr;
            justify-content: flex-end;
            align-items: center;
            flex-direction: row;
            
            .menu_asignar_container_buttons{
                width: 100%;
                display: grid;
                grid-column-start: 3;
                grid-template-columns: 1fr 1fr;

                .normal_button{
                    padding: 0.5vw;
                    border: none;
                    background: #707070;
                    color: #ffff;
                    cursor: pointer;
                    outline: none;
                }
                .btn_left{
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                }
                .btn_rigth{
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                }
                .btn_active{
                    background: #37a7b9;
                    background: linear-gradient(270deg, #1abc9c 0%, #094070 91%);
                }

            }
        }

        .menu_asignar_container_elements{
            display: grid;
            grid-template-columns: .5fr 1fr;
            height: 100%;
            margin-top: 1vw;
        }
    }
}