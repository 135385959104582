.SearchUserComponent {
    width: 90%;
    display: flex;
    align-items: center;
    justify-items: center;
  
    .SearchUserComponent-search-icobuscar{
        background: #dddddd;
        padding: 0px 6px 0px 12px;
        border-radius: 20px 0 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 27px;
        border: none;
  
        .img-search-icon{
            width: 1vw;
  
        }
  
    }
  
  
    .SearchUserComponent-tools-input-search{
  
        border: none;
        width: 100%;
        background: #dddddd;
        border-radius: 0 20px 20px 0;
        outline: none;
        height: 25px;
        font-size: 0.9vw;
        font-family: "Poppins";
        font-weight: 350;
        color: #000;
  
       
    }
  
    ::placeholder{
        font-family: "Poppins";
        font-weight: 350;
        color: #000;
        font-size: 0.9vw;
    }
  
  }
  