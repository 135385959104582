.container-component-comments-Estatus {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 7px;
    -webkit-box-shadow: -1px 5px 19px 0px #efecef;
    -moz-box-shadow: -1px 5px 19px 0px #efecef;
    box-shadow: -1px 5px 19px 0px #efecef;
    margin-bottom: 3vh;

    .header-commentsEstatus {
        width: 100%;

        .header-titleEstatus {
            border-bottom: 1px solid #c2c2c2;
            padding-bottom: 0.5vh;

            .title-componentEstatus {
                color: #37a7b9;
                font-family: "Poppins";
                font-size: 1.2vw;
                font-weight: 500;
                display: flex;
                justify-content: center;
                padding-top: 1vh;
            }
        }
    }

    .body-Estatus {
        width: auto;
        display: flex;
        flex-direction: column;
        margin: 0px 10px 15px 10px;

        .container-Estatus {
            width: 100%;
            display: flex;
            justify-content: center;

            .select-Estatus {
                width: 100%;
                border-radius: 7px;
                border: 1px solid #c2c2c2;
                margin-top: 15px;
                margin-bottom: 20px;
                font-family: "Poppins";
                font-size: 0.9vw;
                font-weight: 200;
                padding: 5px;
                cursor: pointer;
            }
        }
    }
}
