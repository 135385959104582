.ATQ_GuardRole_View-container{
    width: 100%;
    height: 80vh;
    padding-top: 20vh;
    display: flex;
    justify-content: center;
    font-family: "Poppins";
    .ATQ_GuardRole_View-items-menu-container{
        width: 40%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4,15vh);
        justify-content: center;
        grid-gap: 2vw;
        .ATQ_GuardRole_View_container_item{
            width: 100%;
            display: grid;
            border-radius: 1vw;
            align-items: center;
            color: #fff;
            .ATQ_GuardRole_View_item_title{
                font-weight: 400;
                font-size: 1.3vw;
                text-align: center;
                font-family: "Poppins";
            }
            .ATQ_GuardRole_View_item_img{
                width: 5vw;
                justify-self: center;
            }
        }
        .ATQ_GuardRole_View_container_item:nth-child(1){
            background: rgb(55,167,185);
            background: linear-gradient(90deg, rgba(55,167,185,1) 0%, rgba(9,64,112,1) 100%);
            grid-template-columns: .4fr 1fr;
            cursor: pointer;
        }
        .ATQ_GuardRole_View_container_item:nth-child(2){
            grid-template-columns: 1fr .4fr;
            background: rgb(55,167,185);
            background: linear-gradient(90deg, rgba(55,167,185,1) 0%, rgba(26,188,156,1) 100%);
            cursor: pointer;
        }
        .ATQ_GuardRole_View_container_item:nth-child(3){
            background: rgb(26,188,156);
            grid-template-columns: .4fr 1fr;
            background: linear-gradient(90deg, rgba(26,188,156,1) 0%, rgba(9,64,112,1) 100%);
            cursor: pointer;
        }
        .ATQ_GuardRole_View_container_item:nth-child(4){
            grid-template-columns: 1fr;
            display: flex;
            justify-content: center;
            .ATQ_GuardRole_View_container_item_button{
                height: 5vh;
                width: 50%;
                border-radius: 1.5vw;
                background: rgb(26,188,156);
                background: linear-gradient(273deg, rgba(26,188,156,1) 0%, rgba(9,64,112,1) 100%);
                font-family: "Poppins";
                color: #fff;
                font-weight: 500;
                border: none;
                cursor: pointer;
                outline: none;
                box-shadow: 4px 3.9px 3px #c4c1c1;
            }
        }
    }
}