.c-users-sec1
    grid-template-columns: 1fr 1fr
    grid-template-areas: 'c-users-c-programar c-users-c-historial' 'c-users-c-facturas c-users-c-historial' 'c-users-c-facturas c-users-c-cotizar'
    .disabled
        opacity: .4
    .c-users-item-simpleform
        position: relative
        display: flex
        justify-content: center
        flex-direction: column
        align-items: center
        background: linear-gradient(190deg, rgba(9,9,121,1) 0%, rgba(55,167,185,1) 100%, rgba(9,9,121,1) 094070%)
        img
            position: absolute
            top: 10px
            height: 80%
            width: 90%
            object-fit: scale-down
            border-radius: 10px
        h3
            position: absolute
            bottom: 0px
            margin-top: 0px
            margin-bottom: 2vw
    .c-users-item-horizontalform
        position: relative
        display: flex
        justify-content: center
        flex-direction: column
        align-items: center
        background: linear-gradient(0deg, rgba(9,9,121,1) 0%, rgba(32,90,154,1) 29%, rgba(55,167,185,1) 100%)
        img
            position: absolute
            top: 10px
            height: 70%
            width: 90%
            object-fit: scale-down
            border-radius: 10px
        h3
            position: absolute
            bottom: 0px
            margin-top: 0px
            margin-bottom: 1vw
    .c-users-item
        border-radius: 10px
        color: #fff
        cursor: pointer
        text-align: center
        align-self: stretch
        justify-self: stretch
        text-decoration: none
        .users-item-title
            font-family: 'Poppins'
            font-weight: 400
    .c-users-c-programar
        grid-area: c-users-c-programar
    .c-users-c-historial
        grid-area: c-users-c-historial
    .c-users-c-facturas
        grid-area: c-users-c-facturas
    .c-users-c-cotizar
        grid-area: c-users-c-cotizar