.storeHouseApproveOrderScreen-section-rigth-iconografia{
    width: 90%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    font-family: 'Poppins';
    grid-gap: 1vw;
    .storeHouseApproveOrderScreen-section-rigth-iconografia-subcontainer{
       width: 100%;
       display: flex;
       justify-content: space-between;
       align-items: center;
       flex-direction: row;
        .storeHouseApproveOrderScreen-section-rigth-iconografia-img{
            width: 1vw;
        }
        .storeHouseApproveOrderScreen-section-rigth-iconografia-title{
            font-weight: 300;
            font-size: .9vw;
        }
    }
}