
    .StoreHouseInputComponent-body-data{
        
        text-align: center;
        color: #777777;
       margin-top: 9px;
        width: 13vw;
        height: 4vh;
        /* margin-right: 15px; */
        border-radius: 10px;
        border: 0.2px solid #707070;
    }
    
    
    

