.detalleproducto-c-item
    align-items: center
    border-radius: 10px
    box-shadow: 0px -1px 7px -2px
    display: flex
    flex-direction: column
    justify-content: center
    position: relative
    margin: 20px
    width: 150px
    transition: 1s
    height: fit-content
    .detalleproducto-items-delete
        position: absolute
        top: -9px
        right: -7px
    .detalleproducto-items-img
        width: 100px
        min-height: 80px
    .detalleproducto-items-name-typeSurgery
        width: 90%
        text-align: center
        padding: 3vw 0
        font-family: 'Poppins'
        font-weight: 400
        font-size: 14px
    .detalleproducto-items-button-mas
        border: none
        color: #fff
        width: 100%
        // cursor: pointer
        outline: none
        border-radius: 0 0 10px 10px
        background: linear-gradient(90deg, rgba(9,64,112,1) 0%, rgba(14,76,121,1) 35%, rgba(21,93,133,1) 57%, rgba(36,126,156,1) 80%, rgba(55,167,185,1) 100%)
        .items-button-mas-name
            margin: 0
            font-family: 'Poppins'
            font-weight: 300
            margin-top: 10px
            font-size: 14px
        .items-button-mas-name-second
            margin: 0
            font-family: 'Poppins'
            font-weight: 400
            margin-top: 10px
            font-size: 12px
        .items-button-mas-ver
            margin: 5px 0
            font-family: 'Poppins'
            font-weight: 100
            font-size: 14px
            color: #aaaaaa