.container-encuestas-detalles {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: start;
    padding-top: 16vh;

    .container-encuestas-colums-detalles {
        width: 90%;
        display: grid;
        grid-template-columns: 0.8fr 1fr;
        justify-content: start;
        align-items: start;
        grid-gap: 7vw;
        margin-top: 5vh;

        .container-sec-left {
        }

        .container-sec-rigth {
            display: flex;
            flex-direction: column;
            width: 95%;

            .container-medico {
                height: 64vh;
                overflow: overlay;
                padding-left: 4px;
            

                .text-medico-header {
                    margin-top: 3vh;
                    color: #094070;
                    font-family: "Poppins";
                    font-weight: 300;
                    text-align: center;
                    margin-bottom: 3vh;
                }

                
            }

            .btn-detalles-encuesta {
                background: linear-gradient(90deg, #37a7b9 10%, #094070 100%);
                color: #fff;
                padding: 1vh 2vw;
                font-family: "Poppins";
                font-size: 0.9vw;
                border-radius: 15px;
                width: 100px;
                margin-left: 32vw;
                margin-top: 2vh;
                padding: 5px;
            }

            .container-buttons-sales{
                display: grid;
                grid-auto-rows: 1fr 1fr;
                margin-right: 2vw;

                .icoAdd-sales{
                    border-style: none;
                    width: 2.5vw;
                    align-self: end;
                    justify-self: end;
                }
                .btn-Aceptar2-sales{
                    width: 34%;
                    height: 67%;
                    justify-self: end;
                    align-self: end;
                    font-size: 0.9vw;
                    text-align: center;
                    color: #FFFFFF;
                    font-family: "Poppins";
                    background: linear-gradient(
                90deg, #094070 0%, #1abc9c 100%);
                    border-radius: 20px;
                    border: none;
                    cursor: pointer;
                    outline: none;
                }
             }
             .btn-Aceptar21-sales{
                width: 30%;
                height: 4vh;
                justify-self: end;
                align-self: end;
                font-size: 0.9vw;
                text-align: center;
                color: #FFFFFF;
                font-family: "Poppins";
                background: linear-gradient(
            90deg, #094070 0%, #1abc9c 100%);
                border-radius: 20px;
                border: none;
                cursor: pointer;
                outline: none;
                margin-right: 2vw;
            }
        }
    }
}
