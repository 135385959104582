%ButtonsStoreHouse{
    height: 2vw;
    margin-top: 10px;
    border-radius: 30px;
    width: 16vw;
    background: rgb(9,64,112);
    background: linear-gradient(90deg, rgba(9,64,112,1) 0%, rgba(55,167,185,1) 100%);
    border: none;
    font-size: 1.3vw;
    font-family: 'Poppins';
    color: #FFFFFF;
    cursor: pointer;
    outline: none;
}


.StoreHomeReceivingMaterialReportIncomingScreen{
    padding-top: 20vh;
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: 'Poppins';

    .StoreHomeReceivingMaterialReportIncomingScreen-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: .7fr .3fr;
        width: 95%;
        justify-content: center;
        grid-gap: 0 6vw;

        .StoreHomeReceivingMaterialReportIncomingScreen-section-bottom-button-container{
            grid-column-start: 2;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            justify-self: end;
            .StoreHomeReceivingMaterialReportIncomingScreen-section-bottom-button-container-position{
                align-self: center;
                display: flex;
                width: 80%;
                justify-content: space-between;
                .StoreHomeReceivingMaterialReportIncomingScreen-section-bottom-button-quote{
                    @extend %ButtonsStoreHouse;
                }
        
                .StoreHomeReceivingMaterialReportIncomingScreen-section-bottom-button-continue{
                    @extend %ButtonsStoreHouse;
                }
            }
    
        }
    } 
}