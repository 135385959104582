.container-ModalCreateTeamATQ-all{
    width: 100%;
    display: grid;
    grid-template-rows: .1fr .9fr;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    font-family: "Poppins";
    .ModalCreateTeamATQ-title{
        border-bottom: 1px solid #777777;
        color: #303030;
        text-align: start;
        font-weight: 500;
    }
    .container-ModalCreateTeamATQ-list-items{
        width: 90%;
        margin-bottom: 20px;
        font-family: "Poppins";
        .container-ModalCreateTeamATQ-items-scroll{
            height: 10vw;
            overflow-y: scroll;
            .container-ModalCreateTeamATQ{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
            .ModalCreateTeamATQ-c-item{
                width: 100%;
                display: grid;
                grid-template-columns: 1fr .1fr;
                align-items: center;
                padding-bottom: 5px;
                .ModalCreateTeamATQ-item-name{
                    font-family: 'Poppins';
                    font-weight: 300;
                    font-size: 18px;
                    color: #696969;
                    text-align: left;
                }
                .ModalCreateTeamATQ-item-check{
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    margin-right: .5rem;
                    background-size: cover;
                }
            }
        }
    }
        .ModalCreateTeamATQ-button{
            cursor: pointer;
            outline: none;
            margin-top: 1vw;
            width: 50%;
            padding: .5vw;
            border-radius: 1vw;
            background: linear-gradient(23deg, rgba(55,167,185,1) 0%, rgba(9,64,112,1) 100%);
            color: #fff;
            box-shadow: 6px 5px 4px 0px rgb(0 0 0 / 25%);
            border: none;
        }
    }
}