.numberInput-content {
    display: flex;
    flex-direction: column;
}
.react-numeric-input {
    margin-top: 8px;
    /* b {
        width: 20px!important;
        
        background: #777777!important;
        border-color: #777777;
        box-shadow: none!important;
        border-radius: 0!important;
        border-color: #777777!important;
        right: 0!important;
        border-width: 1px 1px 1px 1px!important;
        top: 0!important;
        &:last-child {
            bottom: 0!important;
            top: 50%!important;
        }
    } */
}
.input_title {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    color: #303030;
}
/*.react-numeric-input {
    input {
        width: 80px;
        height: 35px;
    }
    b {
        width: 20px!important;
        background: #777777!important;
        border-color: #777777;
        box-shadow: none!important;
        border-radius: 0!important;
        border-color: #CDCCCC!important;
        right: 0!important;
        i {
            border-color: transparent transparent #ffffff!important;
        }
        &:first-child {
            top: 0!important;
        }
        &:last-child {
            bottom: 0!important;
        }
    }
}*/
.numeric-input-base {
    display: flex;
    input {
        width: 55px;
        height: 35px;
        border: 1px solid #777777!important;
        font-weight: 400;
        font-family: 'Poppins';
        text-align: center;
    }
}
.arrows {
    border-radius: 0;
    height: 39px;
    display: flex;
    flex-direction: column;
}
.arrowUp, .arrowDown {
    border-bottom-color: '#ffffff';
    background: #777777;
    height: 50%;
    .icon-arrow {
        font-size: 25px;
        color: #ffffff!important;
    }
}