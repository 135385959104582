.ATQ_Administrator_GuardRol_component {
    width: 90%;
    justify-self: center;
    box-shadow: 1px 1px 5px 0px rgba(#707070, 0.6);
    border-radius: 2vw;
    display: grid;
    grid-template-rows: 0.1fr 0.9fr;

    .ATQ_Administrator_GuardRol_title {
        text-align: center;
        color: #303030;
    }

    .ATQ_Administrator_GuardRol_day_component {
        overflow-y: scroll;
        justify-self: center;
        justify-content: center;
        height: 60vh;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(auto-fit, 13vw);

        .ATQ_Administrator_GuardRol_day_item_component {
            margin: 1vw 0;
            height: 15vw;
            justify-self: center;
            justify-content: center;
            width: 22%;
            box-shadow: 1px 1px 5px 0px rgba(#707070, 0.6);
            border-radius: 1vw;
            display: flex;
            flex-direction: column; 
            margin-right: 10px;
            

            .txtNameGroup {
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                padding: 1vh 0;
            }

            .ATQ_Administrator_GuardRol_day_list_component {
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 1vh;
                height: 7rem;
                flex-direction: column;
                overflow-y: scroll;

                .li_group {
                    //list-style: none;
                    display: flex;
                    padding: 0;
                    flex-direction: column;
                    justify-content: center;
                    font-size: 0.8vw;
                    max-width: 90%;
                    list-style-position: inside;

                    .group-item-name {
                        //color: red;
                        font-family: "Poppins";
                        color: #303030;
                        width: auto;
                        font-weight: 400;
                        font-size: 0.9vw;
                        justify-content: center;
                    }
                }

                .ATQ_Administrator_GuardRol_day_img {
                    width: 2.5vw;
                    cursor: pointer;
                }
            }

            .ATQ_Administrator_GuardRol_day_list_component-Add {
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 10vh;
                height: 80px;
                justify-content: end;
                flex-direction: column;
                overflow-y: scroll;

                .li_group {
                    //list-style: none;
                    display: flex;
                    padding: 0;
                    flex-direction: column;
                    justify-content: center;
                    font-size: 0.8vw;
                    max-width: 90%;
                    list-style-position: inside;

                    .group-item-name {
                        //color: red;
                        font-family: "Poppins";
                        color: #303030;
                        width: auto;
                        font-weight: 400;
                        font-size: 0.9vw;
                        justify-content: center;
                    }
                }

                .ATQ_Administrator_GuardRol_day_img {
                    width: 2vw;
                    cursor: pointer;
                }
            }

            .ATQ_Administrator_GuardRol_day_team_icons_component-members {
                width: 100%;
                display: flex;
                align-content: end;
                justify-content: end;
                cursor: pointer;
                margin-top: 5px;
                margin-bottom: 3px;

                .ATQ_Administrator_GuardRol_day_team_icon-members {
                    width: 2.3vw;
                    cursor: pointer;
                }
            }
            .ATQ_Administrator_GuardRol_day_team_component {
                width: 95%;
                justify-self: center;
                margin-top: 0.5vw;
                display: grid;
                grid-template-rows: 1fr 0.2fr;
                grid-template-columns: 1fr;

                .ATQ_Administrator_GuardRol_day_team_icons_component {
                    width: 100%;
                    display: flex;
                    align-content: end;
                    justify-content: end;
                    .ATQ_Administrator_GuardRol_day_team_icon {
                        width: 2.5vw;
                    }
                }
                .ATQ_Administrator_GuardRol_day_team_items_component {
                    width: 100%;
                    .ATQ_Administrator_GuardRol_day_team_item {
                        color: #000;
                        font-size: 0.9vw;
                        list-style: number;
                        font-weight: 500;
                    }
                }
            }
            .ATQ_Administrator_GuardRol_text {
                text-align: center;
                font-weight: 500;
            }
            .ATQ_Administrator_GuardRol_text_day {
                color: #303030;
                font-size: 1vw;
                font-family: "Poppins";
               
            }
            .ATQ_Administrator_GuardRol_text_date {
                color: #094070;
                font-weight: 600;
                font-size: 1vw;
                font-family: "Poppins";
            }
        }
    }
}
