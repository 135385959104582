.container_SellerPermisions{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 88vh;
    padding-top: 11vh;
    padding-bottom: 2vh;
    .container_SellerPermisions_Components{
        width: 95%;
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: .9fr .9fr .8fr;
        grid-gap: 2vw;
    }
}