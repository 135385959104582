.container_analisis_modal{
    width: 95%;
    margin: 0 auto;

    .table_analisis{
        width: 100%;
        border-collapse: collapse;
            display: inline-block;
            box-sizing: border-box;

            .thead_analisi{
                width: 100%;
                display: flex;
                .product_fila{
                    display: flex;
                    width: 100%;
                    border-bottom: 2px solid #a7a7a7;
                    .product_material-title {
                        font-weight: 700;
                        font-family: 'Poppins';
                        padding-bottom: 1vw;
                        font-size: 17px;
                        color: #094070;
                        width: 33%;
                    }
                }
            }
        
        tbody {
            tr {
                .analisis_body_modal {
                    padding: 10px 10px;
                    width: 33%;
                    font-family: 'Poppins';
                    font-weight: 400;
                    color: #707070;
                }
            }
        }

    }

}