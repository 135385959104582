.StoreHouseApproveStatusRigthListComponent-list-ul {
    text-decoration: none;
    list-style-type: none;
    width: 100%;
    // width: calc( 100% - 6rem);
    justify-content: space-around;
    .top_border_li {
        border-top: 1px solid #303030;
    }
    .StoreHouseApproveStatusRigthListComponent-list-li {
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #303030;

    }
        .StoreHouseApproveStatusRigthListComponent-list-li-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: none;
            background-color: white;
            outline: none;
            padding: 0 2rem;
            font-family: 'Poppins';
            font-size: 17px;
            font-weight: 500;
            color: #303030;
            cursor: pointer;
            .arrowIcon {
                width: 7px;
            }
            .arrowCloseIcon {
                width: 8px;
            }
        }
        .StoreHouseApproveStatusRigthListComponent-list-li-body {

            .StoreHouseApproveStatusRigthListComponent-list-li-title {
                font-family: 'Poppins';
                font-size: 16px;
                color: #094070; 
                margin-bottom: 1vw;
            }
            .StoreHouseApproveStatusRigthListComponent-container-list-ul{
                width: 100%;
                border-radius: .7vw;
                box-shadow: 1px 1px 8px -4px #8f8f8f;
                .StoreHouseApproveStatusRigthListComponent-list-ol {
                    width: 80%;
                    text-decoration: none;
                    list-style-type: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    position: relative;
                    li {
                        display: flex;
                        padding: .7vw 0;
                        position: relative;
                        align-items: center;
                        text-align: end;
                        &:after {
                            content: '';
                            position: absolute;
                            background: #707070;
                            display: block;
                            width: 2px;
                            height: 23px;
                            top: 34px;
                            left: 11px;
                            z-index: 1;
                        }
                        &:last-child {
                            &:after {
                                width: 0!important;
                                height: 0!important;
                            }
                        }
                    }
                    .textNonActualState {
                        font-family: 'Poppins';
                        font-size: 16px;
                        font-weight: 500;
                        color: #707070;
                        justify-content: space-between;
                        .processNumber {
                            border: 2px solid #707070;
                            color: #ffffff;
                            font-weight: 400;
                            z-index: 1;
                            background-color: #707070;
                        }
                    }
                    .textActualState {
                        font-family: 'Poppins';
                        font-size: 16px;
                        font-weight: 500;
                        color: #1ABC9C;
                        .processNumber {
                            width: 20px!important;
                            height: 20px!important;
                            border: 2px solid #1ABC9C;
                            color: #ffffff;
                            font-weight: 500;
                            background-color: #1ABC9C;
                        }
                    }
                    .processNumber {
                        width: 19px;
                        height: 19px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 14px;
                        border-radius: 50%;
                        margin-right: 1rem;
                        span {
                            width: 17px;
                            height: 17px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                        }
                    }
                }
                .StoreHouseApproveStatusRigthListComponent-list-li-button {
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
}