.mainContainer {
    margin: 0;
    position: relative;
    padding: 0;
    background: linear-gradient(93deg, #1abc9c 10%, #094070 100%);
    height: 100vh;
    overflow: hidden;

    .textContainer {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: 'Poppins', sans-serif;
        color: #fff;
    }

    .logoContainer {
        /*position: absolute;
        left: 50%;
        top: 25%;
        transform: translate(-50%, -25%);*/
        background-color: rgba(255, 255, 255, 0.15);
        padding: 20px;
        border-radius: 40% 60% 71% 29% / 31% 38% 62% 69% ;
        /*animation: blob 3s ease-in-out infinite;*/
    }

    .textContainer h1 {
        /*position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -40%);*/
        z-index: 1;
        font-size: 8em;
        text-align: center;
        text-transform: uppercase;
        text-shadow: 5px 10px 15px rgba(255, 255, 255, 0.3);
        margin: 20px 0;
    }

    .textContainer p {
        /*position: absolute;
        top: 70%;
        left: 50%;
        transform: translate(-50%, -70%);*/
        z-index: 1;
        font-size: 20px;
    }

    .box ul li {
        position: absolute;
        width: 35px;
        height: 35px;
        list-style: none;
        opacity: 0;
        background: radial-gradient(#fff, transparent, transparent);
        bottom: 0;
        left: 10vw;
        animation: bubbles 15s linear infinite;
        -moz-animation: bubbles 15s linear infinite;
        -webkit-animation: bubbles 15s linear infinite;
        -o-animation: bubbles 15s linear infinite;
    }

    .box ul li:nth-child(2) {
        left: 37vw;
        animation-delay: 2s;
        -moz-animation-delay: 2s;
        -webkit-animation-delay: 2s;
        -o-animation-delay: 2s;
    }

    .box ul li:nth-child(3) {
        left: 25vw;
        animation-delay: 4s;
        -moz-animation-delay: 4s;
        -webkit-animation-delay: 4s;
        -o-animation-delay: 4s;
    }

    .box ul li:nth-child(4) {
        left: 60vw;
        animation-delay: 6s;
        -moz-animation-delay: 6s;
        -webkit-animation-delay: 6s;
        -o-animation-delay: 6s;
    }

    .box ul li:nth-child(5) {
        left: 75vw;
        animation-delay: 4s;
        -moz-animation-delay: 4s;
        -webkit-animation-delay: 4s;
        -o-animation-delay: 4s;
    }

    .box ul li:nth-child(6) {
        left: 95vw;
        animation-delay: 3s;
        -moz-animation-delay: 3s;
        -webkit-animation-delay: 3s;
        -o-animation-delay: 3s;
    }

    .box ul li:nth-child(7) {
        left: 15vw;
        animation-delay: 2.5s;
        -moz-animation-delay: 2.5s;
        -webkit-animation-delay: 2.5s;
        -o-animation-delay: 2.5s;
    }

    .box ul li:nth-child(8) {
        left: 50vw;
        animation-delay: 5s;
        -moz-animation-delay: 5s;
        -webkit-animation-delay: 5s;
        -o-animation-delay: 5s;
    }
}

/*animation for every browser*/
@keyframes bubbles {
    0% {
        transform: scale(0);
        opacity: 1;
        bottom: 0;
    }
    100% {
        transform: scale(10);
        opacity: 0;
        bottom: 100vh;
    }
}

@-webkit-keyframes bubbles {
    0% {
        transform: scale(0);
        opacity: 1;
        bottom: 0;
    }
    100% {
        transform: scale(10);
        opacity: 0;
        bottom: 100vh;
    }
}

@-moz-keyframes bubbles {
    0% {
        transform: scale(0);
        opacity: 1;
        bottom: 0;
    }
    100% {
        transform: scale(10);
        opacity: 0;
        bottom: 100vh;
    }
}

@-o-keyframes bubbles {
    0% {
        transform: scale(0);
        opacity: 1;
        bottom: 0;
    }
    100% {
        transform: scale(10);
        opacity: 0;
        bottom: 100vh;
    }
}

@-ms-keyframes bubbles {
    0% {
        transform: scale(0);
        opacity: 1;
        bottom: 0;
    }
    100% {
        transform: scale(10);
        opacity: 0;
        bottom: 100vh;
    }
}