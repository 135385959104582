.containerGeneral-encuesta-ventas {
    margin-top: 15vh;
    font-family: "Poppins";


    .body-encuestas-ventas {
        margin-top: 25vh;

        .btn-encuestas-ventas {
            display: flex;
            width: 80%;
            margin-left: 10%;
            justify-content: flex-end;

            .btn-ventas {
                padding: 1.2vh 2.5vw;
                font-size: 2vh;
                color: #fff;
                font-family: "Poppins";
                background: #707070;
                border-radius: 0.4vw 0.4vw 0 0.2vw;
            }

            .btn-ventas-active {
                background: linear-gradient(152deg, rgba(55, 167, 185, 1) 0%, rgba(9, 64, 112, 1) 100%);
                border-radius: 0.4vw 0.4vw 0 0.2vw;
            }
        }

        .body-encuestas-list {
            width: 80%;
            display: flex;
            justify-content: center;
            margin-left: 10%;
        }
    }
}
