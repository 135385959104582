.container-product-modal{
    width: 95%;
    margin: 0 auto;

    .table_productM{
        width: 100%;
        border-collapse: collapse;
            display: inline-block;
            box-sizing: border-box;

            .description_product{
                width: 100%;
                display: flex;
                .product_fila{
                    display: flex;
                    width: 100%;
                    border-bottom: 2px solid #a7a7a7;
                    .product_material-title {
                        font-weight: 700;
                        font-family: 'Poppins';
                        padding-bottom: 1vw;
                        font-size: 17px;
                        color: #094070;
                        width: 33%;
                    }
                }
            }
        
        tbody {
            tr {
                .product_body_modal {
                    padding: 20px 10px;
                    width: 33%;
                    font-family: 'Poppins';
                    font-weight: 400;
                    color: #707070;
                    .inpt_products{
                        width:35px ;
                    }
                }
            }
        }

    }

    .container-button{
        margin-top: 20px;
    .products-modal-button{
        height: 2.3vw;
        margin-top: 10px;
        border-radius: 30px;
        width: 16vw;
        background: rgb(9,64,112);
        background: linear-gradient(90deg, rgba(9,64,112,1) 0%, rgba(55,167,185,1) 100%);
        border: none;
        font-size: 1.3vw;
        font-family: 'Poppins';
        color: #FFFFFF;
        cursor: pointer;
        outline: none;
    }

  }
}