.container-component-comments-SeguimientoMedic {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 7px;
    -webkit-box-shadow: -1px 5px 19px 0px #efecef;
    -moz-box-shadow: -1px 5px 19px 0px #efecef;
    box-shadow: -1px 5px 19px 0px #efecef;
    margin-bottom: 3vh;

    .header-commentsSeguimientoMedic {
        width: 100%;

        .header-titleSeguimientoMedic {
            border-bottom: 1px solid #c2c2c2;
            padding-bottom: 0.5vh;

            .title-componentSeguimientoMedic {
                color: #37a7b9;
                font-family: "Poppins";
                font-size: 1.2vw;
                font-weight: 500;
                display: flex;
                justify-content: center;
                padding-top: 1vh;
            }
        }
        .header-subtitle-SeguimientoMedico {
            padding-bottom: 0.5vh;
            .subtitle-componen-SeguimientoMedico {
                color: #000;
                font-family: "Poppins";
                font-size: 1vw;
                font-weight: 500;
                display: flex;
                justify-content: center;
                padding: 3vh 0 2vh;
            }
        }
    }

    .body-SeguimientoMedico {
        width: auto;
        display: flex;
        flex-direction: column;
        margin: 0px 10px 15px 10px;

        .container-SeguimientoMedico {
            width: 100%;
            display: flex;
            justify-content: center;

            .textArea-SeguimientoMedico{
                width: 100%;
                color: #303030;
                font-family: "Poppins";
                font-size: 1vw;
                font-weight: 200;
                padding: 10px 0 0 10px;
                border-color: #c2c2c2;
                
            }
        }
    }
}
