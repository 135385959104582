.storeHouseStockLeftButtons_container{

    display: grid;
    align-items: center;
    flex-direction: column;
    grid-template-rows: repeat(4, .6fr);
    grid-gap: .8vw;
    height: 50vh;

    .storeHouseStockLeftButtons{
        width: 60%;
        height: 5vw;
        border: none;
        color: #fff;
        border-radius: .7vw;
        box-shadow: 2px 2px 7px 0px rgba(#000, .5);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        outline: none;
        .storeHouseStockLeftButtons_logistica-img{
            width: 3.5vw;
        }
        .storeHouseStockLeftButtons_logistica-text{
            font-size: 1.2vw;
            font-weight: 400;
            text-align: end;
        }
    }
    .storeHouseStockLeftButtons:nth-child(2n+1){
        background: linear-gradient(270deg, rgba(26,188,156,1) 0%, rgba(9,64,112,1) 100%);
    }
    .storeHouseStockLeftButtons:nth-child(2n){
        background: linear-gradient(90deg, rgba(55,167,185,1) 0%, rgba(9,64,112,1) 100%);
    }
    
}


