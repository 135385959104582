.precontainer_list{
    height: 60vh;
    display: flex;
    margin-top: 2vw;
    align-items: center;
    flex-direction: column;
    width: 70%;
    overflow: scroll;
    overflow-x: hidden;
    justify-self: center;

    .container_list{
        width: 88%;
        display: grid;
        justify-content: space-around;
        align-items: center;
        border: 1px solid #707070;
        border-radius: 0.5vw;
        padding: 0.5vw 0 0.5vw 2vw;
        margin-bottom: 1vw;
        color: #303030;
        cursor: pointer;
        outline: none;
        grid-template-columns: 1fr .1fr;

        .item-disponibilidad-atq{
            color: #303030;
            font-weight: 300;
            font-size: 1vw;
            font-family: "Poppins";

        }

        .storeHouseStock_elements_img_arrow{
            width: .7vw;
            height: .7vw;
            justify-self: center;
        }
        
    }
}