.precontainer_encue-info {
    margin-top: 19vh;
    position: relative;
    background: white;
    .container-sencuestas-header {
        display: flex;
        flex-direction: column;
        width: 100%;
        .header-container {
            width: 100%;
            div {
                width: 50%;
                display: flex;
                justify-content: center;
                div {
                    width: 80%;
                    display: flex;
                    justify-content: flex-start !important;
                    align-items: center;
                    .title-header {
                        background: #707070;
                        padding: 5px 30px;
                        border-radius: 15px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 10px;
                        color: #fff;
                        font-family: "Poppins";
                        font-size: 2.5vh;
                    }
                    .img-header {
                        width: 40px;
                        margin-bottom: -5px;
                    }
                }
            }
        }
        .body-container {
            width: 100%;
            font-family: "Poppins";
            display: flex;
            align-items: center;
            position: absolute;
            top: 6vh;
            z-index: 1;
            padding-bottom: 2rem;
            -webkit-box-shadow: 3px 8px 17px -7px rgb(228, 220, 228);
            -moz-box-shadow: 3px 8px 17px -7px rgb(228, 220, 228);
            box-shadow: 3px 8px 17px -7px rgb(228, 220, 228);
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            background: white;



            .container_encue-info {
                width: 50%;
                padding: 10px 30px;
                display: flex;
                justify-content: center;

                .container-left-info {
                    width: 90%;

                    //Estilos de boton Type:Analisis
                .section-analisis-header-button {
                    margin-top: 5%;

                    .type-analisis-button {
                        background: linear-gradient(90deg, #37a7b9 10%, #094070 100%);
                        color: #fff;
                        border-radius: 15px;
                        font-family: "Poppins";
                        padding: 0.7vh 1vw;
                        font-size: 1vw;
                    }
                }


                }

            }

            //Type Analisis
            .container-encue-info-analisis {
                width: 40%;
                display: flex;
                

                .container-left-info {
                    width: 90%;

                    //Estilos de boton Type:Analisis
                .section-analisis-header-button {
                    margin-top: 5%;

                    .type-analisis-button {
                        background: linear-gradient(90deg, #37a7b9 10%, #094070 100%);
                        color: #fff;
                        border-radius: 15px;
                        font-family: "Poppins";
                        padding: 0.7vh 1vw;
                        font-size: 1vw;
                    }
                }


                }

            }
        }
    }
}
