.container-component-comments-Acciones {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 7px;
    -webkit-box-shadow: -1px 5px 19px 0px #efecef;
    -moz-box-shadow: -1px 5px 19px 0px #efecef;
    box-shadow: -1px 5px 19px 0px #efecef;
    margin-bottom: 3vh;

    .header-commentsAcciones {
        width: 100%;

        .header-titleAcciones {
            border-bottom: 1px solid #c2c2c2;
            padding-bottom: 1vh;

            .title-Acciones {
                color: #37a7b9;
                font-family: "Poppins";
                font-size: 1.2vw;
                font-weight: 500;
                display: flex;
                justify-content: center;
                padding-top: 2vh;
            }
        }

        .body-Acciones {
            margin: 0.5vh 0.2vw 1.4vh 1.3vw;
            margin: 0.5rem 0.2rem 1.4rem 1.3rem;
            max-height: 9rem;
            overflow: auto;
            min-height: 4rem;
            .margin-b{
                margin-bottom: 2vh;
            }
            .accion-add-view {
                width: 100%;
                display: flex;
                justify-content: space-between;



                .left-add-view {
                    width: 104vw;
                    display: grid;
                    align-items: center;
                    /* justify-content: space-between; */
                    grid-template-columns: 0.1fr 2.6fr 1.9fr 1.5fr;
                    grid-gap: 0.5vw;

                    .selec-view {
                        cursor: pointer;
                    }

                    .text-view {
                        font-family: "Poppins";
                        font-size: 0.7vw;
                        color: #707070;
                    }

                    .text-view-analisis{
                        font-family: "Poppins";
                        font-size: 0.9vw;
                        color: #303030;
                        text-decoration: line-through;

                    }
                    .text-center{
                        text-align: center;
                    }
                }

                .rigth-add-view {
                    width: 20%;
                    display: flex;
                    justify-content: flex-end;
                    margin-right: 2vw;
                    .img-delete-view {
                        width: 2.5vw;
                        cursor: pointer;
                    }
                }
            }           
        }
        .footer-btn-view {
            margin-top: 2vh;
            justify-content: end;
            margin-left: 1.5vw;
            margin-bottom: 3vh;
            .btn-add {
                background: linear-gradient(90deg, #37a7b9 10%, #094070 100%);
                color: #fff;
                border-radius: 15px;
                font-family: "Poppins";
                padding: 0.5vh 1vw;
                font-size: 1vw;
            }
        }

        .body-Acciones-checklist {
            margin: 3vh 0 10vh 1.3vw;
            .btn-accion {
                display: flex;
                justify-content: flex-start;
                .title-btn {
                    background: linear-gradient(270deg, rgb(9, 64, 112) 0%, rgb(26, 188, 156) 91%);
                    border-radius: 15px;
                    color: #fff;
                    font-family: "Poppins";
                    font-size: 1.7vh;
                    padding: 1vh 1vw;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    .img-accion {
                        width: 1vw;
                        margin-left: 0.5vw;
                        margin-bottom: 0.3vh;
                    }
                }
            }
        }
        //Estilos de la decision
        .header-accion-add {
            display: flex;
            justify-content: center;
            margin-right: 1vw;

            .input-accion-add {
                width: 40vw;
                font-family: "Poppins";
                font-size: 0.9vw;
                color: #303030;
                font-weight: 200;
                padding: 1vh 0 0.7vh 0.5vw;
                border-radius: 7px;
                border: 1px solid #c2c2c2;
            }
        }

        .body-Acciones-New {
            margin: 2.5vh 0.2vw 4vh 1.3vw;

            .body-accion-add {
                // background: red;
                display: flex;
                margin-right: 1.5vw;
                margin-left: 0.7vw;
                margin-top: 2vh;
                justify-content: flex-start;
                .btn-add {
                    background: linear-gradient(90deg, #37a7b9 10%, #094070 100%);
                    color: #fff;
                    padding: 0.7vh 1vw;
                    font-family: "Poppins";
                    font-size: 0.9vw;
                    border-radius: 15px;
                }

                .body-accion-asignar {
                    display: flex;
                    margin-left: 17vw;
                    align-items: center;
                    cursor: pointer;
                    .img-asignar {
                        width: 1rem;
                        height: 1rem;
                        margin-right: 0.7vw;
                    }
                    .text-asignar {
                        font-family: "Poppins";
                        font-size: 0.9vw;
                        display: flex;
                        align-items: center;
                        color: #303030;
                        font-weight: 300;
                    }
                }
                .body-accion-fecha {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .img-fecha {
                        width: 1rem;
                        height: 1rem;
                        margin-left: 1vw;
                        margin-right: 0.7vw;
                    }

                    .text-fecha {
                        font-family: "Poppins";
                        font-size: 0.9vw;
                        display: flex;
                        align-items: center;
                        color: #303030;
                        font-weight: 300;
                    }
                }
            }
        }
        .container-asignar {
            width: 19vw;
            display: inline-block;
            margin-left: 24vw;
            margin-top: -4vh;
            position: absolute;
            background: white;
            border-radius: 7px;
            -webkit-box-shadow: -1px 5px 19px 0px #efecef;
            -moz-box-shadow: -1px 5px 19px 0px #efecef;
            box-shadow: -1px 5px 19px 0px #efecef;
            margin-bottom: 3vh;
            padding: 15px;
            .body-asignar {
                // background:red;
                .header-search {
                    margin-bottom: 1vh;
                }
               /*  .person-list {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 1.5vh;
                    align-items: center;
                    .txt-person-list {
                        font-family: "Poppins";
                        font-size: 0.9vw;
                        font-weight: 200;
                        color: #303030;
                    }
                    .selec-asignar-list {
                        cursor: pointer;
                    }
                } */
            
                .cont-sel-re{
                    height: 15vh;
                    overflow: auto;
                    .radioButtonsAsignar{
                        color: #777778;
                        .MuiRadio-colorSecondary.Mui-checked {
                            color: #38A8B9!important;
                        }
                        .MuiTypography-body1 {
                            font-family: 'Poppins';
                            font-size: '0.9px';
                            font-weight: 200 !important;
                        }
                    }
                }
                .cont-button-asignar {
                    display: flex;
                    justify-content: center;
                    .btn-asignar {
                        padding: 0.7vh 1.5vw;
                        background: linear-gradient(90deg, #37a7b9 10%, #094070 100%);
                        font-family: "Poppins";
                        font-size: 0.9vw;
                        color: #fff;
                        border-radius: 15px;
                        cursor: pointer;
                        box-shadow: 5px 4px 3px #b8b6b6;
                    }
                }
            }
        }
        .container-fecha {
            width: 15vw;
            display: inline-block;
            margin-left: 29vw;
            margin-top: -4vh;
            position: absolute;
            background: white;
            border-radius: 7px;
            -webkit-box-shadow: -1px 5px 19px 0px #efecef;
            -moz-box-shadow: -1px 5px 19px 0px #efecef;
            box-shadow: -1px 5px 19px 0px #efecef;
            margin-bottom: 3vh;
            padding: 15px;

            .body-fecha {
                display: flex;
                justify-content: space-around;
                margin-bottom: 0.5vh;

                .text-fech {
                    font-size: 0.9vw;
                    font-family: "Poppins";
                    color: #303030;
                    font-weight: 300;
                    display: flex;
                }
            }

            .body-inputs-fecha {
                display: flex;
                justify-content: center;
                margin-bottom: 3vh;
                margin-top: 2vh;

                .input-fecha {
                    width: 14vw;
                    height: 3vh;
                    border: solid 1px #c2c2c2;
                    color: #303030;
                    font-family: "Poppins";
                    font-size: 0.9vw;
                    font-weight: 300;
                }
            }

            .cont-button-fecha {
                display: flex;
                justify-content: center;

                .btn-fecha {
                    padding: 0.7vh 1.5vw;
                    background: linear-gradient(90deg, #37a7b9 10%, #094070 100%);
                    font-family: "Poppins";
                    font-size: 0.9vw;
                    color: #fff;
                    border-radius: 15px;
                    cursor: pointer;
                    box-shadow: 5px 4px 3px #b8b6b6;
                }
            }
        }
    }
    .decoration{
        text-decoration: line-through;
    }
}

