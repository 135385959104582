.precontainer_page_directory{
    height: 80vh;
    padding-top: 20vh;
    font-family: "Poppins";
    display: flex;
    align-items: center;
    flex-direction: column;
    .container_page_directory{
        width: 100%;
        display: flex;
        justify-content: center;
        .section_list_directory{
            display: flex;
            height: 75vh;
            margin-top: 1vw;
            width: 90%;
            justify-content: center;
        }
    }
}