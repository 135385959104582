%flexBox
    display: flex
    justify-content: center
    align-items: center
%flexbox-c-info
    display: grid
    grid-template-columns: 1fr 1fr
    align-items: center
.container-factoryConfigCard
    width: 100%
    @extend %flexBox
    .factoryConfigCard-container-structure
        width: 100%
        border: none
        border-radius: 10px 10px 0 0
        background: linear-gradient(145deg, #ffffff, #e6e6e6)
        box-shadow:  2px 2px 5px #666666, -3px -3px 1px #ffffff
        display: grid
        grid-template-areas: 'factoryConfigCard-container-img factoryConfigCard-container-info'
        grid-template-columns: .5fr 1fr
        height: 17vh
        .factoryConfigCard-container-img
            widht: 100%
            @extend %flexBox
            .factoryConfigCard-img
                width: 5vw
                border: 3px solid rgba(9,64,112,1)
                border-radius: 50%

        .factoryConfigCard-container-info
            width: 100%
            display: grid
            margin-top: 20px
            grid-template-rows: repeat(2, minmax(30px, .3fr))
            grid-template-areas: 'factoryConfigCard-c-sistem' 'factoryConfigCard-c-typeSurgery' 'factoryConfigCard-c-config'
            h3
                font-family: 'Poppins'
                font-size: 1.1vw
            .factoryConfigCard-info-container-sistem
                @extend %flexbox-c-info
                grid-area: 'factoryConfigCard-c-sistem'

                .factoryConfigCard-info-sistem-title
                    font-family: 'Poppins'
                .factoryConfigCard-info-sistem-info
                    font-family: 'Poppins'
            .factoryConfigCard-info-container-typeSurgery
                @extend %flexbox-c-info
                grid-area: 'factoryConfigCard-c-typeSurgery'
                align-items: flex-start
                .factoryConfigCard-info-typeSurgery-title
                    font-family: 'Poppins'
                .factoryConfigCard-info-typeSurgery-info
                    font-family: 'Poppins'
            .factoryConfigCard-info-container-config
                @extend %flexbox-c-info
                grid-area: 'factoryConfigCard-c-config'
                .factoryConfigCard-info-config-title
                    font-family: 'Poppins'
                .factoryConfigCard-info-config-info
                    font-family: 'Poppins'