.container-header-encuesta-analisis{
    width: 100%;
    display: flex;
    flex-direction: column;

    .body-page-infoEncuestas-analisis {
        width: 90%;
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        border-bottom: 2px solid #c2c2c2;

        .container-component-analisis  {
            width: 48.7%;
            margin: 5vh 0;

            .cont-btn-footer-analisis{
                display: flex;
                justify-content: flex-end;
                margin-top: 10vh;

                .btnView-cancelar-analisis{
                    background: linear-gradient(90deg, #37a7b9 10%, #094070 100%);
                    color: #fff;
                    padding: 1.2vh 3vw;
                    font-family: "Poppins";
                    font-size: 0.9vw;
                    border-radius: 25px;
                }
                .btnView-guardar-analisis{
                    background: linear-gradient(90deg, #37a7b9 10%, #094070 100%);
                    color: #fff;
                    padding: 1.2vh 3vw;
                    font-family: "Poppins";
                    font-size: 0.9vw;
                    border-radius: 25px;
                    margin-left: 1vw;
                }
            }
        }
        
        .clasificacion-body-analisis {
            display: flex;
            justify-content: space-between;
        }
    }

    .section-requiere-analisis {
        display: flex;
        width: 90%;
        align-items: center;
        margin-left: 5%;
        margin-top: 2%;

        .text-requiere-visita-analisis {
            font-family: "Poppins";
            font-size: 1.1vw;
            font-weight: 500;
            color: #303030;
            display: flex;
            margin-right: 2%;
        }
        .text-requiere-visita-analisis-respuesta {
            font-family: "Poppins";
            font-size: 1.1vw;
            font-weight: 300;
            color: #303030;
        }
    }
}
