.c-listPrices
    width: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    .c-listPrices-c-list-items-c-title
        width: 100%
        text-align: start
        border-bottom: 1px solid #cfcfcf
        margin-bottom: 1vw
        padding-bottom: 10px
    .c-listPrices-c-list-items-title
        color: #8a8a8a
        font-family: 'Poppins'
        font-weight: 300
        font-size: 18px
    .c-listPrices-c-search
        width: 90%
        margin: 20px 0
        display: flex
        flex-direction: row
        justify-items: flex-start
        align-items: center
        .c-listPrices-tools-c-input-search
            width: 100%
            display: flex
            align-items: center
            justify-items: center
            font-size: 18px
            .c-listPrices-c-tools-search-icobuscar
                background: #dddddd
                padding: 0px 6px 0px 12px
                border-radius: 20px 0 0 20px
                display: flex
                justify-content: center
                align-items: center
                height: 27px
                border: none
                img
                    width: 18px
            .c-listPrices-tools-input-search
                border: none
                width: 50%
                background: #dddddd
                border-radius: 0 20px 20px 0
                outline: none
                padding: 5px
                height: 17px
                color: #777777
    .c-listPrices-c-list-items
        width: 90%
        margin-bottom: 20px
        .c-listPrices-c-list-items-scroll
            height: 100px
            overflow-y: scroll
            .c-listPrices-c-item
                width: 90%
                display: flex
                flex-direction: row
                justify-content: space-between
                align-items: center
                padding-bottom: 5px
                border-bottom: 1px solid #a8a8a8
                .c-listPrices-item-name
                    font-family: 'Poppins'
                    font-weight: 300
                    font-size: 18px
                    color: #696969
                .c-listPrices-item-check
                    display: inline-block
                    width: 18px
                    height: 18px
                    margin-right: .5rem
                    background-size: cover
        .c-listPrices-button
            width: 60%
            padding: 10px
            margin-top: 20px
            border-radius: 20px
            border: none
            background: linear-gradient(270deg, #00325d 0%, #2a99ab 91%)
            color: #fff
            outline: none
            cursor: pointer
            font-size: 16px