.preconter_list_material {
    width: 60%;
    height: 67vh;
    display: flex;
    margin-top: 20vh;
    justify-self: center;
    flex-direction: column;
    overflow: scroll;
    overflow-x: hidden;

    .content_info {
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        border: 1px solid #707070;
        border-radius: 0.5vw;
        padding: 0.5vw 0.9vw;
        margin-bottom: 1vw;
        color: #303030;
        cursor: pointer;
        outline: none;

        .text_info {
            width: 95%;
            display: flex;
            flex-direction: column;

            .info_date_numberPqx {
                display: flex;
                align-items: center;
                flex-direction: row;
                font-family: "Poppins";
                margin-bottom: 0.5vw;
                
                p {
                    font-weight: 300;
                    font-size: 1vw;
                    color: #303030;
                    margin-right: 0.8vw;
                }
            }
        }
        .storeHouseStock_elements_img_arrow {
            width: 0.7vw;
            height: 0.7vw;
        }
    }
}
