.quote-c-section
    display: grid
    grid-template-rows: 70px 1fr
    margin: 30px 0
    .quote-c-section-c-title
        margin-bottom: 20px
        width: 300px
        border-radius: 30px
        display: flex
        height: 30px
        flex-direction: row
        justify-content: space-around
        align-items: center
        .quote-c-section-title
            font-family: 'Poppins'
            font-weight: 300
            font-size: 16px
            text-align: center
            color: #fff
        .quote-c-section-img
            width: 23px
.c-items
    grid-template-columns: repeat( 6, minmax(150px, 1fr))
.c-items-whit-products
    grid-template-columns: repeat( 5, minmax(150px, 1fr))
.quote-c-section-c-items
    display: grid
    grid-gap: 30px
    width: 100%
    .quote-c-item
        align-items: center
        border-radius: 10px
        box-shadow: 0px -1px 7px -2px
        display: flex
        flex-direction: column
        justify-content: center
        width: 100%
        position: relative
        .quote-c-item-img
            padding: 10px 0 50px 0
            width: 80px
            min-height: 4vw
        .quote-c-item-button
            align-items: center
            background: linear-gradient(270deg, rgba(26,188,156,1) 0%, rgba(9,64,112,1) 91%)
            border-radius: 0px 0px 10px 10px
            border: none
            color: #fff
            cursor: pointer
            display: flex
            font-size: .9vw
            flex-direction: row
            font-family: 'Poppins'
            font-weight: 300
            justify-content: space-between
            padding: 5px 0px
            text-align: left
            outline: none
            bottom: 0
            position: absolute
            width: 100%
            .quote-item-button-ico
                font-size: 18px
                margin-right: 10px
            .quote-item-button-name
                margin-left: 10px