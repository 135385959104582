.container-errorModal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .errorModal-img{
        width: 6vw;
        margin-bottom: 1vw;
    }
    .errorModal-text{
        font-weight: 300;
        color: #777777;
        font-family: 'Poppins';
    }
    .errorModal-button{
        cursor: pointer;
        outline: none;
        margin-top: 1vw;
        width: 50%;
        padding: .5vw;
        border-radius: 1vw;
        background: linear-gradient(23deg, rgba(55,167,185,1) 0%, rgba(9,64,112,1) 100%);
        color: #fff;
        box-shadow: 6px 5px 4px 0px rgb(0 0 0 / 25%);
        border: none;
    }
}