


.MessagesChatComponent-container-notification{
    width: 100%;
    height: 66vh;
    overflow: scroll;
    border-bottom: 1px solid #303030;
    border-top: 1px solid #303030;


    .MessagesChatComponent-preContainer-active-notification{
        background: rgba(#1ABC9C, .3);
    }
    .MessagesChatComponent-preContainer-inactive-notification{
        background: #fff;
    }
    .MessagesChatComponent-preContainer-notification{
        width: 100%;
       border-top: 1px solid #303030;

       

    
        .MessagesChatComponent-info-container-notification{
            width: 90%;
            display: grid;
            grid-template-columns: .2fr 1fr .2fr;
            align-items: center;
            margin-left: 1vw;
            .MessagesChatComponent-info-imgUser-notification{
                justify-self: center;
                width: 4vw;
            }
            .MessagesChatComponent-container-info-notification{
                justify-self: center;
                cursor: pointer;
                width: 90%;
                .MessagesChatComponent-info-nameUser-notification{
                    font-size: 1vw;
                    color: #303030;
                    font-weight: 500;
                    width: 24vw;
                    margin: .5vw 0 .5vw 0;
                }
                .MessagesChatComponent-info-messageUser-notification{
                    font-size: .9vw;
                    color: #303030;
                    font-weight: 300;
                    margin: .5vw 0 .5vw 0;
                }
                .MessagesChatComponent-info-timeMsg-notification{
                    color: #303030;
                    font-weight: 300;
                    font-size: .7vw;
                    margin: .5vw 0 .5vw 0;
                }
            }
            .MessagesChatComponent-info-imgMessage-notification{
                justify-self: center;
                width: 2vw;
            }
        }
    }
}