.StoreHouseApproveStatusScreen-container{
    padding-top: 20vh;
    height: 80vh;
    width: 100%;
    display: flex;
    justify-items: center;
    font-family: 'Poppins';
    .StoreHouseApproveStatusScreen-precontainer{
        width: 100%;
        display: grid;
        height: 70vh;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr .2fr;
        grid-gap: 3vw;
        justify-items: center;
        overflow: scroll;
        .StoreHouseApproveStatusScreen-container-section{
            width: 90%;
            display: grid;
            height: 60vh ;
            grid-template-columns: 1fr 1fr;
            .StoreHouseApproveStatusScreen-container-left-section{
                width: 90%;
            }
            .StoreHouseApproveStatusScreen-container-rigth-section{
                width: 95%;
            }
        }
        .StoreHouseApproveStatusScreen-container-bottom-button{
            width: 90%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .StoreHouseApproveStatusScreen-bottom-button{
                width: 20%;
                padding: .3vw;
                border-radius: 1vw;
                border: none;
                background: linear-gradient(275deg, rgba(9,64,112,1) 0%, rgba(55,167,185,1) 100%);
                color: #fff;
                outline: none;
                cursor: pointer;
                box-shadow: 2px 3px 4px -0.9px #777;
            }
        }
    }
}