.precontainer_encue{
    width: 100%;
    padding-top: 20vh;
    font-family: "Poppins";
    display: flex;
    align-items: center;
    flex-direction: column;
    .container_encue{
        width:60%;
        .container-header{
            margin-top: 5vh;
            display: flex;
            justify-content: space-between;
            .container-left {
                display: flex;
                width: 50%;
                .container-fecha {
                    display: flex;
                    flex-direction: column;
                    margin-right: 20px;
                    position: relative;
                    .select-1 {
                        min-width: 90px;
                        height: 100%;
                        cursor: pointer;
                        border-radius: 5px;
                        border:1px solid #707070;
                       
                    }

                    .span-cx{
                        position: absolute;
                        top: -36px;
                        width: 79px;
                        left: 0;
                    }
                }

                .select-2{
                    min-width: 90px;
                    border-radius: 5px;
                    cursor: pointer;
                    border: 1px solid #707070;
                }


                .img-search{
                    width: 35px;
                    cursor: pointer;
                    margin-left: 20px;
                }
            }
            .container-right {
                display: flex;
                align-items: center;
                .comentarios-left {
                    margin: 0 20px;
                    display: flex;
                    align-items: center;
                    
                    .img-comentarios {
                        margin-right: 10px;
                        width: 33px;
                    }

                    .con-p{
                        align-items: center;
                    }


                    
                }

                .comentarios-rigth{
                    display: flex;
                    align-items: center;

                    .img-sin-comentarios {
                        margin-right: 10px;
                        width: 20px;
                        
                    }

                    .sin-p{
                        align-items: center;
                    }
                }
            }

        }


        .container-buttons{
            display: flex;
            margin-top: 7vh;

            .respuesta-btn{
                min-width: 150px;
               
                .button-sin-respuesta{
                    width: 100%;
                    color: #FFFF;
                    background: #707070;
                    padding: 9px 40px ;
                }
            
            }

            .pendiente-btn{
                min-width: 150px;
               
                .button-pendientes{
                    width: 100%;
                    color: #FFFF;
                    background: #707070;
                    padding: 9px 40px ;
                }
            
            }
            .seguimineto-btn{
                min-width: 150px;
               
                .button-seguimiento{
                    width: 100%;
                    color: #FFFF;
                    background: #707070;
                    padding: 9px 40px ;
                }
            
            }

            .cerradas-btn{
                min-width: 150px;
               
                .button-cerradas{
                    width: 100%;
                    color: #FFFF;
                    background: #707070;
                    padding: 9px 40px ;
                }
            
            }



        }



        .container-list{
            display: flex;
            flex-direction: column;
            width: 100%;
            overflow-y: scroll;
            max-height: 47vh;
            margin-top: 20px;
            .list-encuestas{ 
                    margin-top: 10px;
                    display:flex ;
                    cursor: pointer;
                    justify-content: space-between;
                    width: 97%;
                    align-items: center;
                    border: 1px solid #707070;
                    border-radius: 10px;
                    padding: 2px 10px;

                    .precontainer-encuesta-list {
                        display: flex;
                        align-items: center;

                        .list-encuesta-p{
                            /* margin-right: 15px;   */ 
                        }
                        .ico_con_Comentarios {
                            width: 2.3vw;
                            height: 3.2vh;
                            padding: 4px 0px;
                            margin-right: 10px;
                            margin-left: -4px;
                        }

                              .ico_con_sincomentarios {
                               width: 1.5vw;
                               height: 2vh;
                               padding: 4px 0px;
                               margin-right: 10px;
                               margin-left: 4px;

                                
                         }
                       
                    }
                     .icon-arrow {
                     margin-right: 1vw;
                    
                     }             
            }
        }



    }
}
