.pending_users_content {
    width: calc( 100% - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 18vh auto 40px auto;
    max-width: 1200px;
    padding-top: 5vh;
}
.userCardContent {
    width: 60%;
    display: flex;
    padding: 1rem;
    border-radius: 5px;
    // border-bottom: 2px solid rgba(143, 237, 252, 0.6);
    box-shadow: 3px 1px 10px 0 rgba(166, 171, 189, 0.5);
    margin-bottom: 1.5rem;
    .userCardContent-image {
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            width: 90%;
            max-width: 95px;
        }
    }
    .userCardContent-data {
        width: 70%;
        font-family: 'Poppins';
        display: flex;
        flex-direction: column;
        justify-content: center;
        .user-fullname {
            display: flex;
            padding-left: 2rem;
            p {
                font-size: 18px;
                color: #00325D;
                font-weight: 500;
                margin-right: 4px;
                text-transform: capitalize;
            }
        }
        .second-data-element {
            display: flex;
            font-size: 15px;
            margin-top: 8px;
            padding-left: 2rem;
            .dark_gray_color {
                color: #303030;
                font-weight: 600;
            }
            .light_gray_color {
                color: #777777;
                font-weight: 500;
                margin-left: 8px;
            }
        }
    }
    .user-approve-unapprove {
        width: 10%;
        border-left: 2px solid #2A99AB;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        .a_u_icon {
            cursor: pointer;
        }
        .approve_icon {
            color: #37A585;
        }
        .unapprove_icon {
            color: #FF0000;
        }
    }
}