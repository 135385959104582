.atq-product{
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 53vh;

    

        .paperSchedule{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;

            .labelCounter{
                color: white;
                font-family: 'Poppins';
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                font-size: 1.5vw;
                letter-spacing: normal;
                text-align: center;
                color: #FFFFFF;
                outline: none;
                margin-bottom: 15px;
               
            }
            .productBox{
                width: 50%;
                height: 25vh;
                text-align: center;
                background: white;
                align-self: center;
                margin-bottom: 3vh;
                border-radius: 12px;
                display: grid;
                grid-template-rows: .7fr 1fr;
                .txt-cod{
                    align-self: center;
                    font-family: 'Poppins';
                    font-weight: 600;
                    font-size: 1.2vw;
                }
                .txt-des{
                    padding-left: 1.5vw;
                    padding-right: 1.5vw;
                    font-family: 'Poppins';
                    font-weight: 300;
                }
                
            }
            .carousel{

                .counter{
                    width: 60%;
                        border: none;
                        cursor: inherit;
                        height: 30px;
                        margin: 0;
                        opacity: 0.7;
                        outline: none;
                        font-size: 20px;
                        box-shadow: 0 0 3.7px 0 rgb(0 0 0 / 40%);
                        margin-top: 10px;
                        text-align: center;
                        font-weight: 600;
                        line-height: inherit;
                        border-radius: 10px;
                        background-color: #ffffff;
                        -webkit-appearance: none;
                }
                .focus{

                }
                .continueButton{
                    color: #303030;
                    width: 60%;
                    cursor: pointer;
                    margin: auto;
                    outline: none;
                    padding: 5px;
                    font-size: 18px;
                    box-shadow: 4px 4px 3px 0 rgb(0 0 0 / 35%);
                    font-style: normal;
                    margin-top: 50px;
                    text-align: center;
                    font-family: Poppins;
                    font-weight: 500;
                    line-height: 1.2;
                    border-style: none;
                    font-stretch: normal;
                    border-radius: 15.7px;
                    letter-spacing: normal;
                    background-color: #ffffff;
                }
            }
        }
    /* } */
}