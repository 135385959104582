.container {
    padding-top: 20vh;
    height: 80vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 95vw;

        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        color: #094070;
        font-weight: 700;
    }

    .title {
        display: block;
        align-items: center;
    }

    hr {
        width: 40%;
        text-align: start;
        height: 4px;
        background-color: #37A7B9;
        margin-left: 0;
        border: none;
    }

    .tableContainer {
        margin-top: 34px;
        width: 95vw;
    }

    .bottomBar {
        background: #E5E9F2;
        display: flex;
        width: 100%;
        height: 82px;
        position: fixed;
        bottom: 30px;
        justify-content: end;
        align-items: center;
        padding-right: 50px;
    }

    .btnExcel {
        background: linear-gradient(90deg, rgba(29,111,66,1) 0%, rgba(29,111,66,1) 94%);
        color: #fff;
        width: 10%;
        height: 65%;
        padding: .5vw;
        cursor: pointer;
        border: none;
        border-radius: 2vw;
        display: flex;
        justify-content: center;
    }
}