.storeHouseStock_elements_container_info {
    height: 60vh;
    display: flex;
    margin-top: 2vw;
    align-items: center;
    flex-direction: column;
    width: 100%;
    overflow: scroll;

    .content-div-list-generate {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5vw;
        margin-right: 1.2vw;
        margin-left: 1vw;

        width: 100%;

        .storeHouseStock_elements_info_container {
            width: 75%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            border: 1px solid #707070;
            border-radius: .5vw;
            padding: .5vw .9vw;
            margin-bottom: 1vw;
            color: #303030;
            cursor: pointer;
            outline: none;


            .storeHouseStock_elements_info_elements_container {
                width: 95%;
                display: flex;
                flex-direction: column;


                .storeHouseStock_elements_info_date_container {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    font-family: 'Poppins';
                    margin-bottom: .5vw;

                    .storeHouseStock_elements_info_date_title {
                        font-weight: 400;
                        font-size: 1vw;
                        margin: 0 1vw 0 0;
                    }

                    .storeHouseStock_elements_info_date {
                        font-weight: 300;
                        font-size: 1vw;
                    }
                }

                .storeHouseStock_elements_info_ubicacion_container {
                    display: flex;
                    font-size: 1vw;
                    align-items: center;
                    flex-direction: row;

                    .storeHouseStock_elements_info_ubicacion_element {
                        margin-right: 1vw;
                        font-weight: 300;
                    }
                }
            }

            .storeHouseStock_elements_img_arrow {
                width: .7vw;
                height: .7vw;
            }
        }

        .generate-pedido-content {
            border-radius: 7px;
            padding: 0.3vw;
            width: 5vw;
            background: linear-gradient(270deg, #1abc9c 0%, #094070 100%);
            margin-bottom: 1vw;
            -webkit-box-shadow: 3px 3px 0px 0px rgba(112, 112, 112, 0.4);
            -moz-box-shadow: 3px 3px 0px 0px  rgba(112, 112, 112, 0.4);
            box-shadow: 3px 3px 0px 0px  rgba(112, 112, 112, 0.4);
            cursor: pointer;

            .txt-generate {
                color: #FFF;
                text-align: center;
                font-family: 'Poppins';
                font-size: .7vw;
            }
        }

    }

   
}