$gradient: linear-gradient(270deg, rgb(9, 64, 112) 0%, rgba(55,167,185,1) 91%);
.container {
    padding-top: 20vh;
    height: 80vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    margin-bottom: 100px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 95vw;

        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        color: #094070;
        font-weight: 700;
    }

    .title {
        display: block;
        align-items: center;
    }

    hr {
        width: 40%;
        text-align: start;
        height: 4px;
        background-color: #37A7B9;
        margin-left: 0;
        border: none;
    }

    .tableContainer {
        width: 95vw;
        z-index: 0;
    }

    .bottomBar {
        background: #E5E9F2;
        display: flex;
        width: 100%;
        height: 82px;
        position: fixed;
        bottom: 30px;
        justify-content: end;
        align-items: center;
        padding-right: 50px;
    }
}

.activeTag {
    font-weight: 700;
    color: #049104;
    background-color: #b9f7a8;
    border-radius: 6px;
    padding: 4px;
}

.cancelTag {
    font-weight: 700;
    border-radius: 6px;
    padding: 4px;
    color: #d10a0a;
    background-color: #fcc6c6;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.concilTag {
    font-weight: 700;
    border-radius: 6px;
    padding: 4px;
    color: #d1990a;
    background-color: #fcf5c6;
}

.finishedTag {
    font-weight: 700;
    border-radius: 6px;
    padding: 4px;
    color: #0aadd1;
    background-color: #c6effc;
}

.cancelBtn {
    background: #d10a0a;
    color: #E5E9F2;
    border-radius: 6px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
}

.disableButton {
    color: #fff !important;
    background: #c3c3c3;
    border: none;
    border-radius: 50px;
    padding: 0.8em;
    font-family: 'Inter', sans-serif;
    cursor: default;
}

.filterContainer {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.filterButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $gradient;
    border-radius: 50px;
    padding: 10px 20px;
    font-family: 'Roboto';
    font-size: 16px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-right: 10px;
}

.ag-body-viewport-wrapper.ag-layout-normal {
    overflow-x: scroll;
  }

.cancelTitleBg {
    color: #fff;
    background-color: #d10a0a;
}

@media screen and (min-width: 992px) {

    .tableContainer {
        padding-bottom: 50px;
    }
}
