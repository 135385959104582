.c-navbar-sideBar
        margin-left: 3vw
        align-self: center
        justify-self: self-start
        grid-area: c-navbar-sideBar
        .btn-sideBar
            border: none
            outline: none
            cursor: pointer
            background: none
        .sideBar-logo-img
            margin-right: 1.5vw
            width: 2vw
            background: none