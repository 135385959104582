.StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-component{
    display: grid;
    grid-template-rows: .65fr .1fr;
    grid-gap: 2vw;
    width: 100%;

    .StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-buttons-component{
        width: 30%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: flex-start;
        .StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-button{
            width: 3vw;
            height: 3vw;
            background: linear-gradient( 275deg, #094070 0%, #37a7b9 100%);
            border: none;
           cursor: pointer;
           outline: none;
           border-radius: 0.5vw;
           margin-right: 2vw;
           justify-content: center;
           align-items: center;
           display: flex;

 
         .StoreHomeReceivingImgane{
             width: 2vw;
         }
        }
    }
}