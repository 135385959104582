.c-StoreHomeAbailableSystemsItems
    display: grid
    grid-template-areas: 'StoreHomeAbailableSystemsItems-c-cervicales' 'StoreHomeAbailableSystemsItems-c-lumbares' 'StoreHomeAbailableSystemsItems-c-toraxicos' 'StoreHomeAbailableSystemsItems-c-complementos' 'StoreHomeAbailableSystemsItems-c-products'
    margin: 40px auto 40px auto
    width: 90%
    position: relative
    padding-top: 8vw
.c-StoreHomeAbailableSystemsItems-whit-products
    display: grid
    grid-template-areas: 'StoreHomeAbailableSystemsItems-c-cervicales' 'StoreHomeAbailableSystemsItems-c-lumbares' 'StoreHomeAbailableSystemsItems-c-toraxicos' 'StoreHomeAbailableSystemsItems-c-complementos' 'StoreHomeAbailableSystemsItems-c-products'
    margin: 40px 10px
    width: 60%
    position: relative
    padding-top: 8vw
    
    