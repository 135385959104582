.doctor-tab-chat-right-profile {
    border-bottom: 2px solid #CDCCCC;
    padding-bottom: .5vw;
    display: flex;
    align-items: center;

    .image-circle {
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 2px solid #37A7B9;
        overflow: hidden;

        img {
            width: 80px;
        }
    }


    .txt-member-sinasignar-msj{
        font-size: 1vw;
        font-family: "Poppins";
        font-weight: 300;
        display: flex;
        justify-content: center;
        margin-top: 10%;
    }
    .doctor-tab-chat-right-title {
        font-family: 'Poppins';
        font-size: 1.2vw;
        font-weight: 600;
        color: #00325D;
        margin-left: 1rem;
    }
}

.container-messages-sesions {

    height: 23vh;
    overflow: scroll;
    margin-top: 1vw;
    width: 102%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .txt-member-sinasignar-msj {
        font-size: 1vw;
        font-family: "Poppins";
        font-weight: 300;
        display: flex;
        justify-content: center;
        margin-top: 10%;
    }
}

.doctor-tab-chat-right-messages-ul {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    list-style-type: none;
    width: 100%;
    padding: 0;
    padding-top: 30px;
    height: calc(100vh - 250px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 20px;
    font-family: 'Poppins';
    font-size: 15px;

    .doctor-tab-chat-right-messages-li {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        width: 65% !important;
        padding: 12px;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        .deliveryDate {
            font-size: 12px;
            color: #777777;
            margin-top: 8px;
        }
    }

    .even {
        border-top-right-radius: 10px;
        background-color: #F5F5F5;
        color: #303030;
    }

    .odd {
        border-top-left-radius: 10px;
        border-bottom-right-radius: 15px;
        background-color: #1ABC9C;
        color: #FFFFFF;
        margin-left: 35%;

        .deliveryDate {
            color: #E1E1E1;
        }
    }
}

.doctor-tab-chat-right-messages-ul::-webkit-scrollbar,
.right-bar::-webkit-scrollbar {
    -webkit-appearance: none;
}

.doctor-tab-chat-right-messages-ul::-webkit-scrollbar:vertical,
.right-bar::-webkit-scrollbar:vertical {
    width: 10px;
}

.doctor-tab-chat-right-messages-ul::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button,
.right-bar::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
    display: none;
}

.doctor-tab-chat-right-messages-ul::-webkit-scrollbar:horizontal,
.right-bar::-webkit-scrollbar:horizontal {
    height: 10px;
}

.doctor-tab-chat-right-messages-ul::-webkit-scrollbar-thumb,
.right-bar::-webkit-scrollbar-thumb {
    background-color: #777777;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.doctor-tab-chat-right-messages-ul::-webkit-scrollbar-track,
.right-bar::-webkit-scrollbar-track {
    border-radius: 10px;
}
