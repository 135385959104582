.containerRequeriments{
    padding: 20vh 0 0 0;
    font-family: 'Poppins';
    display: flex;
    align-items: center;
    flex-direction: column;
    .precontainerRequeriments{
        width: 95%;
             .containerButtonsChossed{
                width: 100%;
                display: flex;
                .containerButtons{
                    width: 100%;
                    /* display: grid; */
                    display: flex;
                    /* grid-column-start: 3; */
                    /* grid-template-columns: 1fr 1fr; */
                    justify-content: center;
                    .btn_left1{
                        border-radius: 0.2vw 0 0 0.2vw;
                    }
                    .btn_rigth1{
                        border-radius: 0 0.2vw 0.2vw 0;
                    }
                    .btn_active1{
                        
                        background: linear-gradient(270deg, #1abc9c 0%, #094070 91%) !important;
                    }
                    .normal_button1{
                        padding: 0.5vw;
                        border: none;
                        background: #707070;
                        color: #ffff;
                        cursor: pointer;
                        outline: none;
                        width: 15vw;
                    }
            }
        }
    
    }
}