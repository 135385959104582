.quote_completed_general_c {
    margin: 40px auto 40px auto;
    width: 90%;
    position: relative;
    padding-top: 7vw;

    * {
        font-family: 'Poppins';
    }

    .button_elements_content {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;

        .radioButtonOptions {
            color: #777778;

            .MuiRadio-colorSecondary.Mui-checked {
                color: #38A8B9;
            }

            .MuiTypography-body1 {
                font-family: 'Poppins';
            }
        }
    }

    .buttons_options {
        display: contents;
    }
}

.btn {
    background: linear-gradient(90deg, rgba(15, 65, 111, 1) 0%, rgba(56, 168, 185, 1) 100%);
    color: #fff;
    width: 10%;
    padding: .5vw;
    cursor: pointer;
    border: none;
    border-radius: 2vw
}

.c-quote_completed_allelements {
    overflow-y: scroll;
    height: 75vh;

    ::-webkit-scrollbar {
        display: none;
        scrollbar-width: none;
    }

    .quote_completed_allelements {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .cardB {
            width: 100%;
        }
    }
}

///// New Styles
.dialog-add-disscount {
    .dialog-title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 20px;
        color: #000000;
    }

    .dialog-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .dialog-description {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 23px;
        display: flex;
        align-items: center;
        color: #000000;
        margin-bottom: 22px;
    }

    .radio {
        color: #BDBDBD;
    }

    .radio-selected {
        color: #689F38;
    }

    .discount-input {
        margin-top: 28px;
        width: 80%;
        margin-bottom: 43px;
    }

    .dialog-actions {
        align-content: center;
        align-items: center;
        align-self: center;
        width: 100%;
        justify-content: center;
    }

    .btn {
        background: linear-gradient(90deg, #37A7B9 0%, #094070 100%);
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.5), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
        border-radius: 50px;
        width: 30%;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
    }
}

.dialog-delete-selected {
    .dialog-title {
        font-family: 'Roboto';
        font-style: Bold;
        font-weight: 800;
        font-size: 24px;
        line-height: 20px;
        color: #000000;
    }

    .dialog-description {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
    }

    .dialog-actions {
        align-content: center;
        align-items: center;
        align-self: center;
        width: 100%;
        justify-content: center;
    }

    .btnDeleteDialog {
        background: #C70808;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.5), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
        border-radius: 50px;
        width: 40%;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
    }
}

.drawer-simple-edit {
    .drawer-content {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        margin-left: 35px;
        margin-right: 35px;
    }

    .drawer-product-code {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 17px;
        color: #484F59;
        margin-bottom: 23px;
    }

    .drawer-product-description {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 20px;
        text-align: center;
        color: #000000;
        margin-bottom: 100px;
    }

    .drawer-product-price {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 29px;
        color: #000000;
        align-self: center;
        margin-bottom: 90px;
    }

    .drawer-input-container {
        margin-top: 20px;
    }

    .drawer-input {
        width: 100%;
        box-sizing: border-box;
        background: #FFFFFF;
        border-radius: 4px;
    }

    .drawer-input-number {
        box-sizing: border-box;
        background-color: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        width: 100%;
        height: 62px;
        font-family: Roboto;
    }

    .drawer-update-button-container {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        align-items: center;
    }

    .btnSimpleEdit {
        width: 50%;
        background: linear-gradient(90deg, #37A7B9 0%, #094070 100%);
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.5), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
        border-radius: 50px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 17px;
        color: #FFFFFF;
    }
}

.drawer-edit {
    .drawer-content {
        margin: 20px;
        max-width: 100%;
        display: flex;
        flex-direction: column;
    }

    .drawer-product-code {
        font-family: 'arial';
        font-size: 15px;
        margin-top: 20px;
    }

    .drawer-product-description {
        font-family: 'arial';
        font-size: 20px;
        margin-top: 20px;
        font-weight: bold;
    }

    .drawer-product-price {
        font-family: 'arial';
        font-size: 15px;
        margin-top: 20px;
        align-self: center;
    }

    .drawer-input-container {
        margin-top: 20px;
    }

    .drawer-input {
        width: 100%;
        box-sizing: border-box;
        background: #FFFFFF;
        border-radius: 4px;
    }

    .drawer-input-number {
        box-sizing: border-box;
        background-color: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        width: 100%;
        height: 62px;
        font-family: Roboto
    }

    .drawer-tabs-container {
        margin-top: 20px;
    }

    .drawer-update-button-container {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        align-items: center;
    }

    .btnEditVest {
        width: 50%;
        background: linear-gradient(90deg, #37A7B9 0%, #094070 100%);
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.5), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
        border-radius: 50px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 17px;
        color: #FFFFFF;
    }
}

.price-list-dialog {
    height: 160%;

    .price-list-dialog-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .price-list-form-control {
        width: 329px;
        height: 46px;
        box-sizing: border-box;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        margin-left: 80px;
    }

    .price-list-select {
        height: 46px;
    }

    .price-list-dialog-actions {
        align-content: center;
        align-items: center;
        align-self: center;
    }

    .price-list-dialog-actions .btn {
        width: 220px;
    }
}

.bottomBar-active1 {
    background: #E5E9F2;
    display: flex;
    width: 100%;
    height: 82px;
    position: fixed;
    bottom: 40px;
    justify-content: flex-start;
    z-index: 10;


    .div-one {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .div-two {
        display: flex;
        align-items: center;
        margin-right: 73px;
    }

    .buttonBottomBar1 {
        width: 239px;
        height: 46px;
        background: linear-gradient(90deg, #37A7B9 0%, #094070 100%);
        border-radius: 50px;
        font-family: Arial;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        color: #FFFFFF;
    }
}

.bottomBar-active2 {
    background: #E5E9F2;
    display: flex;
    width: 100%;
    height: 82px;
    position: fixed;
    bottom: 40px;
    justify-content: flex-start;
    z-index: 10;


    .div-first {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .div-second {
        display: flex;
        align-items: center;
        margin-right: 73px;
    }

    .navLink-one {
        text-align: center;
        justify-content: center;
        display: flex;
        align-content: center;
        align-items: center;
    }

    .navLink-two {
        width: 239px;
        height: 46px;
        background: linear-gradient(90deg, #37A7B9 0%, #094070 100%);
        border-radius: 50px;
        font-family: Arial;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        color: #FFFFFF;

    }

}