.c-priceListModal
    .priceListModal-container
        display: flex
        flex-direction: column
        max-height: 60vh
        overflow-y: scroll
        button
            display: flex
            flex-direction: column
            background: none
            border: none
            border-bottom: 1px solid gray
            padding: 12px 0
            &:focus
                outline: none
            .name
                font-size: 1.3vw
                margin-bottom: 8px
            .code
                color: gray
                font-size: 1.3vw