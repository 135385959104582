%cardToFactoryContainer{
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 4vw;
    margin-bottom: 1vw;
    cursor: pointer;
    border-radius: 1vw;
    padding: .5vw;
    .factoryConfigList-card-container-img{
        width: 50%;

        .factoryConfigList-card-img{
            width: 50%;
        }
    }
}


%formSearchPermissionsUser{
    border-radius: .7vw;
    border: none;
    outline: none;
    color: #666666;
    background: #e4e4e4;
}

    .medic_hospital_list_component_card_container_info_requeriments{
        grid-template-columns: 1fr .2fr !important;
        width: 100%;
        .medic_hospital_list_component_card_info_text{

        }
        .medic_hospital_list_component_card_container_info_img{
            width: 65%;
            display: flex;
            justify-content: center;
            align-items: center;
            justify-self: center;
            border: 1px solid #a80000;
            border-radius: 50%;
            cursor: pointer;
            .medic_hospital_list_component_card_info_img{
                width: 2vw;
            }
        }

    }
    .medic_hospital_list_component_card_container_info{

        display: grid;
        justify-content: space-between;
        grid-template-columns: 1fr;
        align-items: center;
        width: 95%;
        margin-right: 5px;
        font-weight: 300;
        overflow: hidden;
        font-family: 'Poppins';
        font-size: 1.2vw;
        .medic_hospital_list_component_card_container_info_name{
            margin-bottom: .5vw;
        }

    }

    .medic_hospital_list_component_card_container_arrow{

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 10%;
        margin-right: 5px;
        .arrowRight{

        }
    }
.medic_hospital_list_component_rigth{
    grid-template-rows: .2fr 1fr .2fr !important;
    .medic_hospital_list_component_container_title{
        margin: 0 !important;
    }
    .medic_hospital_list_component_cards_rigth_container_button{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family:  'Poppins';
        font-weight: 300;
        font-size: .8vw;
        .medic_hospital_list_component_cards_rigth_button{
            padding: .5vw 0;
            width: 70%;
            border-radius: 1vw;
            border: 1px solid #00325D;
            background: #37a7b9;
            color: #fff;
            cursor: pointer;
            overflow: none;
        }
    }
}

.container_medic_hospital_list_component{

    width: 100%;
    height: 73vh;
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    justify-items: center;
    grid-template-rows: .1fr .1fr 1fr;
    border-radius: 18px;
    box-shadow:  0px 2px 5px #666666, 1px -5px 0px #ffffff;

    .medic_hospital_list_component_container_search{
        display: grid;
        width: 80%;
        justify-items: center;
        align-items: center;
        grid-gap: .4vw;
        font-family: 'Poppins';
        font-size: .8vw;
        height: 0vh;
        .medic_hospital_list_component_search{
            width: 90%;
            padding: .5vw;
            @extend %formSearchPermissionsUser;

        }
        .medic_hospital_list_component_search_combobox{
            width: 100%;
            padding: .3vw;
            @extend %formSearchPermissionsUser;

        }
    }

    .medic_hospital_list_component_container_title{
        margin: 1vw 0;
        display: flex;
        width: 60%;
        justify-content: space-around;
        align-items: center;
        .medic_hospital_list_component_title{
            
            text-align: center;
            font-family: 'Poppins';
            font-weight: 400;
            color: #00325D;
            font-size: 1.5vw;
        }
        .medic_hospital_list_component_title_button_active{
            text-align: center;
            width: 50%;
            margin: 0 .5vw;
            font-family: 'Poppins';
            font-weight: 400;
            color: #fff;
            font-size: 1.3vw;
            background: #37a7b9;
            border: none;
            padding: .5vw;
            border-radius: 1vw;
        }

        .medic_hospital_list_component_title_button{
            text-align: center;
            width: 50%;
            margin: 0 .5vw;
            font-family: 'Poppins';
            font-weight: 400;
            color: #00325D;
            font-size: 1.3vw;
            background: #fff;
            border: 1px solid #37a7b9;
            padding: .5vw;
            border-radius: 1vw;
        }
    }
    .medic_hospital_list_component_cards_rigth{

    }
    
    .medic_hospital_list_component_container_cards{
        width: 95%;
        overflow: auto;
        margin-bottom: 2vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        
        .medic_hospital_list_component_container_card_selected{

            @extend %cardToFactoryContainer;
            background: #37a7b9;
            color: #fff;
            border-left: .5vw solid #37a7b9;
            
        }
        .medic_hospital_list_component_container_card_requeriment{
            cursor: default !important;
        }
        .medic_hospital_list_component_container_card{

            @extend %cardToFactoryContainer;
            background: #fff;
            border-left: .5vw solid #37a7b9;
            border-top: 1px solid #37a7b9;
            border-bottom: 1px solid #37a7b9;
            border-right: 1px solid #37a7b9;
            color: #666666;
        }
        
    }

}







