.mensaje_transferirCX_atq_containes {
    width: 100%;
    height: 85vh ;
    padding-top: 15vh ;
    position: relative;
    background: rgb(9,64,112);
    background: linear-gradient(152deg, rgba(9,64,112,1) 0%, rgba(56,168,185,1) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow-y: auto;
    * {
        font-family: Poppins;
    }
    .mensjae_transferirCX_atq_general_c {
        width: 100%;
        height: 78vh;
        overflow: auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        .mensaje_transferirCX-images {
            display: flex;
            position: relative;
            justify-content: center;
            .atq_transferirCX-success-img {
                width: 20vw;
                margin-bottom: 2vw;
            }
            .Final_encuesta-success-icon {
                width: 3vw;
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
            }
        }
        .mensaje_atq_transferirCX_inf_subtitle_1 {
            font-size: 2.2vw;
            font-weight: 400;
            color: #ffffff;
            margin-bottom: 1.5rem;
            margin-top: .8rem;
            font-family: "Poppins";
        }
        .mensaje_atq_transferirCX_inf_subtitle_2 {
            font-size: 2.3vw;
            font-weight: 400;
            color: #ffffff;
            margin-bottom: 1.5rem;
            font-family: "Poppins";
        }
        .mensaje_atq_transferirCX_inf_subtitle_3 {
            font-size: 1.1vw;
            font-weight: 400;
            color: #ffffff;
            margin-bottom: 1.5rem;
            font-family: "Poppins";
        }
        .menaje_atq_transferirCX-subtitle{
            font-size: 2vw;
            color: #ffffff;
            margin-bottom: 2vw;
            font-family: "Poppins";
        }
        
        .mensaje_atq_transferirCX_order_buttons {
            display: flex;
            justify-content: center;
            .mensaje_atq_transferirCX_white_button {
                font-size: 1.5vw;
                color: #094070;
                background-color: #ffffff;
                border-radius: 20px;
                cursor: pointer;
                display: flex;
                font-weight: 500;
                text-transform: uppercase;
                padding: 6px 1rem;
                margin-left: 1rem;
                min-width: 14vw;
                border: none;
                justify-content: center;
                text-transform: uppercase;
                opacity: .7;
                margin-top: 2.5rem;
                font-size: 1.1vw;
            }
        }
    }
}