.container_ATQ_Enc{
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: center;
    .precontainer_ATQ_Enc{
        display: grid;
        width: 90%;
        height: 85vh;
        grid-template-columns: 1fr 1fr;
        padding-top: 20vh;
        grid-gap: 7vw;
        .sect_ATQ_Left{
            display: grid;
            width: 100%;
            height: 100;
            grid-template-rows: .4fr .4fr .1fr;
                .btn-Aceptar{
                    width: 25%;
                    height: 3vh;
                    font-size: 0.9vw;
                    text-align: center;
                    color: #FFFFFF;
                    font-family: "Poppins";
                    background: linear-gradient(
                90deg, #094070 0%, #1abc9c 100%);
                    border-radius: 20px;
                    border: none;
                    cursor: pointer;
                    outline: none;
                    margin-top: 1vh;
                }
        }   
        .sect_ATQ_Rigth{
            display: grid;
            grid-auto-rows: 0.1fr .8fr 0.1fr;
            height: 76vh;
            width: 100%;

            .btn-Aceptar21{
                width: 25%;
                height: 3vh;
                margin-top: 7.5vh;
                justify-self: end;
                align-self: end;
                font-size: 0.9vw;
                text-align: center;
                color: #FFFFFF;
                font-family: "Poppins";
                background: linear-gradient(
            90deg, #094070 0%, #1abc9c 100%);
                border-radius: 20px;
                border: none;
                cursor: pointer;
                outline: none;

                
            }
            .container-buttons{
                display: grid;
                grid-auto-rows: 1fr 1fr;
                width: 95%;
                .icoAdd{
                    border-style: none;
                    width: 2.5vw;
                    align-self: end;
                    justify-self: end;
                }
                .btn-Aceptar2{
                    width: 25%;
                    height: 3vh;
                    justify-self: end;
                    align-self: end;
                    font-size: 0.9vw;
                    text-align: center;
                    color: #FFFFFF;
                    font-family: "Poppins";
                    background: linear-gradient(
                90deg, #094070 0%, #1abc9c 100%);
                    border-radius: 20px;
                    border: none;
                    cursor: pointer;
                    outline: none;
                }
             }
            .survey_title_ATQ{
                padding-bottom: 2vh;
                color: #094070;
                font-family: "Poppins";
                text-align: center;
                padding-top: 3vh;
                font-size: 1.2vw;
                font-weight: 300
            }
            .container-questions{
                display: flex;
                padding-top: 1vw;
                height: 58vh;
                overflow: auto;
                flex-direction: column;

                .individual-container{
                    width: 100%;
                    background: #FFFFFF;
                    display: flex;
                    justify-content: center;
                   
                }
                .individual-container_New{
                    width: 100%;
                    background: #FFFFFF;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 4vh;
                }
            }
            
        }
    }
}