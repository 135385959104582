.quote-c-cervicales
    grid-template-areas: 'quote-c-cervicales-c-title' 'quote-c-cervicales-c-items'
    grid-area: quote-c-cervicales
    .quote-c-cervicales-c-title
        background: rgba(#313131, .4)
.quote-c-cervicales-c-items
    grid-area: quote-c-cervicales-c-items
    .quote-c-item
        .quote-c-item-img
        .quote-c-item-button
            .quote-item-button-ico