.container-atq {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: start;
    padding-top: 14vh;
    padding-bottom: 2vh;

    .container-atq-colums {
        width: 90%;
        display: grid;
        grid-template-columns: 0.8fr 1fr;
        justify-content: start;
        align-items: start;
        grid-gap: 7vw;

        .precontainer-atq_survey {
            width: 100%;
            padding: 3.3vw 1vw;
            display: grid;
            grid-template-rows: .1fr .8fr .1fr;
            height: 45vh;

            .survey_title {
                padding-bottom: 5vh;
                color: #094070;
                font-family: "Poppins";
                text-align: center;
                padding-top: 3vh;
                font-size: 1.2vw;
                font-weight: 300;
            }

            .container-atq_survey {
                width: 100%;
                padding-left: 1vw;
                height: 55vh;
                overflow: auto;
            }

            .individual-container-atq_New {
                //  margin-left: .5vw;

            }
        }

        .container-atq-buttons-add {
            width:95%;
            display: grid;
            grid-template-rows: 1fr 1fr;

            .icoAdd {
                border-style: none;
                cursor: pointer;
                margin-bottom: 5px;
                width: 2vw;
                align-self: end;
                justify-self: end;
            }

            .btn-Aceptar2-add {
                width: 25%;
                height: 3vh;
                justify-self: end;
                align-self: center;
                font-size: 0.9vw;
                text-align: center;
                color: #FFFFFF;
                font-family: "Poppins";
                background: linear-gradient(90deg, #094070 0%, #1abc9c 100%);
                border-radius: 20px;
                border: none;
                cursor: pointer;
                outline: none;

                .btn-title {}
            }
        }

        .btn-Aceptar21 {
            width: 25%;
            height: 3vh;
            justify-self: end;
            align-self: end;
            font-size: 0.9vw;
            text-align: center;
            color: #FFFFFF;
            font-family: "Poppins";
            background: linear-gradient(90deg, #094070 0%, #1abc9c 100%);
            border-radius: 20px;
            border: none;
            cursor: pointer;
            outline: none;
            margin-right: 2vw;
            margin-top: 3vh;

            .btn-title {}
        }
    }

    section {
        margin-top: 10vh;
    }
}