.form_input_chat_modal_send-add {
    width: 75%;
    display: flex;
    align-items: center;
    justify-items: center;

    .btn_form_chat_modal_send-add {
        border-radius: 20px 0 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1.3vw;
        border: none;
        background: #707070a6;
        border-right: 1px solid #FFFF;

        .img-emoji-chat-modal-add {
            width: .9vw;
        }
    }

    ::placeholder {
        color: #FFFF;
        font-weight: 500;
        font-size: .7vw;
      }

    .input_form_chat_modal_send-add {
        border: none;
        width: 100%;
        background: #dddddd;
        font-family: "Poppins";
        border-radius: 20px 20px 20px 20px;
        outline: none;
        padding: 5px;
        color: #FFFF;
        font-size: .7vw;
        font-weight: 500;
        background: #707070a6;

    }

    .btn_chat_message_send{
        display: none;
    }
}